import React, { useState } from "react";
import ProcessItemCard from "./ProcessItemCard";
import { Box } from "@mui/material";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProcessItemCardsSlider = ({
  items,
  currentItems,
  lockedItems,
  onProcess,
  gatherItems,
  userInventory,
  setUserInventory,
  processItems,
  allItems,
  otherLockedItems,
}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 0.35,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
  };

  const [autoProcessState, setAutoProcessState] = useState({});

  const toggleAutoProcess = (itemId) => {
    setAutoProcessState((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const filteredItems = items.filter(
    (item) => ![31, 32, 33].includes(item.itemId)
  );

  const filteredLockedItems = lockedItems.filter(
    (item) => ![31, 32, 33].includes(item.itemId)
  );

  return (
    <>
      {window.innerWidth <= 1024 ? (
        <Slider {...settings}>
          {filteredItems.map((item, idx) => (
            <Box key={idx}>
              <ProcessItemCard
                item={item}
                onProcess={onProcess}
                gatherItems={gatherItems}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                processItems={processItems}
                isLocked={lockedItems.some(
                  (lockedItem) => lockedItem.itemId === item.itemId
                )}
                lockedItems={lockedItems}
                allItems={allItems}
                currentItems={currentItems}
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
          {filteredLockedItems.map((item, idx) => (
            <Box key={idx}>
              <ProcessItemCard
                item={item}
                onProcess={() => {}}
                gatherItems={gatherItems}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                processItems={processItems}
                isLocked={true}
                lockedItems={lockedItems}
                allItems={allItems}
                currentItems={currentItems}
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
        </Slider>
      ) : (
        <div style={{ overflowY: "scroll", maxHeight: "370px" }}>
          {filteredItems.map((item, idx) => (
            <Box key={idx}>
              <ProcessItemCard
                item={item}
                onProcess={onProcess}
                gatherItems={gatherItems}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                processItems={processItems}
                isLocked={lockedItems.some(
                  (lockedItem) => lockedItem.itemId === item.itemId
                )}
                lockedItems={lockedItems}
                currentItems={currentItems}
                allItems={allItems}
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
          {filteredLockedItems.map((item, idx) => (
            <Box key={idx}>
              <ProcessItemCard
                item={item}
                onProcess={() => {}}
                gatherItems={gatherItems}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                processItems={processItems}
                isLocked={true}
                lockedItems={lockedItems}
                currentItems={currentItems}
                allItems={allItems}
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default ProcessItemCardsSlider;
