import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ItemCardsSlider from "./ItemCardsSlider";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box } from "@mui/material";

const GatherTab = ({
  items,
  lockedItems,
  onGather,
  onAutoGather,
  expPercentages,
  purchasedItems,
  onBackToSkills,
  userProfileRef,
  userInventoryRef,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAutoGather, setIsAutoGather] = useState(false);
  const [autoGatheringItems, setAutoGatheringItems] = useState([]);
  const [isAfk, setIsAfk] = useState(false);
  const [afkModePurchased, setAfkModePurchased] = useState(false);
  const [afkDialogOpen, setAfkDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const afkModeProduct = "AFK Mode"; // replace with the actual product name in your inventory
    const isPurchased = userInventoryRef.current?.purchasedItems.some(
      (item) =>
        item.productName === afkModeProduct && item.status === "purchased"
    );
    setAfkModePurchased(isPurchased);
  }, [userInventoryRef.current]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filter available gather items and locked items separately based on the search term
  const filteredItems = items.filter(
    (item) =>
      item.status === "gather" &&
      item.name.toLowerCase().includes(searchTerm) &&
      !item.name.toLowerCase().includes("water")
  );

  const filteredLockedItems = lockedItems.filter(
    (item) =>
      item.status === "gather" && item.name.toLowerCase().includes(searchTerm)
  );

  const filteredLockedOtherItems = lockedItems.filter(
    (item) =>
      item.status !== "gather" && item.name.toLowerCase().includes(searchTerm)
  );

  const handleAutoGatherChange = (itemId, isActive) => {
    setAutoGatheringItems((prev) => {
      const newItems = new Set(prev);
      if (isActive) {
        newItems.add(itemId);
      } else {
        newItems.delete(itemId);
      }
      return Array.from(newItems);
    });
  };

  const handleSetAfk = () => {
    setAfkDialogOpen(true); // Open dialog
  };

  const handleAfkDialogClose = () => {
    setAfkDialogOpen(false);
  };

  const handleItemSelection = (itemId) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  const isSelected = (itemId) => selectedItems.includes(itemId);

  const handleAfkNow = async () => {
    console.log("Selected items for AFK gathering:", selectedItems);
    try {
      const userId = userProfileRef.current.id;

      // Set AFK details using the new set-afkGather endpoint
      const afkResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/set-afkGather`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ afkItems: selectedItems }),
        }
      );

      if (!afkResponse.ok) {
        throw new Error("Failed to set AFK details.");
      }

      const afkData = await afkResponse.json();
      console.log("AFK gather details set:", afkData);

      setIsAfk(true);
      alert(afkData.message);
      setAfkDialogOpen(false);
    } catch (error) {
      console.error("Failed to process AFK gather:", error);
      alert("Error: Could not start AFK gathering.");
    }
  };

  return (
    <div
      style={{
        padding: "0 20px",
        margin: "auto",
        maxWidth: "350px",
        marginBottom: "10px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2 style={{ color: "green" }}>Gather</h2>
      </div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Tooltip title={!afkModePurchased ? "Purchase AFK Mode in Store" : ""}>
          <span>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSetAfk}
              disabled={!afkModePurchased || isAfk}
            >
              Set AFK Mode
            </Button>
          </span>
        </Tooltip>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <IconButton onClick={onBackToSkills}>
          <ArrowBack />
        </IconButton>
        <TextField
          label="Search Items"
          variant="outlined"
          margin="normal"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { borderRadius: "20px" },
          }}
          style={{ width: "266px", marginLeft: "10px" }}
        />
      </div>
      <div style={{ marginTop: "7px" }}>
        {items.length === 0 && lockedItems.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <CircularProgress />
          </div>
        ) : filteredItems.length > 0 || filteredLockedItems.length > 0 ? (
          <ItemCardsSlider
            items={filteredItems}
            lockedItems={filteredLockedItems}
            onGather={onGather}
            onAutoGather={onAutoGather}
            onAutoGatherChange={handleAutoGatherChange}
            expPercentages={expPercentages}
            purchasedItems={purchasedItems}
            isAutoGather={isAutoGather}
            allItems={[...items, ...lockedItems]}
            otherLockedItems={filteredLockedOtherItems}
          />
        ) : (
          <Typography textAlign="center" mt={2}>
            No items available or no match found.
          </Typography>
        )}
      </div>
      {/* AFK Gather Dialog */}
      <Dialog open={afkDialogOpen} onClose={() => setAfkDialogOpen(false)}>
        <DialogTitle>Select Items for AFK Gathering</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gap: 2,
            }}
          >
            {filteredItems.map((item) => {
              const autoGatherProductName = `${item.name} Auto Gather`;
              const isAutoGatherPurchased = purchasedItems.some(
                (pItem) =>
                  pItem.productName === autoGatherProductName &&
                  pItem.status === "purchased"
              );

              return (
                <Tooltip
                  title={
                    !isAutoGatherPurchased
                      ? `Purchase ${item.name}'s Auto Gather feature to unlock`
                      : ""
                  }
                  arrow
                  key={item.itemId}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 1,
                      border: "2px solid",
                      borderColor: isSelected(item.itemId) ? "green" : "gray",
                      borderRadius: "8px",
                      backgroundColor: isSelected(item.itemId)
                        ? "lightgreen"
                        : isAutoGatherPurchased
                        ? "white"
                        : "lightgray",
                      cursor: isAutoGatherPurchased ? "pointer" : "not-allowed",
                      transition: "background-color 0.3s, border-color 0.3s",
                      pointerEvents: isAutoGatherPurchased ? "auto" : "none",
                    }}
                    onClick={() =>
                      isAutoGatherPurchased && handleItemSelection(item.itemId)
                    }
                  >
                    <img
                      src={item.imageUrl}
                      alt={item.name}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginBottom: "8px",
                      }}
                    />
                    <Typography variant="body2" align="center">
                      {item.name}
                    </Typography>
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAfkDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAfkNow} color="secondary" variant="contained">
            AFK NOW!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GatherTab;
