import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";

export default function ItemInfoDialog({ open, onClose, item, onSellItem }) {
  const [sellQuantity, setSellQuantity] = useState(0);
  const [bronzeCoinValue, setBronzeCoinValue] = useState(0);

  const handleQuantityChange = (e) => {
    let newQuantity = e.target.value;
    newQuantity = Math.max(0, newQuantity);

    const maxQuantity = item?.quantity || 0;
    let quantity =
      newQuantity === ""
        ? ""
        : Math.min(parseInt(newQuantity, 10), maxQuantity);
    setSellQuantity(quantity);

    const newBronzeValue = quantity * (item?.itemValue || 0);
    setBronzeCoinValue(newBronzeValue);
  };

  const handleBronzeCoinChange = (e) => {
    let newValue = e.target.value;
    newValue = Math.max(0, newValue);

    const maxPossibleValue = item ? item.itemValue * item.quantity : 0;
    let bronzeValue =
      newValue === "" ? "" : Math.min(parseInt(newValue, 10), maxPossibleValue);
    setBronzeCoinValue(bronzeValue);

    const newQuantity = Math.floor(bronzeValue / (item?.itemValue || 1));
    setSellQuantity(newQuantity);
  };

  const handleSell = () => {
    if (item) {
      onSellItem(item.itemId, sellQuantity, bronzeCoinValue);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          textAlign: "center",
          fontFamily: '"Pixelify Sans", sans-serif',
        }}
      >
        Item Details
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6">{item?.itemName}</Typography>
          <img
            src={item?.imageUrl}
            alt={item?.itemName}
            style={{ width: "50px", height: "50px" }}
          />
          <div>Quantity: {item?.quantity.toLocaleString()}</div>
          <div>Value: {item?.itemValue}</div>
          <div>Level: {item?.itemLevel}</div>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <TextField
            type="number"
            value={sellQuantity}
            onChange={handleQuantityChange}
            label="Quantity"
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              inputProps: {
                min: 0,
                max: item?.quantity,
              },
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
            <Box
              component="img"
              src="/assets/items/currencies/bronze-coin2.webp"
              alt="Bronze Coin"
              sx={{ width: 24, height: 24, marginRight: 1 }}
            />
            <span>Bronze Coin</span>
          </Box>
          <TextField
            type="number"
            value={bronzeCoinValue}
            onChange={handleBronzeCoinChange}
            label="Bronze Coin Value"
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              inputProps: {
                min: 0,
                max: item ? item.itemValue * item.quantity : 0,
              },
            }}
          />
        </Box>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            onClick={handleSell}
            disabled={
              sellQuantity <= 0 ||
              bronzeCoinValue <= 0 ||
              (item && item.itemId === "5") // Assuming "5" is the ID for the coin item
            }
            sx={{ color: "red" }}
          >
            Sell
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
