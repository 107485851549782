const defaultItemLevels = [
  { level: 1, expNeeded: 100, expGain: 1 },
  { level: 2, expNeeded: 500, expGain: 1.07 },
  { level: 3, expNeeded: 1000, expGain: 1.18 },
  { level: 4, expNeeded: 1350, expGain: 1.24 },
  { level: 5, expNeeded: 1500, expGain: 1.31 },
  { level: 6, expNeeded: 1750, expGain: 1.46 },
  { level: 7, expNeeded: 2050, expGain: 1.65 },
  { level: 8, expNeeded: 2350, expGain: 1.73 },
  { level: 9, expNeeded: 2650, expGain: 1.89 },
  { level: 10, expNeeded: 3000, expGain: 2 },
  { level: 11, expNeeded: 7500, expGain: 2.07 },
  { level: 12, expNeeded: 11000, expGain: 2.17 },
  { level: 13, expNeeded: 13500, expGain: 2.24 },
  { level: 14, expNeeded: 16000, expGain: 2.29 },
  { level: 15, expNeeded: 18800, expGain: 2.34 },
  { level: 16, expNeeded: 20000, expGain: 2.37 },
  { level: 17, expNeeded: 25000, expGain: 3.42 },
  { level: 18, expNeeded: 31250, expGain: 3.47 },
  { level: 19, expNeeded: 38000, expGain: 3.55 },
  { level: 20, expNeeded: 50000, expGain: 3.68 },
  { level: 21, expNeeded: 55000, expGain: 3.73 },
  { level: 22, expNeeded: 65500, expGain: 3.79 },
  { level: 23, expNeeded: 78000, expGain: 3.83 },
  { level: 24, expNeeded: 89000, expGain: 3.89 },
  { level: 25, expNeeded: 97500, expGain: 3.99 },
  { level: 26, expNeeded: 100000, expGain: 4 },
  { level: 27, expNeeded: 110000, expGain: 4.04 },
  { level: 28, expNeeded: 115000, expGain: 4.15 },
  { level: 29, expNeeded: 121000, expGain: 4.21 },
  { level: 30, expNeeded: 150000, expGain: 4.3 },
  { level: 31, expNeeded: 175000, expGain: 4.45 },
  { level: 32, expNeeded: 200000, expGain: 4.57 },
  { level: 33, expNeeded: 255000, expGain: 4.64 },
  { level: 34, expNeeded: 290000, expGain: 4.7 },
  { level: 35, expNeeded: 350000, expGain: 4.75 },
  { level: 36, expNeeded: 425000, expGain: 4.88 },
  { level: 37, expNeeded: 500000, expGain: 4.92 },
  { level: 38, expNeeded: 600000, expGain: 4.95 },
  { level: 39, expNeeded: 700000, expGain: 4.99 },
  { level: 40, expNeeded: 850000, expGain: 4.99 },
  { level: 41, expNeeded: 950000, expGain: 5 },
  { level: 42, expNeeded: 1000000, expGain: 5.1 },
  { level: 43, expNeeded: 1100000, expGain: 5.2 },
  { level: 44, expNeeded: 1200000, expGain: 5.3 },
  { level: 45, expNeeded: 1300000, expGain: 5.45 },
  { level: 46, expNeeded: 1400000, expGain: 5.6 },
  { level: 47, expNeeded: 1500000, expGain: 5.75 },
  { level: 48, expNeeded: 1600000, expGain: 5.83 },
  { level: 49, expNeeded: 1700000, expGain: 5.99 },
  { level: 50, expNeeded: Number.MAX_SAFE_INTEGER, expGain: 0.5 },
];

const itemLevels = {
  1: defaultItemLevels,
  2: defaultItemLevels,
  3: defaultItemLevels,
  4: defaultItemLevels,
  5: defaultItemLevels,
  6: defaultItemLevels,
  7: defaultItemLevels,
  8: defaultItemLevels,
  9: defaultItemLevels,
  10: defaultItemLevels,
  11: defaultItemLevels,
  12: defaultItemLevels,
  13: defaultItemLevels,
  14: defaultItemLevels,
  15: defaultItemLevels,
  16: defaultItemLevels,
  17: defaultItemLevels,
  18: defaultItemLevels,
  19: defaultItemLevels,
  20: defaultItemLevels,
  21: defaultItemLevels,
  22: defaultItemLevels,
  23: defaultItemLevels,
  24: defaultItemLevels,
  25: defaultItemLevels,
  26: defaultItemLevels,
  27: defaultItemLevels,
  28: defaultItemLevels,
  29: defaultItemLevels,
  30: defaultItemLevels,
  31: defaultItemLevels,
  32: defaultItemLevels,
  33: defaultItemLevels,
  34: defaultItemLevels,
  35: defaultItemLevels,
  36: defaultItemLevels,
  37: defaultItemLevels,
  38: defaultItemLevels,
  39: defaultItemLevels,
  40: defaultItemLevels,
  41: defaultItemLevels,
  42: defaultItemLevels,
  43: defaultItemLevels,
  44: defaultItemLevels,
  45: defaultItemLevels,
  46: defaultItemLevels,
  47: defaultItemLevels,
  48: defaultItemLevels,
  49: defaultItemLevels,
  50: defaultItemLevels,
  51: defaultItemLevels,
  52: defaultItemLevels,
  53: defaultItemLevels,
  54: defaultItemLevels,
  55: defaultItemLevels,
  56: defaultItemLevels,
  57: defaultItemLevels,
  58: defaultItemLevels,
  59: defaultItemLevels,
  60: defaultItemLevels,
  61: defaultItemLevels,
  62: defaultItemLevels,
  63: defaultItemLevels,
  64: defaultItemLevels,
  65: defaultItemLevels,
  66: defaultItemLevels,
  67: defaultItemLevels,
  68: defaultItemLevels,
  69: defaultItemLevels,
  70: defaultItemLevels,
  71: defaultItemLevels,
  72: defaultItemLevels,
  73: defaultItemLevels,
  74: defaultItemLevels,
  75: defaultItemLevels,
  76: defaultItemLevels,
  77: defaultItemLevels,
  78: defaultItemLevels,
  79: defaultItemLevels,
  80: defaultItemLevels,
  81: defaultItemLevels,
  82: defaultItemLevels,
  83: defaultItemLevels,
  84: defaultItemLevels,
  85: defaultItemLevels,
  86: defaultItemLevels,
  87: defaultItemLevels,
  88: defaultItemLevels,
  89: defaultItemLevels,
  90: defaultItemLevels,
  91: defaultItemLevels,
  92: defaultItemLevels,
  93: defaultItemLevels,
  94: defaultItemLevels,
  95: defaultItemLevels,
  96: defaultItemLevels,
  97: defaultItemLevels,
  98: defaultItemLevels,
  99: defaultItemLevels,
  100: defaultItemLevels,
  101: defaultItemLevels,
  102: defaultItemLevels,
  103: defaultItemLevels,
  104: defaultItemLevels,
  105: defaultItemLevels,
  106: defaultItemLevels,
  107: defaultItemLevels,
  108: defaultItemLevels,
  109: defaultItemLevels,
  110: defaultItemLevels,
  111: defaultItemLevels,
  112: defaultItemLevels,
  113: defaultItemLevels,
  114: defaultItemLevels,
  115: defaultItemLevels,
  116: defaultItemLevels,
  117: defaultItemLevels,
  118: defaultItemLevels,
  119: defaultItemLevels,
  120: defaultItemLevels,
  121: defaultItemLevels,
  122: defaultItemLevels,
  123: defaultItemLevels,
  124: defaultItemLevels,
  125: defaultItemLevels,
  126: defaultItemLevels,
  127: defaultItemLevels,
  128: defaultItemLevels,
  129: defaultItemLevels,
  130: defaultItemLevels,
  131: defaultItemLevels,
  132: defaultItemLevels,
  133: defaultItemLevels,
  134: defaultItemLevels,
  135: defaultItemLevels,
  136: defaultItemLevels,
  137: defaultItemLevels,
  138: defaultItemLevels,
  139: defaultItemLevels,
  140: defaultItemLevels,
};

module.exports = itemLevels;
