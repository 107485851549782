import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import SecureLS from "secure-ls";
import FarmPlots from "./FarmPlots";
import AnimalPlots from "./AnimalPlots";
import EditFarmPlotDialog from "./FarmPlotsDialog";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UserPlot = () => {
  const { userId } = useParams();
  const location = useLocation();
  const userName = location.state?.userName || "User";
  const [plots, setPlots] = useState({ farmPlots: [], animalPlots: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingSeeds, setIsFetchingSeeds] = useState(false);
  const [seeds, setSeeds] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [isAddingPlot, setIsAddingPlot] = useState(false);
  const [isAddingAnimalPlot, setIsAddingAnimalPlot] = useState(false);

  const fetchPlots = async () => {
    setIsLoading(true);
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setPlots({
          farmPlots: data.farmPlots,
          animalPlots: data.animalPlots,
        });
      } else {
        console.error("Failed to fetch plots.");
      }
    } catch (error) {
      console.error("Error fetching plots:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSeeds = async () => {
    setIsFetchingSeeds(true);
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/seeds`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const seedsData = await response.json();
        setSeeds(seedsData);
      } else {
        console.error("Failed to fetch seeds.");
      }
    } catch (error) {
      console.error("Error fetching seeds:", error);
    } finally {
      setIsFetchingSeeds(false);
    }
  };

  const fetchAnimals = async () => {
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/animals`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const animalsData = await response.json();
        setAnimals(animalsData); // Set animals data to state
      } else {
        console.error("Failed to fetch animals.");
      }
    } catch (error) {
      console.error("Error fetching animals:", error);
    }
  };

  useEffect(() => {
    fetchPlots();
    fetchSeeds();
    fetchAnimals();
  }, [userId]);

  const handlePlotUpdate = async () => {
    await fetchPlots();
  };

  const handleAddFarmPlot = async () => {
    setIsAddingPlot(true);
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/farm-add/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        await fetchPlots(); // Refresh the plots after adding a new one
      } else {
        console.error("Failed to add new plot.");
      }
    } catch (error) {
      console.error("Error adding new plot:", error);
    } finally {
      setIsAddingPlot(false);
    }
  };

  const handleAddAnimalPlot = async () => {
    setIsAddingAnimalPlot(true);
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/animal-add/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        await fetchPlots();
      } else {
        console.error("Failed to add new animal plot.");
      }
    } catch (error) {
      console.error("Error adding new animal plot:", error);
    } finally {
      setIsAddingAnimalPlot(false);
    }
  };

  if (isLoading || isFetchingSeeds) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: '"Pixelify Sans", sans-serif',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: "800px",
        mx: "auto",
        mt: 4,
        fontFamily: '"Pixelify Sans", sans-serif',
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <IconButton
          onClick={() => window.history.back()}
          sx={{ marginRight: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: '"Pixelify Sans", sans-serif',
            textAlign: "center",
            flex: 1,
          }}
        >
          {userName}'s Inventory
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 2, fontFamily: '"Pixelify Sans", sans-serif' }}
        >
          Farm Plots
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontFamily: '"Pixelify Sans", sans-serif',
            backgroundColor: "#c8e6c9",
            color: "black",
          }}
          onClick={handleAddFarmPlot}
          disabled={isAddingPlot} // Disable button while adding
        >
          {isAddingPlot ? <CircularProgress size={24} /> : "Add Farm Plot"}
        </Button>
      </Box>
      {plots.farmPlots.length > 0 ? (
        plots.farmPlots.map((plot) => (
          <FarmPlots
            key={plot._id}
            plot={plot}
            seeds={seeds}
            onPlotUpdate={handlePlotUpdate}
          />
        ))
      ) : (
        <Typography variant="body2">No farm plots available.</Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{ mb: 2, fontFamily: '"Pixelify Sans", sans-serif' }}
        >
          Animal Plots
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontFamily: '"Pixelify Sans", sans-serif',
            backgroundColor: "#c8e6c9",
            color: "black",
          }}
          onClick={handleAddAnimalPlot}
          disabled={isAddingAnimalPlot}
        >
          {isAddingAnimalPlot ? (
            <CircularProgress size={24} />
          ) : (
            "Add Animal Plot"
          )}
        </Button>
      </Box>
      {plots.animalPlots.length > 0 ? (
        plots.animalPlots.map((plot) => (
          <AnimalPlots
            key={plot._id}
            plot={plot}
            animals={animals}
            onPlotUpdate={handlePlotUpdate}
          />
        ))
      ) : (
        <Typography variant="body2">No animal plots available.</Typography>
      )}
    </Box>
  );
};

export default UserPlot;
