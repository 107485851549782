import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";

const EditItemModal = ({ open, onClose, itemData, onSave }) => {
  const [editedItem, setEditedItem] = useState("");

  useEffect(() => {
    if (itemData) {
      setEditedItem(JSON.stringify(itemData, null, 2));
    } else {
      setEditedItem("");
    }
  }, [itemData]);

  const handleSave = () => {
    try {
      const parsedItem = JSON.parse(editedItem);
      onSave(parsedItem);
      onClose();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Invalid JSON",
        text: "Please ensure the JSON format is valid before saving.",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{`Edit Item | ${
        itemData?.name || "Unknown Item"
      }`}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ mt: 2 }}
          label="Edit Item JSON"
          multiline
          fullWidth
          rows={20}
          value={editedItem}
          onChange={(e) => setEditedItem(e.target.value)}
          variant="outlined"
          inputProps={{
            style: { fontFamily: "monospace", whiteSpace: "pre-wrap" },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditItemModal;
