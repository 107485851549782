import React, { useState, useEffect } from "react";
import { useTabContext } from "../../TabProvider";
import SecureLS from "secure-ls";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useExp } from "../../ExpContext";
import { sortedInventory, requestSort } from "./SortingUtils";
import { itemLevels } from "../../../System/Levels/itemLevels";
import { formatQuantity } from "../../../../pages/Admin/components/formatQuantity";
import "../../../../../src/css/inventory.css";
import ItemInfoDialog from "./ItemInfoDialog";

export default function InventoryTab({
  filterType,
  updatedInventory,
  localInventory,
  shouldFetchInventory,
  onInventoryFetched,
  userInventoryRef,
  setLocalInventory,
}) {
  const { setActiveTab } = useTabContext();
  const [inventory, setInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { expPercentages, calculateAndUpdateExpPercentages } = useExp();
  const [sortConfig, setSortConfig] = useState({
    key: "itemId",
    direction: "ascending",
  });
  const [isSellDialogOpen, setIsSellDialogOpen] = useState(false);
  const [selectedItemForSale, setSelectedItemForSale] = useState(null);
  const [isItemInfoDialogOpen, setIsItemInfoDialogOpen] = useState(false);
  const [sellQuantity, setSellQuantity] = useState(0);
  const [userId, setUserId] = useState(null);
  const [isInventoryFetched, setIsInventoryFetched] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [bronzeCoinValue, setBronzeCoinValue] = useState(0);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setBronzeCoinValue(0);
    setSellQuantity(0);
    setIsItemInfoDialogOpen(true);
  };

  const getLocalExpNeeded = (itemId, level) => {
    const levels = itemLevels[itemId];
    const levelInfo = levels?.find((l) => l.level === level);
    return levelInfo ? levelInfo.expNeeded : null;
  };

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userIdFromStorage = userDetails ? userDetails.userId : null;
    setUserId(userIdFromStorage);
  }, []);

  useEffect(() => {
    if (userInventoryRef && Array.isArray(userInventoryRef.inventory)) {
      const sortedInventory = userInventoryRef.inventory.sort(
        (a, b) => Number(a.itemId) - Number(b.itemId)
      );
      setInventory(sortedInventory);
    }

    const fetchInventory = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/${userId}`
        );
        if (response.ok) {
          const inventoryResponse = await response.json();
          const inventoryData = inventoryResponse.itemDetails;

          // Ensure inventoryData is an array before sorting
          if (Array.isArray(inventoryData)) {
            const sortedData = inventoryData.sort(
              (a, b) => a.itemId - b.itemId
            );
            setInventory(sortedData);
          } else {
            console.error("Invalid inventory data format");
          }

          onInventoryFetched();
        } else {
          console.error("Failed to fetch inventory data");
        }
      } catch (error) {
        console.error("Error while fetching inventory data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (shouldFetchInventory && userId && !isInventoryFetched) {
      fetchInventory();
    }
  }, [
    shouldFetchInventory,
    userId,
    isInventoryFetched,
    onInventoryFetched,
    userInventoryRef,
    isSuccessDialogOpen,
    setActiveTab,
  ]);

  useEffect(() => {
    let timer;
    if (isSuccessDialogOpen) {
      timer = setTimeout(() => {
        setIsSuccessDialogOpen(false);
      }, 1000); // Close dialog after 1 second
    }
    return () => clearTimeout(timer); // Clear timer on component unmount or if isSuccessDialogOpen changes
  }, [isSuccessDialogOpen]);

  // Function to close dialog
  const handleCloseSellDialog = () => {
    setIsSellDialogOpen(false);
    setSelectedItemForSale(null);
    setSellQuantity(0);
    setBronzeCoinValue(0);
  };

  // Function to handle selling
  const handleSellItem = async (itemId, sellQuantity, bronzeCoinValue) => {
    try {
      if (!userId) {
        console.error("User ID not found in local storage");
        return;
      }

      if (!selectedItem) {
        console.error("No selected item for sale");
        return;
      }

      const requestBody = {
        itemId,
        quantityToSell: sellQuantity,
        itemValue: selectedItem.itemValue,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/sell/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        const updatedInventory = await response.json();
        if (updatedInventory && Array.isArray(updatedInventory.inventory)) {
          // Update the state of Inventory
          setInventory((prevInventory) => {
            const newInventory = [...prevInventory];
            const itemIndex = newInventory.findIndex(
              (item) => item.itemId === itemId
            );
            const coinIndex = newInventory.findIndex(
              (item) => item.itemId === "5"
            );

            if (itemIndex !== -1) {
              newInventory[itemIndex].quantity = Math.max(
                newInventory[itemIndex].quantity - sellQuantity,
                0
              );
            }

            if (coinIndex !== -1) {
              // Update coin quantity
              newInventory[coinIndex].quantity += bronzeCoinValue;
            } else {
              // Add coins to the inventory if they don't exist
              newInventory.push({
                itemId: "5",
                quantity: bronzeCoinValue /* other coin properties */,
              });
            }

            return newInventory;
          });

          // Update userInventoryRef
          if (userInventoryRef && Array.isArray(userInventoryRef.inventory)) {
            const itemIndexInRef = userInventoryRef.inventory.findIndex(
              (item) => item.itemId === itemId
            );

            if (itemIndexInRef !== -1) {
              userInventoryRef.inventory[itemIndexInRef].quantity = Math.max(
                userInventoryRef.inventory[itemIndexInRef].quantity -
                  sellQuantity,
                0
              );
            }

            const coinIndexInRef = userInventoryRef.inventory.findIndex(
              (item) => item.itemId === "5" // Replace "5" with the actual ID for coins
            );

            if (coinIndexInRef !== -1) {
              userInventoryRef.inventory[coinIndexInRef].quantity +=
                bronzeCoinValue;
            } else {
              // Add coins to the inventory ref if they don't exist
              userInventoryRef.inventory.push({
                itemId: "5",
                quantity: bronzeCoinValue /* other coin properties */,
              });
            }

            // Optionally, you can update localInventory if you are using it
            setLocalInventory([...userInventoryRef.inventory]);
          }

          setIsSuccessDialogOpen(true);
          handleCloseSellDialog();
        } else {
          console.error("Failed to sell item. Response:", response);
        }
      } else {
        console.error("Failed to sell item. Response:", response);
      }
    } catch (error) {
      console.error("Error selling item:", error);
    }
  };

  useEffect(() => {
    const calculateAndUpdateExpPercentagesLocally = () => {
      const newExpPercentages = {};
      for (let item of inventory) {
        if (!expPercentages.hasOwnProperty(item.itemId)) {
          const expNeeded = getLocalExpNeeded(item.itemId, item.itemLevel);
          if (expNeeded !== null) {
            newExpPercentages[item.itemId] = (item.itemExp / expNeeded) * 100;
          }
        }
      }
      calculateAndUpdateExpPercentages(newExpPercentages);
    };

    if (inventory.length > 0) {
      calculateAndUpdateExpPercentagesLocally();
    }
  }, [inventory, expPercentages, calculateAndUpdateExpPercentages]);

  useEffect(() => {
    // Remove the merging logic here and directly set the inventory
    if (updatedInventory) {
      setInventory(updatedInventory);
    }
  }, [updatedInventory]);

  // Filtering of Inventory to itemType tabs
  let filteredInventory;
  if (filterType === "all" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(inventory, sortConfig);
  } else if (filterType === "materials" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "material"),
      sortConfig
    );
  } else if (filterType === "processed" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "processed"),
      sortConfig
    );
  } else if (filterType === "crafted" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "crafted"),
      sortConfig
    );
  } else if (filterType === "currency" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "currency"),
      sortConfig
    );
  } else if (filterType === "fish" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "fished"),
      sortConfig
    );
  } else if (filterType === "seeds" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "seeds"),
      sortConfig
    );
  } else if (filterType === "produce" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "harvested"),
      sortConfig
    );
  } else {
    filteredInventory = [];
  }

  const handleQuantityChange = (e) => {
    let newQuantity = e.target.value;
    newQuantity = Math.max(0, newQuantity);

    const maxQuantity = selectedItem?.quantity || 0;
    let quantity =
      newQuantity === ""
        ? ""
        : Math.min(parseInt(newQuantity, 10), maxQuantity);
    setSellQuantity(quantity);

    // Calculate and set bronze coin value based on new quantity
    const newBronzeValue = quantity * (selectedItem?.itemValue || 0);
    setBronzeCoinValue(newBronzeValue);
  };

  const handleBronzeCoinChange = (e) => {
    let newValue = e.target.value;
    newValue = Math.max(0, newValue);

    const maxPossibleValue = selectedItem
      ? selectedItem.itemValue * selectedItem.quantity
      : 0;
    let bronzeValue =
      newValue === "" ? "" : Math.min(parseInt(newValue, 10), maxPossibleValue);
    setBronzeCoinValue(bronzeValue);

    // Calculate and set sell quantity based on new bronze coin value
    const newQuantity = Math.floor(
      bronzeValue / (selectedItem?.itemValue || 1)
    );
    setSellQuantity(newQuantity);
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="inventory-container">
          <div className="inventory-grid">
            {filteredInventory.map((item) => (
              <div
                key={item.id}
                className="inventory-item"
                onClick={() => handleItemClick(item)}
                style={{
                  backgroundColor:
                    item.quantity === 0
                      ? "rgba(255, 0, 0, 0.5)"
                      : "transparent",
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <img src={item.imageUrl} alt={item.itemName} />
                <Tooltip
                  title={`${item.itemName} - ${item.quantity.toLocaleString()}`}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      bottom: "0px",
                      right: "0px",
                      left: "0px",
                      fontWeight: "bold",
                      fontSize: "0.75rem",
                      color: "#000", // Text color
                      textShadow: `
                        -1px -1px 0 #fff,
                        1px -1px 0 #fff,
                        -1px 1px 0 #fff,
                        1px 1px 0 #fff
                      `,
                      padding: "2px",
                      borderRadius: "0 0 2px 2px",
                    }}
                  >
                    {formatQuantity(item.quantity)}
                  </Typography>
                </Tooltip>
              </div>
            ))}
          </div>

          {/* ItemInfoDialog */}
          <ItemInfoDialog
            item={selectedItem}
            open={isItemInfoDialogOpen}
            onClose={() => setIsItemInfoDialogOpen(false)}
            onSellItem={handleSellItem}
          />
        </div>
      )}
      <Dialog
        open={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
      >
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <DialogContentText>Item sold successfully.</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
