// src/components/EditEmptyFarmPlotLogs.js
import React from "react";
import { Typography, Card, CardContent } from "@mui/material";

// Helper function to format dates
const formatDate = (date) => {
  return date ? new Date(date).toLocaleString() : "N/A";
};

const EditEmptyFarmPlotLogs = ({ log }) => {
  const { previousDetails, newDetails } = log.editEmptyFarmPlotLog;

  const renderDetailChange = (label, prevValue, newValue, isDate = false) => {
    const formattedPrevValue = isDate ? formatDate(prevValue) : prevValue;
    const formattedNewValue = isDate ? formatDate(newValue) : newValue;

    if (formattedPrevValue !== formattedNewValue) {
      return (
        <Typography variant="body2">
          {label}: {formattedPrevValue} &rarr; {formattedNewValue}
        </Typography>
      );
    }
    return null;
  };

  return (
    <Card sx={{ marginBottom: 2, boxShadow: "0px 3px 10px rgba(0,0,0,0.2)" }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          {formatDate(log.date)}
        </Typography>
        <Typography variant="h6">{log.transactType}</Typography>
        <Typography variant="body1">
          {log.userName} <small>({log.userId})</small>
        </Typography>
        <Typography variant="body2">
          Plot Number: {log.editEmptyFarmPlotLog.plotNumber}
        </Typography>

        {renderDetailChange(
          "Status",
          previousDetails.productStatus,
          newDetails.productStatus
        )}
        {renderDetailChange(
          "Date Planted",
          previousDetails.datePlanted,
          newDetails.datePlanted,
          true // Indicate that this is a date field
        )}
        {renderDetailChange(
          "Date to Harvest",
          previousDetails.dateToHarvest,
          newDetails.dateToHarvest,
          true // Indicate that this is a date field
        )}
        {renderDetailChange(
          "Date to Wilt",
          previousDetails.dateToWilt,
          newDetails.dateToWilt,
          true // Indicate that this is a date field
        )}
        {renderDetailChange(
          "Seed Planted",
          `${previousDetails.seedPlanted || "N/A"} (${
            previousDetails.seedPlanted || "N/A"
          })`,
          `${newDetails.seedPlanted || "N/A"} (${
            newDetails.seedPlanted || "N/A"
          })`
        )}

        {renderDetailChange(
          "Harvest Product",
          `${previousDetails.harvestProduct?.productName || "N/A"} (${
            previousDetails.harvestProduct?.productId || "N/A"
          })`,
          `${newDetails.harvestProduct?.productName || "N/A"} (${
            newDetails.harvestProduct?.productId || "N/A"
          })`
        )}
        {renderDetailChange(
          "Watered",
          previousDetails.isWatered,
          newDetails.isWatered
        )}
        {renderDetailChange(
          "Water Interval",
          previousDetails.waterInterval,
          newDetails.waterInterval
        )}
        {renderDetailChange(
          "Water Amount",
          previousDetails.waterAmount,
          newDetails.waterAmount
        )}
        {renderDetailChange(
          "Type of Water",
          previousDetails.typeOfWater || "N/A",
          newDetails.typeOfWater || "N/A"
        )}

        <Typography variant="caption">
          Admin: {log.adminName} <small>({log.adminId})</small>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EditEmptyFarmPlotLogs;
