import React from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
} from "@mui/material";

const LoadingDialog = ({ open, message = "Loading..." }) => (
  <Dialog open={open} maxWidth="sm" fullWidth>
    <DialogContent
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress />
      <Typography variant="body1" sx={{ mt: 2 }}>
        {message}
      </Typography>
    </DialogContent>
  </Dialog>
);

export default LoadingDialog;
