import axios from "axios";
import SecureLS from "secure-ls";
import { useCallback } from "react";

export const handleProcess = (
  items,
  setUserState,
  userInventoryRef,
  setProcessBatch,
  addNotification
) =>
  useCallback(
    async (itemId, amountToProcess) => {
      try {
        const ls = new SecureLS({ isCompression: false });
        const userDetails = ls.get("userDetails");
        const userId = userDetails ? userDetails.userId : null;

        if (!userId) {
          console.error("User ID not found in local storage");
          return;
        }

        const payload = {
          itemIds: [{ itemId, amount: amountToProcess }],
        };

        console.log(
          "Sending batch process request to backend with payload:",
          payload
        );

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/batchProcess`,
          payload
        );

        if (response && response.data) {
          const { user, itemsProcessed } = response.data;

          const processedItem = itemsProcessed.find(
            (item) => String(item.itemId) === String(itemId)
          );

          if (processedItem) {
            const modalData = {
              name: processedItem.name,
              img: processedItem.img,
              amountProcessed: amountToProcess.toLocaleString(),
              newQuantity: processedItem.newQuantity.toLocaleString(),
            };
            // Store modal data in SecureLS
            ls.set("processModalData", modalData);
          } else {
            console.warn(`No processed item found with itemId: ${itemId}`);
          }

          // Update user state
          setUserState((prevState) => ({
            ...prevState,
            userProfile: {
              ...prevState.userProfile,
              exp: user.exp,
              level: user.level,
              skills: user.skills,
            },
          }));

          // Update inventory
          userInventoryRef.current.itemDetails = Array.from(
            response.data.inventory.entries()
          );

          setProcessBatch((prevBatch) => ({
            ...prevBatch,
            [itemId]: (prevBatch[itemId] || 0) + 1,
          }));

          addNotification({
            id: Date.now(),
            name: "Batch Process",
            message: `Processed ${amountToProcess} items`,
          });

          console.log("User inventory and state updated successfully.");
        }
      } catch (error) {
        console.error("Error processing item:", error);
      }
    },
    [setUserState, userInventoryRef, setProcessBatch, addNotification]
  );
