import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet, useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import AdminSidebar from "./components/AdminSideBar";

const AdminLayout = () => {
  const [open, setOpen] = useState(false);
  const ls = new SecureLS({ isCompression: false });
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open); 
  };

  const handleMenuClick = (path) => {
    navigate(path); 
  };

  const handleLogout = () => {
    ls.removeAll();
    navigate("/admin/login"); 
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#3f51b5", 
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <AdminSidebar
        open={open}
        handleMenuClick={handleMenuClick} 
        handleLogout={handleLogout}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          transition:
            "margin 0.5s ease-in-out, transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
          padding: "64px 24px 24px",
          minWidth: 0, 
          transform: open ? "translateX(0)" : "translateX(-20px)", 
          opacity: open ? 1 : 0.8, 
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;
