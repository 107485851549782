import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const AnimalPlotsDialog = ({ open, onClose, plot, onSave, animals }) => {
  const [originalDetails, setOriginalDetails] = useState({});
  const [newDetails, setNewDetails] = useState({
    addedAnimalId: plot.animalDetails.addedAnimalId || "",
    datePlaced: plot.animalDetails.datePlaced
      ? dayjs(plot.animalDetails.datePlaced)
      : null,
    dateFed: plot.animalDetails.dateFed
      ? dayjs(plot.animalDetails.dateFed)
      : null,
    dateToFeed: plot.animalDetails.dateToFeed
      ? dayjs(plot.animalDetails.dateToFeed)
      : null,
    dateToHarvest: plot.animalDetails.dateToHarvest
      ? dayjs(plot.animalDetails.dateToHarvest)
      : null,
    dateToMaturity: plot.animalDetails.dateToMaturity
      ? dayjs(plot.animalDetails.dateToMaturity)
      : null,
    dateToDeath: plot.animalDetails.dateToDeath
      ? dayjs(plot.animalDetails.dateToDeath)
      : null,
    currentLifeStage: plot.animalDetails.currentLifeStage || "baby",
    isFed: plot.animalDetails.isFed || false,
    feedType: plot.animalDetails.feedType || "",
    feedAmount: plot.animalDetails.feedAmount || 1,
    affectionLvl: plot.animalDetails.affectionLvl || 0,
    timeToAdult: plot.animalDetails.timeToAdult || 1,
  });

  // Store the original plot details when the component mounts
  useEffect(() => {
    setOriginalDetails({
      addedAnimalId: plot.animalDetails.addedAnimalId || "",
      datePlaced: plot.animalDetails.datePlaced
        ? dayjs(plot.animalDetails.datePlaced)
        : null,
      dateFed: plot.animalDetails.dateFed
        ? dayjs(plot.animalDetails.dateFed)
        : null,
      dateToFeed: plot.animalDetails.dateToFeed
        ? dayjs(plot.animalDetails.dateToFeed)
        : null,
      dateToHarvest: plot.animalDetails.dateToHarvest
        ? dayjs(plot.animalDetails.dateToHarvest)
        : null,
      dateToMaturity: plot.animalDetails.dateToMaturity
        ? dayjs(plot.animalDetails.dateToMaturity)
        : null,
      dateToDeath: plot.animalDetails.dateToDeath
        ? dayjs(plot.animalDetails.dateToDeath)
        : null,
      currentLifeStage: plot.animalDetails.currentLifeStage || "baby",
      isFed: plot.animalDetails.isFed || false,
      feedType: plot.animalDetails.feedType || "",
      feedAmount: plot.animalDetails.feedAmount || 1,
      affectionLvl: plot.animalDetails.affectionLvl || 0,
      timeToAdult: plot.animalDetails.timeToAdult || 1,
    });
  }, [plot.animalDetails]);

  // Prefill the details based on the selected animal
  useEffect(() => {
    if (newDetails.addedAnimalId) {
      const selectedAnimal = animals.find(
        (animal) => animal.itemId === newDetails.addedAnimalId
      );
      if (selectedAnimal) {
        const currentDate = dayjs();
        const dateFed = currentDate.subtract(1, "day"); // Date fed is one day before current date
        const nextFeedTime = dateFed.add(1, "day"); // Next feed time is one day after date fed
        const maturityTime = selectedAnimal.itemAnimal.timeToAdult
          ? currentDate.add(selectedAnimal.itemAnimal.timeToAdult, "day")
          : currentDate;
        const harvestTime = maturityTime.add(1, "day"); // Example: harvest is 1 day after maturity
        const deathTime = nextFeedTime.add(1, "day"); // Death time is one day after the next feed time

        setNewDetails((prevDetails) => ({
          ...prevDetails,
          datePlaced: prevDetails.datePlaced || currentDate,
          dateFed: dateFed,
          dateToFeed: nextFeedTime,
          dateToMaturity: maturityTime,
          dateToHarvest: harvestTime,
          dateToDeath: deathTime,
          feedType: selectedAnimal.itemAnimal.feedType,
          feedAmount: selectedAnimal.itemAnimal.feedAmount,
          currentLifeStage: selectedAnimal.itemAnimal.lifeStage || "baby",
        }));
      }
    }
  }, [newDetails.addedAnimalId, animals]);

  // Handle the change in form fields
  const handleChange = (field) => (event) => {
    setNewDetails({
      ...newDetails,
      [field]: event.target.value,
    });
  };

  // Handle the change in date fields
  const handleDateChange = (field) => (date) => {
    setNewDetails({
      ...newDetails,
      [field]: date,
    });
  };

  // Save the updated plot details
  const handleSave = () => {
    const updatedDetails = {
      ...newDetails,
      datePlaced: newDetails.datePlaced
        ? newDetails.datePlaced.toISOString()
        : null,
      dateFed: newDetails.dateFed ? newDetails.dateFed.toISOString() : null,
      dateToFeed: newDetails.dateToFeed
        ? newDetails.dateToFeed.toISOString()
        : null,
      dateToHarvest: newDetails.dateToHarvest
        ? newDetails.dateToHarvest.toISOString()
        : null,
      dateToMaturity: newDetails.dateToMaturity
        ? newDetails.dateToMaturity.toISOString()
        : null,
      dateToDeath: newDetails.dateToDeath
        ? newDetails.dateToDeath.toISOString()
        : null,
      currentLifeStage: newDetails.currentLifeStage,
    };
    onSave(updatedDetails);
  };

  // Clear all the fields
  const handleClearAll = () => {
    setNewDetails({
      addedAnimalId: "",
      datePlaced: null,
      dateFed: null,
      dateToFeed: null,
      dateToHarvest: null,
      dateToMaturity: null,
      dateToDeath: null,
      currentLifeStage: "baby",
      isFed: false,
      feedType: "",
      feedAmount: 1,
      affectionLvl: 0,
      timeToAdult: 1,
    });
  };

  // Reset to original details
  const handleReset = () => {
    setNewDetails(originalDetails);
  };

  const isSaveDisabled =
    !newDetails.addedAnimalId ||
    Object.values(newDetails).some((value) => value === null || value === "");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Animal Plot</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Animal"
                value={newDetails.addedAnimalId}
                onChange={handleChange("addedAnimalId")}
                fullWidth
                sx={{ mt: 1 }}
              >
                {animals.map((animal) => (
                  <MenuItem key={animal.itemId} value={animal.itemId}>
                    <img
                      src={animal.imageUrl}
                      alt={animal.name}
                      style={{ width: 20, marginRight: 8 }}
                    />
                    {animal.name} ({animal.itemId})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Date Placed"
                value={newDetails.datePlaced}
                onChange={handleDateChange("datePlaced")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Date Fed"
                value={newDetails.dateFed}
                onChange={handleDateChange("dateFed")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Next Feed Time"
                value={newDetails.dateToFeed}
                onChange={handleDateChange("dateToFeed")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Harvest Time"
                value={newDetails.dateToHarvest}
                onChange={handleDateChange("dateToHarvest")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Maturity Time"
                value={newDetails.dateToMaturity}
                onChange={handleDateChange("dateToMaturity")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Death Time"
                value={newDetails.dateToDeath}
                onChange={handleDateChange("dateToDeath")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Life Stage"
                value={newDetails.currentLifeStage}
                onChange={handleChange("currentLifeStage")}
                fullWidth
                disabled={!newDetails.addedAnimalId}
              >
                <MenuItem value="baby">Baby</MenuItem>
                <MenuItem value="adult">Adult</MenuItem>
                <MenuItem value="dead">Dead</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Feed Type"
                value={newDetails.feedType}
                onChange={handleChange("feedType")}
                fullWidth
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Feed Amount"
                value={newDetails.feedAmount}
                onChange={handleChange("feedAmount")}
                type="number"
                fullWidth
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Affection Level"
                value={newDetails.affectionLvl}
                onChange={handleChange("affectionLvl")}
                type="number"
                fullWidth
                disabled={!newDetails.addedAnimalId}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Clear all Fields" arrow>
            <Button onClick={handleClearAll} color="warning">
              Clear All
            </Button>
          </Tooltip>
          <Tooltip title="Restore Previous Field" arrow>
            <Button
              onClick={handleReset}
              color="secondary"
              sx={{ color: "red" }}
            >
              Reset
            </Button>
          </Tooltip>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default AnimalPlotsDialog;
