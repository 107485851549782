import { useState } from "react";
import {
  Box,
  Card,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const FishingRodStatusCard = ({
  fishingRodQuantity,
  wormQuantity,
  fishingAttempts,
  FISHING_ROD_DURABILITY,
}) => {
  const [isCardOpen, setIsCardOpen] = useState(true);

  const toggleCardVisibility = () => {
    setIsCardOpen(!isCardOpen);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 60,
        right: 20,
        padding: "10px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      {isCardOpen ? (
        <Card
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
            padding: "10px",
            borderRadius: "8px",
            color: "white",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2">Fishing Status</Typography>
            <IconButton
              size="small"
              sx={{ color: "white" }}
              onClick={toggleCardVisibility}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <img
              src="/assets/items/crafting/fishingrod.webp"
              alt="Fishing Rod"
              style={{ width: 24, height: 24 }}
            />
            <Typography variant="body2">
              Fishing Rods: {fishingRodQuantity.toLocaleString()}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <img
              src="/assets/items/processed/worm1-processed.webp"
              alt="Worm"
              style={{ width: 24, height: 24 }}
            />
            <Typography variant="body2">
              Worms: {wormQuantity.toLocaleString()}
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ width: "100%" }}
          >
            <Typography variant="body2">Durability:</Typography>
            <LinearProgress
              variant="determinate"
              value={
                100 -
                ((fishingAttempts % FISHING_ROD_DURABILITY) /
                  FISHING_ROD_DURABILITY) *
                  100
              }
              sx={{
                width: "100%",
                marginTop: "5px",
                color: "white",
                borderRadius: "5px", // Added to make the LinearProgress rounded
                "& .MuiLinearProgress-bar": {
                  borderRadius: "5px", // Ensures the progress bar itself is rounded
                },
              }}
            />
          </Box>
        </Card>
      ) : (
        <Box
          sx={{
            width: "100px",
            display: "flex",
            flexDirection: "column", // Change to column layout
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent background
            padding: "5px",
            borderRadius: "8px",
          }}
          onClick={toggleCardVisibility}
        >
          <Typography
            variant="body2"
            sx={{ color: "white", textAlign: "center" }}
          >
            Durability:
          </Typography>
          <LinearProgress
            variant="determinate"
            value={
              100 -
              ((fishingAttempts % FISHING_ROD_DURABILITY) /
                FISHING_ROD_DURABILITY) *
                100
            }
            sx={{
              width: "100%",
              marginTop: "5px",
              color: "white",
              borderRadius: "5px", // Added to make the LinearProgress rounded
              "& .MuiLinearProgress-bar": {
                borderRadius: "5px", // Ensures the progress bar itself is rounded
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};
