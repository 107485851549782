import React from "react";
import { Container, Typography, Box } from "@mui/material";
import SecureLS from "secure-ls";

const AdminDashboard = () => {
  const ls = new SecureLS({ isCompression: false });
  const adminDetails = ls.get("adminDetails");
  const adminName = adminDetails ? adminDetails.name : "Admin";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", 
        alignItems: "flex-start",
        height: "calc(100vh - 64px)", 
        paddingTop: "64px", 
      }}
    >
      <Container sx={{ textAlign: "center", marginTop: "20px" }}>
        <Typography variant="h4" gutterBottom>
          Welcome, {adminName}!
        </Typography>
      </Container>
    </Box>
  );
};

export default AdminDashboard;
