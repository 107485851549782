// services/inventoryService.js

import { getExpForNextItemLevel } from "../modules/System/Levels/itemLevels";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchUserInventory = async (userId) => {
  try {
    const response = await fetch(`${BASE_URL}/v1/api/inventory/${userId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch user inventory");
    }
    return await response.json();
  } catch (error) {
    console.error("Error while fetching user inventory", error);
    throw error;
  }
};

export const fetchExpNeeded = async (itemId, level) => {
  try {
    // Use the local function to get the experience needed for the next level
    const expNeeded = getExpForNextItemLevel(level, itemId);

    if (expNeeded !== null) {
      return expNeeded;
    } else {
      console.error("Exp needed data not found in local data.");
      return null;
    }
  } catch (error) {
    console.error("Error while fetching expNeeded", error);
    return null;
  }
};
