import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditSkillDialog from "./EditSkillDialog";
import AddRemoveSkillDialog from "./AddRemoveSkillDialog";

const UnlockedSkills = ({ skills, onSkillUpdate, onSkillRemove }) => {
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const handleEditClick = (skill) => {
    setSelectedSkill(skill);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedSkill(null);
  };

  const handleSaveSkill = (updatedSkill) => {
    onSkillUpdate(updatedSkill);
    setIsDialogOpen(false);
  };

  const handleRemoveClick = (skill) => {
    setSelectedSkill(skill);
    setIsRemoveDialogOpen(true);
  };

  return (
    <Box>
      {skills.length > 0 ? (
        skills.map((skill) => (
          <Card key={skill._id} sx={{ mb: 2, display: "flex" }}>
            <CardContent
              sx={{ display: "flex", alignItems: "center", flex: 1 }}
            >
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  mr: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={skill.skillImage}
                  alt={skill.skillName}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: '"Pixelify Sans", sans-serif',
                    fontWeight: "bold",
                  }}
                >
                  {skill.skillName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {skill.description}
                </Typography>
                <Typography variant="body2">
                  Level: {skill.skillLevel} | EXP:{" "}
                  {skill.skillExp.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  / {skill.expNeeded.toLocaleString()} (
                  {((skill.skillExp / skill.expNeeded) * 100).toFixed(2)}%)
                </Typography>
              </Box>
            </CardContent>
            <Box
              sx={{ display: "flex", alignItems: "center", paddingRight: 2 }}
            >
              <Stack direction="column" spacing={1}>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  sx={{
                    backgroundColor: "#a6c9f2",
                    color: "black",
                    fontSize: "0.75rem",
                    padding: "4px 12px",
                    "&:hover": { backgroundColor: "#8bb4e0" },
                  }}
                  onClick={() => handleEditClick(skill)}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  sx={{
                    backgroundColor: "#f5a6a6",
                    color: "black",
                    fontSize: "0.75rem",
                    padding: "4px 12px",
                    "&:hover": { backgroundColor: "#e08a8a" },
                  }}
                  onClick={() => handleRemoveClick(skill)}
                >
                  Remove
                </Button>
              </Stack>
            </Box>
          </Card>
        ))
      ) : (
        <Typography variant="body2">No unlocked skills available.</Typography>
      )}

      {selectedSkill && (
        <>
          <EditSkillDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            skill={selectedSkill}
            onSave={handleSaveSkill}
          />
          <AddRemoveSkillDialog
            open={isRemoveDialogOpen}
            onClose={() => setIsRemoveDialogOpen(false)}
            skill={selectedSkill}
            onConfirm={async () => {
              const success = await onSkillRemove(selectedSkill.skillId);
              if (success) {
                setIsRemoveDialogOpen(false);
              }
            }}
            isAdd={false}
          />
        </>
      )}
    </Box>
  );
};

export default UnlockedSkills;
