import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ForageTabCardsSlider from "./ForageTabCardsSlider";
import ForagingActivity from "./ForagingActivity";

const ForageTab = ({
  foragingLocations,
  lockedForagingLocations, // Add this prop for locked locations
  onForage,
  onBackToSkills,
  userInventory,
  setUserInventory,
  items,
  userInventoryRef,
  userProfileRef,
  forageInventoryRef,
  onGather,
  onAutoGather,
  purchasedItems,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isForaging, setIsForaging] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isAutoGather, setIsAutoGather] = useState(false);

  const startForaging = (location) => {
    setCurrentLocation(location);
    setIsForaging(true);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filter unlocked and locked locations based on search term
  const filteredUnlockedLocations = foragingLocations.filter((location) =>
    location.locationName?.toLowerCase().includes(searchTerm)
  );

  const filteredLockedLocations = lockedForagingLocations.filter((location) =>
    location.locationName?.toLowerCase().includes(searchTerm)
  );

  return (
    <>
      {isForaging ? (
        <ForagingActivity
          location={currentLocation}
          items={items}
          userInventoryRef={userInventoryRef}
          userProfileRef={userProfileRef}
          onBackToForagingTab={() => setIsForaging(false)}
          forageInventoryRef={forageInventoryRef}
          onGather={onGather}
          onAutoGather={onAutoGather}
          isAutoGather={isAutoGather}
          purchasedItems={purchasedItems}
        />
      ) : (
        <div
          style={{
            padding: "0 20px",
            margin: "auto",
            maxWidth: "400px",
            marginBottom: "10px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h2 style={{ color: "green" }}>Forage</h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <IconButton onClick={onBackToSkills}>
              <ArrowBack />
            </IconButton>
            <TextField
              label="Search Locations"
              variant="outlined"
              margin="normal"
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: "20px" },
              }}
              style={{ width: "266px", marginLeft: "10px" }}
            />
          </div>
          <div style={{ marginTop: "7px" }}>
            {filteredUnlockedLocations.length > 0 ||
            filteredLockedLocations.length > 0 ? (
              <ForageTabCardsSlider
                locations={filteredUnlockedLocations}
                lockedLocations={filteredLockedLocations} // Pass locked locations
                onForage={startForaging}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                items={items}
                userInventoryRef={userInventoryRef}
                userProfileRef={userProfileRef}
                forageInventoryRef={forageInventoryRef}
              />
            ) : (
              <Typography textAlign="center" mt={2}>
                No locations available or no match found.
              </Typography>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ForageTab;
