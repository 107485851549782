import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  Card,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAutoGather } from "./AutoGatherContext";
import "../../../../../css/home.css";

const DISABLED_REFRESH_ITEM_IDS = [0];

const ItemCard = ({
  item,
  lockedItems,
  onGather,
  onAutoGather,
  purchasedItems,
  isAutoGather,
  onAutoGatherChange,
  isLocked = false,
  allItems = [],
  otherLockedItems,
}) => {
  const [displayLevel, setDisplayLevel] = useState(item.itemLvl);
  const [displayExp, setDisplayExp] = useState("0%");
  const [localPurchasedItems, setLocalPurchasedItems] =
    useState(purchasedItems);
  const [open, setOpen] = useState(false);
  const [NotificationInterval, setNotificationInterval] = useState(null);
  const [clickPositions, setClickPositions] = useState([]);
  const [imageInstances, setImageInstances] = useState([]);

  const {
    itemCardStates,
    updateItemCardState,
    autoGatherStates,
    updateAutoGatherState,
    animationStates,
    updateAnimationState,
    isGatheringInProgressStates,
    updateIsGatheringInProgressState,
  } = useAutoGather();

  const wowAutoGather = itemCardStates[item.itemId] || false;
  const isAutoGatherActive = autoGatherStates[item.itemId] || false;
  const isAnimating = animationStates[item.itemId] || false;
  const isGatheringInProgress =
    isGatheringInProgressStates[item.itemId] || false;

  const isRefreshDisabled = DISABLED_REFRESH_ITEM_IDS.includes(item.itemId);

  useEffect(() => {
    setLocalPurchasedItems(purchasedItems);

    // Directly calculate EXP percentage based on item's current EXP and EXP needed for next level
    const expPercentage =
      item.itemExp && item.expNeeded
        ? ((item.itemExp / item.expNeeded) * 100).toFixed(2)
        : 0;
    setDisplayExp(`${expPercentage}%`);
  }, [item.itemExp, item.expNeeded, purchasedItems]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleReloadClick = () => {
    updateItemCardState(item.itemId, !wowAutoGather);
  };

  const handleGatherClick = (e) => {
    if (!isLocked) {
      const buttonRect = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - buttonRect.left - 60;
      const offsetY = e.clientY - buttonRect.top;

      const newClickPosition = { x: offsetX, y: offsetY, id: Date.now() };

      setClickPositions((prevPositions) => [
        ...prevPositions,
        newClickPosition,
      ]);

      setImageInstances((prevInstances) => [
        ...prevInstances,
        <FloatingImage
          key={newClickPosition.id}
          imageUrl={item.imageUrl}
          x={offsetX}
          y={offsetY}
        />,
      ]);

      onGather(item.itemId);
    }
  };

  const FloatingImage = ({ imageUrl, x, y }) => {
    const animationProps = useSpring({
      from: { opacity: 1, transform: `translate(${x}px, ${y}px) scale(1)` },
      to: {
        opacity: 0,
        transform: `translate(${x}px, ${y - 50}px) scale(0.5)`,
      },
      config: { duration: 2000 },
      onRest: () => {
        setImageInstances((prevInstances) =>
          prevInstances.filter((instance) => instance.key !== `${x}-${y}`)
        );
      },
    });

    return (
      <animated.img
        src={imageUrl || "/assets/items/default.webp"}
        alt="Gathered item"
        style={{
          ...animationProps,
          position: "absolute",
          pointerEvents: "none", // Ensure it doesn't block the button
          width: 30,
          height: 30,
          zIndex: 3,
        }}
      />
    );
  };

  const handleAutoGatherToggle = () => {
    onAutoGather(item.itemId);
    const newAutoGatherState = !isAutoGatherActive;
    updateAutoGatherState(item.itemId, newAutoGatherState);

    if (!isAutoGatherActive) {
      toast.success("Auto Gather Started");
      updateIsGatheringInProgressState(item.itemId, true);
      updateAnimationState(item.itemId, true);
    } else {
      clearInterval(NotificationInterval);
      toast.error("Auto Gather Dismissed");
      updateIsGatheringInProgressState(item.itemId, false);
      updateAnimationState(item.itemId, false);
    }

    onAutoGatherChange(item.itemId, newAutoGatherState);
  };

  const autoGatherMap = {
    "Stone Auto Gather": 1,
    "Sand Auto Gather": 2,
    "Branch Auto Gather": 3,
    "Leaf Auto Gather": 4,
    "Dirt Auto Gather": 10,
    "Pebbles Auto Gather": 22,
    "Clay Auto Gather": 61,
    "Moss Auto Gather": 87,
  };

  const isAutoGatherPurchased = (productName, itemId, purchasedItems) =>
    purchasedItems?.some(
      (purchasedItem) =>
        purchasedItem.productName === productName &&
        purchasedItem.status === "purchased"
    ) || false;

  const getUnlockRequirement = () => {
    if (item.itemPreq && item.itemPreq.length > 0) {
      const preq = item.itemPreq[0];
      const requiredItem = allItems.find((itm) => itm.itemId === preq.itemId);

      const isLockedItem =
        (Array.isArray(lockedItems) &&
          lockedItems.some(
            (lockedItem) => lockedItem.itemId === preq.itemId
          )) ||
        (Array.isArray(otherLockedItems) &&
          otherLockedItems.some(
            (lockedItem) => lockedItem.itemId === preq.itemId
          ));

      if (requiredItem) {
        const itemName = isLockedItem ? "???" : requiredItem.name;
        return `Progress "${itemName}" to level ${preq.requiredLevel} to unlock.`;
      }
    }
    return null;
  };

  const unlockRequirementText = getUnlockRequirement();

  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          position: "relative", // Ensure text and button are positioned correctly
        }}
      >
        <CardHeader
          sx={{
            textAlign: "center",
          }}
          title={`${isLocked ? "???" : item.name} ${
            isRefreshDisabled ? "(Leaderboard)" : ""
          }`}
          subheader={`Level: ${displayLevel} Exp: ${displayExp}`}
        />
        <CardMedia
          component="img"
          sx={{
            width: 190,
            maxWidth: 190,
            height: 190,
            maxHeight: 190,
            objectFit: "contain",
            display: "block",
            margin: "auto",
            textAlign: "center",
            filter: isLocked ? "grayscale(100%) blur(3px)" : "none", // Apply blur and grayscale only to the image
          }}
          image={item.imageUrl || "/assets/items/default.webp"}
          alt={item.name}
          onClick={!isLocked ? handleOpen : undefined}
        />

        {isLocked && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 2, // Ensure text appears above the image
              pointerEvents: "none", // Prevent interaction with locked items
            }}
          >
            <Typography
              variant="h4"
              color="white"
              sx={{
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
              }}
            >
              LOCKED
            </Typography>
            <Typography
              variant="caption"
              color="white"
              sx={{
                mt: 1,
                textAlign: "center",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
              }}
            >
              {unlockRequirementText}
            </Typography>
          </Box>
        )}

        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Button
              variant="contained"
              color={wowAutoGather ? "success" : "primary"}
              onClick={
                !isLocked
                  ? wowAutoGather
                    ? handleAutoGatherToggle
                    : handleGatherClick
                  : undefined
              }
              disabled={isLocked}
              sx={{
                zIndex: 1,
                mt: -1,
                minWidth: 120,
                whiteSpace: "nowrap",
                height: "50px",
                backgroundColor: isAnimating ? "black !important" : "",
              }}
              className={isAnimating ? "animate-border" : ""}
            >
              {isLocked ? "Locked" : wowAutoGather ? "Auto Gather" : "Gather"}
            </Button>
            {imageInstances.map((instance) => instance)}
          </Box>

          {!isLocked && (
            <IconButton style={{ float: "right" }}>
              {Object.entries(autoGatherMap).map(
                ([productName, autoGatherItemId]) =>
                  isAutoGatherPurchased(
                    productName,
                    item.itemId,
                    localPurchasedItems
                  ) &&
                  item.itemId === autoGatherItemId && (
                    <Button
                      key={autoGatherItemId}
                      variant="contained"
                      color="error"
                      onClick={handleReloadClick}
                      disabled={isGatheringInProgress || isRefreshDisabled}
                      sx={{
                        position: "absolute",
                        top: "-5px",
                        right: "5px",
                        transform: "translateY(-111%) translateX(-5px)",
                        minWidth: "auto",
                        padding: "6px",
                        borderRadius: "50%",
                        zIndex: 2,
                      }}
                    >
                      <RefreshIcon />
                    </Button>
                  )
              )}
            </IconButton>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogContent>
          <Typography
            variant="h5"
            sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}
          >
            {item.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {item.description}
          </Typography>
          <Typography variant="body2">Value: {item.value}</Typography>
          <Typography variant="body2">Level: {displayLevel}</Typography>
          <Typography variant="body2">EXP: {displayExp}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(ItemCard);
