import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { formatQuantity } from "../../components/formatQuantity";

const CollectedItems = ({ items, onItemClick }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(50px, 1fr))",
        gap: 1,
      }}
    >
      {items.map((item) => (
        <Box
          key={item.itemId}
          sx={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            width: "50px",
            height: "50px",
            textAlign: "center",
            backgroundColor: item.quantity === 0 ? "#f8d7da" : "#f9f9f9", // Light red for quantity 0
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => onItemClick(item)}
        >
          <Box sx={{ position: "relative" }}>
            <img
              src={item.imageUrl}
              alt={item.name}
              style={{
                width: "45px",
                height: "45px",
                objectFit: "contain",
              }}
            />
            <Tooltip title={`${item.name} - ${item.quantity.toLocaleString()}`}>
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  right: "0px",
                  left: "0px",
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                  color: "#000",
                  textShadow: `
                    -1px -1px 0 #fff,
                    1px -1px 0 #fff,
                    -1px 1px 0 #fff,
                    1px 1px 0 #fff
                  `,
                  padding: "2px",
                  borderRadius: "0 0 2px 2px",
                }}
              >
                {formatQuantity(item.quantity)}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CollectedItems;
