import React, { useState, useEffect, useRef } from "react";
import SecureLS from "secure-ls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CardMedia,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Badge from "@mui/material/Badge";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PercentIcon from "@mui/icons-material/Percent";
import IconButton from "@mui/material/IconButton";
import DialogContentText from "@mui/material/DialogContentText";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAutoGather } from "../GatherTab/AutoGatherContext";

import {
  itemLevels,
  getExpForNextItemLevel,
} from "../../../../System/Levels/itemLevels";
import {
  locationLevels,
  getExpNeededForNextLevel,
} from "../../../../System/Levels/locationLevels";
import { getFailPercentageForNextLevel } from "../../../../System/Levels/locationLevels";

const ForagingActivity = ({
  location,
  items,
  userInventoryRef,
  userProfileRef,
  onBackToForagingTab,
  forageInventoryRef,
  onGather,
  onAutoGather,
  isAutoGather,
  purchasedItems,
}) => {
  const [forageCount, setForageCount] = useState(0);
  const [caughtForage, setCaughtForage] = useState({});
  const [isForaging, setIsForaging] = useState(false);
  const [foragingProgress, setForagingProgress] = useState(0);
  const [foragingAttempts, setForagingAttempts] = useState(0);
  const [foragingRodQuantity, setForagingRodQuantity] = useState(0);
  const [wormQuantity, setWormQuantity] = useState(0);
  const [forageDialogOpen, setForageDialogOpen] = useState(false);
  const [selectedForageDetail, setSelectedForageDetail] = useState({});
  const [inventoryDialogOpen, setInventoryDialogOpen] = useState(false);
  const [foragedItemImage, setForagedItemImage] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [userId, setUserId] = useState(null);
  const [locationLevel, setLocationLevel] = useState(location.level);
  const [failPercentage, setFailPercentage] = useState(location.failPercentage);
  const [arrowBackDialog, setArrowBackDialog] = useState(false);
  const [probabilityDialogOpen, setProbabilityDialogOpen] = useState(false);
  const [adjustedProbabilitiesState, setAdjustedProbabilitiesState] = useState({
    adjustedProbabilities: [],
    failPercentage: 0,
  });
  const [forageDetails, setForageDetails] = useState([]);
  const [bronzeCoinsEarned, setBronzeCoinsEarned] = useState(0);
  const [bronzeCoinsDialogOpen, setBronzeCoinsDialogOpen] = useState(false);
  const [storageSuccessDialogOpen, setStorageSuccessDialogOpen] =
    useState(false);

  const { autoGatherStates, updateAutoGatherState } = useAutoGather();
  const [buttonHoldStart, setButtonHoldStart] = useState(null);
  const [autoForagingEnabled, setAutoForagingEnabled] = useState(false);

  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const hasPreviousPage = currentPage > 0;
  const hasNextPage = endIndex < forageDetails.length;

  const autoGatherMap = {
    46: "Mint Auto Gather",
    47: "Chamomile Auto Gather",
    48: "Wild Garlic Auto Gather",
  };

  const handlePreviousPage = () => {
    if (hasPreviousPage) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const isAutoGatherPurchased = (itemId, purchasedItems) => {
    const productName = autoGatherMap[itemId];
    return (
      purchasedItems?.some(
        (purchasedItem) =>
          purchasedItem.productName === productName &&
          purchasedItem.status === "purchased"
      ) || false
    );
  };

  const toggleAutoGather = (itemId) => {
    const currentState = autoGatherStates[itemId] || false;

    updateAutoGatherState(itemId, !currentState);
  };

  const startForaging = () => {
    setIsForaging(true);
  };

  useEffect(() => {
    // This effect already exists in your code with slight modification
    let interval;
    if (autoForagingEnabled) {
      startForaging(); // Start immediately when auto-foraging is enabled
      interval = setInterval(() => {
        startForaging(); // Repeat every 3 seconds
      }, 3000); // Adjust time as needed
    }
    return () => clearInterval(interval);
  }, [autoForagingEnabled]);

  // Updated useEffect for setting up forageDetails based on special location
  useEffect(() => {
    if (location.locTag === "spacial-forage") {
      const dynamicForageDetails = userInventoryRef.current.itemDetails
        .filter((item) => {
          const itemIdAsString = String(item.itemId);
          const foundItem = items.find(
            (gameItem) =>
              String(gameItem.itemId) === itemIdAsString &&
              gameItem.itemType === "foraged"
          );
          return foundItem;
        })
        .map((inventoryItem) => {
          // No need to convert here as it's used for finding in the same array
          const fullItemDetails = items.find(
            (item) => String(item.itemId) === String(inventoryItem.itemId)
          );
          return { ...fullItemDetails };
        });

      setForageDetails(dynamicForageDetails);

      // Update forageInventoryRef to use item IDs as keys
      const updatedForageInventory = {};
      dynamicForageDetails.forEach((item) => {
        updatedForageInventory[item.itemId] = { ...item };
      });
      forageInventoryRef.current = updatedForageInventory;
    } else {
      const predefinedForageDetails = location.fishItemIds.map((fishItemId) => {
        const foundItem = items.find((item) => item.itemId === fishItemId);
        return foundItem;
      });

      setForageDetails(predefinedForageDetails);

      const updatedForageInventory = items
        .filter(
          (item) =>
            location.fishItemIds.includes(item.itemId) &&
            item.status === "forage"
        )
        .reduce((acc, item) => {
          const expNeeded = getExpForNextItemLevel(item.itemLvl, item.itemId);
          const levelInfo = itemLevels[item.itemId]?.find(
            (lvl) => lvl.level === item.itemLvl
          );
          const expGain = levelInfo?.expGain || item.expGain;

          acc[item.itemId] = {
            ...item,
            exp: item.itemExp || 0,
            expNeeded,
            expGain, // Updated to dynamically adjust based on item's level
          };
          return acc;
        }, {});

      forageInventoryRef.current = updatedForageInventory;
    }
  }, [location, userInventoryRef.current, items]);

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    setUserId(userDetails ? userDetails.userId : null);
  }, [items, location.fishItemIds, userInventoryRef]);

  useEffect(() => {
    let start = null;
    const duration = 2000; // Duration of the progress in milliseconds

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const percentage = Math.min((progress / duration) * 100, 100);
      setForagingProgress(percentage);

      if (progress < duration) {
        requestAnimationFrame(animate);
      } else {
        setIsForaging(false);
        handleLoanForageClick(); // Trigger the foraging logic after progress completes
      }
    };

    if (isForaging) {
      requestAnimationFrame(animate);
    }
  }, [isForaging]);

  const toggleProbabilityDialog = () => {
    setProbabilityDialogOpen(!probabilityDialogOpen);
  };

  const handleForageCaught = (caughtForageDetail) => {
    const amount = caughtForage[caughtForageDetail.itemId]
      ? caughtForage[caughtForageDetail.itemId] + 1
      : 1;
    setCaughtForage((prev) => ({
      ...prev,
      [caughtForageDetail.itemId]: amount,
    }));

    let itemDetail = forageInventoryRef.current[caughtForageDetail.itemId];
    console.log("itemDetail: ", itemDetail);
    const isForage = caughtForageDetail.status === "forage";
    if (!itemDetail) {
      // Item not in forageInventoryRef, try to find it in the items array
      const itemFromItemsArray = items.find(
        (item) =>
          item.itemId.toString() === caughtForageDetail.itemId.toString()
      );
      if (itemFromItemsArray) {
        // Initialize item in forageInventoryRef with details from items array
        itemDetail = {
          ...itemFromItemsArray,
          itemExp: itemFromItemsArray.itemExp || 0, // Use itemExp from items array
        };
        forageInventoryRef.current[caughtForageDetail.itemId] = itemDetail;
      }
    }

    if (itemDetail) {
      let { itemExp, expNeeded, itemLvl } = itemDetail;
      const levelInfo = itemLevels[caughtForageDetail.itemId]?.find(
        (lvl) => lvl.level === itemLvl
      );
      let expGain = levelInfo?.expGain || itemDetail.expGain; // Use level-specific expGain if available

      itemExp += expGain * amount; // Add expGain for each caught item, considering the amount caught

      // Level up logic
      while (itemExp >= expNeeded) {
        itemExp -= expNeeded;
        itemLvl += 1;
        const nextLevelInfo = itemLevels[caughtForageDetail.itemId]?.find(
          (lvl) => lvl.level === itemLvl
        );
        expGain = nextLevelInfo?.expGain || expGain; // Update expGain based on new level
        expNeeded = getExpForNextItemLevel(itemLvl, caughtForageDetail.itemId);
      }

      // Update the item details in forageInventoryRef
      forageInventoryRef.current[caughtForageDetail.itemId] = {
        ...itemDetail,
        itemExp,
        itemLvl,
        expNeeded,
        expGain,
      };
    } else {
      console.warn(`Detail not found for itemId: ${caughtForageDetail.itemId}`);
    }

    // Update user experience and levels accordingly
    updateUserExpAndLevels(caughtForageDetail.expGain);
    setForagedItemImage(caughtForageDetail.imageUrl);

    setTimeout(() => setForagedItemImage(null), 1750);
  };

  const updateUserExpAndLevels = async (expGain) => {
    const foragingSkillIndex = userProfileRef.current.skills.findIndex(
      (skill) => skill.skillId === 5
    );

    console.log("foragingSkillIndex: ", foragingSkillIndex);

    if (foragingSkillIndex !== -1) {
      // Update foraging skill experience
      userProfileRef.current.skills[foragingSkillIndex].skillExp += expGain;

      const foragingSkill = userProfileRef.current.skills[foragingSkillIndex];

      // Check if the foraging skill level needs to be increased
      if (
        foragingSkill.skillExp >=
        getExpNeededForNextLevel(
          foragingSkill.skillLevel,
          userProfileRef.current.locations[0].locationId // Update to use foraging location's ID
        )
      ) {
        foragingSkill.skillExp -= getExpNeededForNextLevel(
          foragingSkill.skillLevel,
          userProfileRef.current.locations[0].locationId // Update to use foraging location's ID
        );
        foragingSkill.skillLevel += 1;
      }
    }

    // Update location experience based on foraging
    const locationIndex = userProfileRef.current.locations.findIndex(
      (loc) => loc.locationId === userProfileRef.current.locations[0].locationId
    );

    if (
      locationIndex !== -1 &&
      userProfileRef.current.locations[locationIndex].level <
        locationLevels[userProfileRef.current.locations[0].locationId].length &&
      forageCount > 0
    ) {
      const currentExpNeeded =
        locationLevels[userProfileRef.current.locations[0].locationId][
          userProfileRef.current.locations[locationIndex].level - 1
        ].expNeeded;

      if (
        userProfileRef.current.locations[locationIndex].exp + expGain >=
        currentExpNeeded
      ) {
        const nextLevelInfo =
          locationLevels[userProfileRef.current.locations[0].locationId][
            userProfileRef.current.locations[locationIndex].level
          ];
        userProfileRef.current.locations[locationIndex].exp =
          userProfileRef.current.locations[locationIndex].exp +
          expGain -
          currentExpNeeded;
        userProfileRef.current.locations[locationIndex].level += 1;
        setFailPercentage(nextLevelInfo.failPercentage);
      } else {
        userProfileRef.current.locations[locationIndex].exp += expGain;
      }
    }

    // Update user experience
    userProfileRef.current.exp += expGain;
  };

  const handleForageClick = (forage) => {
    const isCaught = isForageInInventory(forage.itemId.toString());

    let forageDetail;

    if (location.locTag === "spacial-forage") {
      // For "spacial-forage" location, use forageInventoryRef or fallback to forage
      forageDetail = forageInventoryRef.current[forage.itemId] || forage;
      if (isCaught) {
        const { itemExp, expNeeded } = forageDetail;
        expPercentage = expNeeded
          ? ((itemExp / expNeeded) * 100).toFixed(2)
          : "0";
      }
    } else {
      // For non-"spacial-forage" locations, use userInventoryRef or fallback to items or forage
      forageDetail =
        userInventoryRef.current.itemDetails.find(
          (item) => item.itemId === forage.itemId
        ) ||
        items.find(
          (item) => item.itemId.toString() === forage.itemId.toString()
        ) ||
        forage;
    }

    let expPercentage = "0";

    if (isCaught) {
      let exp = 0;
      let expNeeded = 0;

      if (location.locTag === "spacial-forage") {
        const forageDetail =
          forageInventoryRef.current[forage.itemId] ||
          items.find(
            (item) => item.itemId.toString() === forage.itemId.toString()
          );
        if (forageDetail) {
          exp = forageDetail.itemExp || 0;
          expNeeded = forageDetail.expNeeded || 0;
        }
      } else {
        // For non-"spacial-forage" locations, combine info from `items` and `userInventoryRef`
        const baseDetail = items.find(
          (item) => item.itemId.toString() === forage.itemId.toString()
        );
        const userDetail = userInventoryRef.current.itemDetails.find(
          (item) => item.itemId.toString() === forage.itemId.toString()
        );

        if (baseDetail) {
          expNeeded = baseDetail.expNeeded || 0;
        }
        if (userDetail) {
          exp = userDetail.itemExp || 0;
        }
      }

      if (expNeeded > 0) {
        // Prevent division by zero
        expPercentage = ((exp / expNeeded) * 100).toFixed(2);
      }
    }

    setSelectedForageDetail({
      name: isCaught ? forageDetail.itemName || forageDetail.name : "???",
      description: isCaught ? "Foraged" : "Unforaged",
      forageLvl: isCaught
        ? forageDetail.itemLevel || forageDetail.itemLvl
        : undefined,
      expPercentage,
      imgUrl: forageDetail.imageUrl || "",
      forageDescription: isCaught
        ? forageDetail.description
        : "Forage to discover its details",
      itemId: forageDetail.itemId,
      itemType: forageDetail.itemType,
    });

    setForageDialogOpen(true);
  };

  const handleLoanForageClick = () => {
    const targetLocationId = location.locationId;
    const forageLocation = userProfileRef.current.locations.find(
      (location) => location.locationId === targetLocationId
    );
    const locationLevel = forageLocation ? forageLocation.level : 1;

    setForagingAttempts((prevAttempts) => prevAttempts + 1);

    const currentFailPercentage =
      getFailPercentageForNextLevel(locationLevel, targetLocationId) ||
      failPercentage;

    // Introduce a 40% chance of failing to catch any forage directly
    const failChance = Math.random();
    if (failChance <= currentFailPercentage) {
      setNotification({ open: true, message: "Foraging Failed" });
      return;
    }

    // Adjust the remaining probability for success (60%) among the available forage
    const totalProbability = forageDetails.reduce((total, forage) => {
      const forageDetail =
        userInventoryRef.current.itemDetails.find(
          (item) => item.itemId === forage.itemId
        ) ||
        forageInventoryRef.current[forage.itemId] ||
        forage;
      const forageProbability =
        forageDetail.itemProbability[0]?.probability || 0;
      return total + forageProbability;
    }, 0);

    let adjustedTotalProbability = 0;

    const adjustedProbabilities = forageDetails.map((forage) => {
      const forageDetail =
        userInventoryRef.current.itemDetails.find(
          (item) => item.itemId === forage.itemId
        ) ||
        forageInventoryRef.current[forage.itemId] ||
        forage;
      const originalProbability = forage.itemProbability[0]?.probability || 0;
      const adjustedProbability =
        (originalProbability / totalProbability) *
        (100 - currentFailPercentage * 100);
      adjustedTotalProbability += adjustedProbability;
      return { forage, forageDetail, adjustedProbability };
    });

    const sortedAdjustedProbabilities = adjustedProbabilities.sort(
      (a, b) => b.adjustedProbability - a.adjustedProbability
    );

    setAdjustedProbabilitiesState({
      adjustedProbabilities: sortedAdjustedProbabilities,
      failPercentage: currentFailPercentage,
    });

    // adjustedProbabilities.forEach(({ forageDetail, adjustedProbability }) => {
    //   console.log(
    //     `Forage: ${
    //       forageDetail.name
    //     }, Adjusted Probability: ${adjustedProbability.toFixed(2)}%`
    //   );
    // });

    // Determine which forage is caught based on adjusted probabilities
    let cumulativeProbability = 0;
    const randomChance = Math.random() * adjustedTotalProbability;
    let caughtForageDetail;

    for (const { forage, adjustedProbability } of adjustedProbabilities) {
      cumulativeProbability += adjustedProbability;
      if (randomChance <= cumulativeProbability) {
        caughtForageDetail = forage;
        break;
      }
    }

    if (caughtForageDetail) {
      handleForageCaught(caughtForageDetail);

      setForageCount((prevCount) => prevCount + 1);
      setNotification({
        open: true,
        message: `You foraged a ${caughtForageDetail.name}!`,
      });
    } else {
      setNotification({ open: true, message: "Foraging Failed" });
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleOpenInventory = () => {
    setInventoryDialogOpen(true);
  };

  const handleCloseInventory = () => {
    setInventoryDialogOpen(false);
  };

  const handleStoreForage = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    // Prepare forageData based on caughtForage with valid quantities

    console.log("items: ", items);
    console.log("caughtForage: ", caughtForage);
    console.log("userInventoryRef: ", userInventoryRef.current.itemDetails);

    const forageData = Object.entries(caughtForage)
      .filter(([_, quantity]) => quantity > 0)
      .map(([itemId, quantity]) => {
        let forageDetail = forageInventoryRef.current[itemId];
        console.log("forageDetail: ", forageDetail);

        if (!forageDetail) {
          forageDetail = items.find(
            (item) => item.itemId.toString() === itemId
          );
          if (forageDetail) {
            let currentExp = forageDetail.itemExp || forageDetail.expGain; // Default to expGain if itemExp is not defined

            // Check if it's a non-forage item and adjust currentExp accordingly
            if (forageDetail.status !== "forage") {
              const inventoryItem = userInventoryRef.current.itemDetails.find(
                (item) => item.itemId === forageDetail.itemId
              );
              currentExp = inventoryItem ? inventoryItem.itemExp : currentExp; // Use current exp from inventory if available
            }

            // Update forageInventoryRef with complete details from items array
            forageInventoryRef.current[itemId] = {
              ...forageDetail,
              itemExp: currentExp,
              expGain:
                forageDetail.status === "forage" ? forageDetail.expGain : 1, // Ensure non-forage items have expGain of 1
            };
          }
        }

        if (!forageDetail) {
          console.warn(`Forage detail not found for itemId: ${itemId}`);
          return null;
        }

        return {
          itemId,
          expGain: forageDetail.expGain,
          exp: forageDetail.itemExp,
          level: forageDetail.itemLvl,
          amount: quantity,
        };
      })
      .filter(Boolean);

    console.log("forageData: ", forageData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/forage/storeForage`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            forageData,
            locationId: location.locationId,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);

        // Locally update userInventoryRef with the new forage data, ensuring we're only adding valid entries
        const newItemDetails = [...userInventoryRef.current.itemDetails];

        forageData.forEach((forage) => {
          if (!forage) return;
          const index = newItemDetails.findIndex(
            (item) => item.itemId === forage.itemId
          );
          if (index !== -1) {
            newItemDetails[index].itemLevel = forage.level;
            if (newItemDetails[index].quantity) {
              newItemDetails[index].quantity += forage.amount;
            } else {
              newItemDetails[index].quantity = forage.amount;
            }
          } else {
            const forageDetail =
              forageInventoryRef.current[forage.itemId] ||
              items.find((item) => item.itemId === forage.itemId);
            newItemDetails.push({
              itemId: forage.itemId,
              itemName: forageDetail.name,
              itemLevel: forage.level,
              itemType: "foraged",
              imageUrl: forageDetail.imageUrl,
              quantity: forage.amount,
            });
          }
        });

        // Update the inventory reference; use a state setter if userInventoryRef is part of the state
        userInventoryRef.current = {
          ...userInventoryRef.current,
          itemDetails: newItemDetails,
        };

        // Reset local states
        resetForageAmounts();
        setStorageSuccessDialogOpen(true);
      } else {
        console.error(
          "Failed to store forage and update rods",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error storing forage and updating rods:", error);
    } finally {
      resetForageAmounts();
    }
  };

  const handleSellForage = async () => {
    console.log("Selling forages:", forageInventoryRef.current);

    // Calculate the total value from selling the forage
    let totalValue = 0;
    Object.entries(caughtForage).forEach(([itemId, quantity]) => {
      if (quantity > 0) {
        const forageValue = forageInventoryRef.current[itemId]?.value || 0;
        totalValue += forageValue * quantity;
      }
    });

    // Find bronze coins in userInventoryRef and update its amount
    const bronzeCoinIndex = userInventoryRef.current.itemDetails.findIndex(
      (item) => item.itemId === "52"
    );

    if (bronzeCoinIndex !== -1) {
      // Update the quantity of bronze coins
      userInventoryRef.current.itemDetails[bronzeCoinIndex].quantity +=
        totalValue;
    } else {
      // If bronze coins are not in the inventory, add them
      userInventoryRef.current.itemDetails.push({
        itemId: "52",
        itemName: "Forage Coin", // Assuming you have a name field for items
        quantity: totalValue,
        itemType: "currency", // Assuming you have a type field for items
      });
    }

    // Now proceed to inform the backend about the sale for data consistency, without waiting for the total value
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/forage/sellForage`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            forageData: Object.entries(caughtForage)
              .filter(([_, q]) => q > 0)
              .map(([itemId, quantity]) => ({ itemId, amount: quantity })),
            locationId: location.locationId,
            foragingAttempts,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to sell forage");
      // You might want to update the state or UI based on the successful sale
      console.log("Forage sold successfully");

      setBronzeCoinsEarned(totalValue);
      setBronzeCoinsDialogOpen(true);
    } catch (error) {
      console.error("Error during forage sale:", error);
    } finally {
      resetForageAmounts();
    }

    // Reset local states
    resetForageAmounts();
  };

  const resetForageAmounts = () => {
    Object.keys(forageInventoryRef.current).forEach((itemId) => {
      if (forageInventoryRef.current[itemId].amount !== undefined) {
        forageInventoryRef.current[itemId].amount = 0;
      }
    });

    setCaughtForage({});
    setForageCount(0);
    setForagingAttempts(0);
  };

  const isForageInInventory = (fishItemId) => {
    // Check if the forage is already caught
    if (caughtForage[fishItemId] && caughtForage[fishItemId] > 0) {
      return true;
    }

    // Check if the forage exists in the userInventoryRef
    return userInventoryRef.current.itemDetails.some(
      (item) => item.itemId === fishItemId
    );
  };

  const handleBackClick = () => {
    if (forageCount > 0) {
      console.log("foragecount", forageCount);
      setArrowBackDialog(true);
    } else {
      onBackToForagingTab();
    }
  };

  const handleContinueClick = () => {
    onBackToForagingTab();
  };

  const handleButtonRelease = (holdDuration) => {
    if (holdDuration >= 3000) {
      const autoForageItemExists = userInventoryRef.current.purchasedItems.some(
        (item) =>
          item.productName === `${location.locationName} Auto Forage` &&
          item.status === "purchased"
      );
      setAutoForagingEnabled(autoForageItemExists);
    } else {
      startForaging();
    }
    setButtonHoldStart(null);
  };

  // Remove the unnecessary button and effect hook for background setting
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${location.imageLocationBGUrl})`,
        backgroundSize: "cover",
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: 1,
          left: 2.5,
        }}
      >
        <IconButton onClick={handleBackClick}>
          <ArrowBack
            sx={{
              color: location.locTag === "spacial-forage" ? "white" : "white",
              height: 25,
              width: 50,
              border: `1px solid ${
                location.locTag === "spacial-forage" ? "white" : "white"
              }`,
              borderRadius: "15px",
            }}
          />
        </IconButton>
        <IconButton
          onClick={toggleProbabilityDialog}
          sx={{
            height: 25,
            width: 50,
            marginLeft: "5px",
            color: location.locTag === "spacial-forage" ? "white" : "white", // Match ArrowBack color
            border: `1px solid ${
              location.locTag === "spacial-forage" ? "white" : "white"
            }`,
            borderRadius: "15px",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor:
                location.locTag === "spacial-forage"
                  ? "rgba(255, 255, 255, 0.1)"
                  : "rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <PercentIcon sx={{ fontSize: "1.5rem" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "40px",
          left: "10px",
          maxWidth: "100px",
          maxHeight: "400px", // Adjust to fit the up/down icons
          overflow: "hidden",
        }}
      >
        {/* Up Arrow Button */}
        <IconButton
          onClick={handlePreviousPage}
          disabled={!hasPreviousPage}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <ExpandLessIcon />
        </IconButton>

        {/* Forage Cards */}
        {forageDetails.slice(startIndex, endIndex).map((forage, index) => (
          <Card
            key={index}
            sx={{
              marginBottom: "10px",
              cursor: "pointer",
              filter: isForageInInventory(forage.itemId.toString())
                ? "none"
                : "grayscale(100%)",
            }}
            onClick={() => handleForageClick(forage)}
          >
            <CardMedia
              component="img"
              image={forage.imageUrl}
              alt={`Forage ${forage.name}`}
              sx={{ height: "50px", objectFit: "contain" }}
            />
          </Card>
        ))}

        {/* Down Arrow Button */}
        <IconButton
          onClick={handleNextPage}
          disabled={!hasNextPage}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "140px",
          height: "40px",
        }}
      >
        {foragedItemImage && (
          <Box
            sx={{
              position: "absolute",
              top: "-150px",
              left: "50%",
              transform: "translate(-50%, -50%) scale(0)",
              zIndex: 9999,
              width: "200px",
              height: "200px",
              background: `url(${foragedItemImage}) no-repeat center center`,
              backgroundSize: "contain",
              animation: `
                popIn 0.5s ease-in-out,
                stay 1s linear 0.5s,
                fadeOut 0.25s ease-out 1.5s
              `,
              "@keyframes popIn": {
                "0%": {
                  transform: "translate(-50%, -50%) scale(0)",
                  opacity: 0,
                },
                "100%": {
                  transform: "translate(-50%, -50%) scale(1)",
                  opacity: 1,
                },
              },
              "@keyframes stay": {
                "0%": {
                  transform: "translate(-50%, -50%) scale(1)",
                  opacity: 1,
                },
                "100%": {
                  transform: "translate(-50%, -50%) scale(1)",
                  opacity: 1,
                },
              },
              "@keyframes fadeOut": {
                "0%": {
                  transform: "translate(-50%, -50%) scale(1)",
                  opacity: 1,
                },
                "100%": {
                  transform: "translate(-50%, -50%) scale(0.8)",
                  opacity: 0,
                },
              },
            }}
          />
        )}

        {isForaging ? (
          <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
            <Box
              sx={{
                display: "flex", // Use flexbox layout
                alignItems: "center", // Center items vertically
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={foragingProgress}
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "forestGreen",
                  },
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <Typography variant="body2" component="div">{`${Math.round(
                  foragingProgress
                )}%`}</Typography>
              </Box>
              {autoForagingEnabled && (
                <IconButton
                  onClick={() => {
                    setAutoForagingEnabled(false);
                    setIsForaging(false);
                  }}
                  sx={{
                    color: "red",
                    position: "absolute",
                    right: 25,
                  }}
                >
                  <ReplayIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        ) : (
          <>
            <Tooltip
              title={
                !autoForagingEnabled &&
                userInventoryRef.current.purchasedItems.some(
                  (item) =>
                    item.productName ===
                      `${location.locationName} Auto Forage` &&
                    item.status === "purchased"
                )
                  ? "To auto forage, hold button for 3 seconds then release."
                  : ""
              }
              placement="top"
              arrow
            >
              <Button
                variant="contained"
                // Mouse event handlers
                onMouseDown={() => setButtonHoldStart(Date.now())}
                onMouseUp={() => {
                  const holdDuration = Date.now() - buttonHoldStart;
                  handleButtonRelease(holdDuration);
                }}
                onMouseLeave={() => setButtonHoldStart(null)} // Cancel if the mouse leaves the button
                // Touch event handlers
                onTouchStart={() => setButtonHoldStart(Date.now())}
                onTouchEnd={() => {
                  const holdDuration = Date.now() - buttonHoldStart;
                  handleButtonRelease(holdDuration);
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "forestgreen",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
              >
                {autoForagingEnabled ? "AUTO FORAGE" : "FORAGE"}
              </Button>
            </Tooltip>
          </>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 20,
          right: 20,
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={handleOpenInventory}
      >
        <Badge badgeContent={forageCount} color="error">
          <ShoppingBasketIcon
            sx={{
              fontSize: 40,
              color: location.locTag === "spacial-forage" ? "white" : "white",
              borderRadius: "15px",
            }}
          />
        </Badge>
      </Box>

      <Dialog
        open={inventoryDialogOpen}
        onClose={handleCloseInventory}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>Inventory</DialogTitle>
        <DialogContent>
          {location.locTag === "spacial-forage" ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Forage</TableCell>
                  <TableCell align="right">Level</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(forageInventoryRef.current)
                  .map((forage) => ({
                    ...forage,
                    quantity: caughtForage[forage.itemId] || 0,
                  }))
                  .filter((forage) => forage.quantity > 0)
                  .sort((a, b) => b.quantity - a.quantity)
                  .map((forage, index) => (
                    <TableRow key={index}>
                      <TableCell>{forage.name}</TableCell>
                      <TableCell align="right">{forage.itemLvl}</TableCell>
                      <TableCell align="right">{forage.quantity}</TableCell>
                    </TableRow>
                  ))}
                {Object.keys(caughtForage).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No forage caught yet.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Forage | Item</TableCell>
                  <TableCell align="right">Level</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(caughtForage)
                  .filter(
                    ([itemId, quantity]) =>
                      quantity > 0 &&
                      location.fishItemIds.includes(parseInt(itemId))
                  )
                  .map(([itemId, quantity]) => {
                    const itemDetail =
                      forageInventoryRef.current[itemId] ||
                      items.find((item) => item.itemId.toString() === itemId);
                    if (!itemDetail) {
                      console.warn(`Detail not found for itemId: ${itemId}`);
                      return null;
                    }
                    return {
                      ...itemDetail,
                      quantity,
                    };
                  })
                  .filter(Boolean)
                  .sort((a, b) => b.quantity - a.quantity)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="right">
                        {item.itemLvl || "N/A"}
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                    </TableRow>
                  ))}
                {Object.keys(caughtForage).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No items caught yet.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleStoreForage}
            color="primary"
            disabled={forageCount === 0}
          >
            Store
          </Button>
          <Button
            onClick={handleSellForage}
            color="secondary"
            disabled={forageCount === 0}
          >
            Sell
          </Button>
          <Button onClick={handleCloseInventory}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={forageDialogOpen}
        onClose={() => setForageDialogOpen(false)}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{
              height: 180,
              objectFit: "cover",
              filter:
                selectedForageDetail.description === "Unforaged"
                  ? "grayscale(100%)"
                  : "none",
            }}
            image={selectedForageDetail.imgUrl}
            title={selectedForageDetail.name}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                textAlign: "center",
                fontFamily: '"Pixelify Sans", sans-serif',
              }}
            >
              {selectedForageDetail.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {selectedForageDetail.forageDescription}
            </Typography>
            &nbsp;
            <Typography variant="body2">
              {selectedForageDetail.description}
            </Typography>
            {selectedForageDetail.forageLvl !== undefined && (
              <Typography variant="body2">
                Level: {selectedForageDetail.forageLvl}
              </Typography>
            )}
            {selectedForageDetail.expPercentage && (
              <Typography variant="body2">
                EXP: {selectedForageDetail.expPercentage}%
              </Typography> // Display EXP Percentage
            )}
          </CardContent>
          {selectedForageDetail.description === "Foraged" && (
            <CardActions
              disableSpacing
              sx={{
                flexDirection: "column",
                alignItems: "center",
                "& > *": { mb: 1 },
              }}
            >
              <Button
                variant="contained"
                onClick={() => onGather(selectedForageDetail.itemId)}
                sx={{
                  backgroundColor: "forestGreen",
                  "&:hover": {
                    backgroundColor: "darkGreen",
                  },
                  color: "white",
                  marginBottom: 1,
                }}
                disabled={selectedForageDetail.itemType === "seeds"}
              >
                FORAGE
              </Button>

              {/* Toggle Button for Auto Gather */}
              <Button
                variant="contained"
                disabled={
                  !isAutoGatherPurchased(
                    selectedForageDetail.itemId,
                    purchasedItems
                  )
                }
                onClick={() => {
                  toggleAutoGather(selectedForageDetail.itemId);
                  onAutoGather(selectedForageDetail.itemId);
                }}
                sx={{
                  backgroundColor: autoGatherStates[selectedForageDetail.itemId]
                    ? "black"
                    : "#4caf50",
                  "&:hover": {
                    backgroundColor: autoGatherStates[
                      selectedForageDetail.itemId
                    ]
                      ? "black"
                      : "#388e3c",
                  },
                  color: "white",
                  marginBottom: 1,
                }}
                className={
                  autoGatherStates[selectedForageDetail.itemId]
                    ? "animate-border"
                    : ""
                }
              >
                {autoGatherStates[selectedForageDetail.itemId]
                  ? "AUTO FORAGING"
                  : "AUTO FORAGE"}
              </Button>
            </CardActions>
          )}
        </Card>
      </Dialog>

      <Dialog
        open={probabilityDialogOpen}
        onClose={toggleProbabilityDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Forage Probabilities</DialogTitle>
        <DialogContent>
          {adjustedProbabilitiesState.adjustedProbabilities.map(
            ({ forageDetail, adjustedProbability }, index) => {
              // Check if the forage name is in the user's inventory
              const isForageInInventory =
                userInventoryRef.current.itemDetails.some(
                  (item) => item.itemName === forageDetail.name
                );

              const displayProbability =
                adjustedProbability < 0.01
                  ? "??.??%"
                  : `${adjustedProbability.toFixed(2)}%`;

              return (
                <DialogContentText key={index} style={{ marginBottom: "10px" }}>
                  {isForageInInventory ? forageDetail.name : "???"}:{" "}
                  {displayProbability}
                </DialogContentText>
              );
            }
          )}

          <hr style={{ margin: "20px 0" }} />
          <DialogContentText style={{ marginTop: "10px" }}>
            Fail Probability:{" "}
            {(adjustedProbabilitiesState.failPercentage * 100).toFixed(2)}%
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleProbabilityDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={arrowBackDialog}
        onClose={() => setArrowBackDialog(false)}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <CardContent sx={{ maxWidth: "350px" }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              textAlign: "center",
              fontFamily: '"Pixelify Sans", sans-serif',
            }}
          >
            Forage Basket
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <br />
            You already found{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {forageCount.toLocaleString()}
            </span>{" "}
            forage!
          </Typography>
          <br />
          <Typography>
            Are you sure you want to exit? Exiting will empty your basket.
          </Typography>
        </CardContent>
        <DialogActions>
          <Button onClick={() => setArrowBackDialog(false)}>Cancel</Button>
          <Button color="error" onClick={() => handleContinueClick()}>
            Exit anyway
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={bronzeCoinsDialogOpen}
        onClose={() => setBronzeCoinsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Forage Sold"}</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography variant="h10" component="div" gutterBottom>
              {"You have earned"}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Typography
                variant="h7"
                component="span"
                style={{ fontWeight: "bold" }}
              >
                {bronzeCoinsEarned.toLocaleString()}
              </Typography>
              <img
                src="/assets/items/currencies/forage-coin1.webp"
                alt="Bronze Coin"
                style={{ width: 24, height: 24 }}
              />
            </Box>
            <Typography variant="h10" component="div">
              {"from selling your forage"}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBronzeCoinsDialogOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={storageSuccessDialogOpen}
        onClose={() => setStorageSuccessDialogOpen(false)}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>{"Forage Stored"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Forages are successfully stored to your inventory.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStorageSuccessDialogOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={1000} // Adjusted to 1 second
        onClose={() => setNotification({ ...notification, open: false })}
        message={notification.message}
      />
    </Box>
  );
};

export default ForagingActivity;
