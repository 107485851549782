import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

const AddRemoveShopDialog = ({ open, onClose, shop, onConfirm, isAdd }) => {
  const handleConfirm = async () => {
    const success = await onConfirm(shop.itemId);
    if (success) {
      onClose(); // Close the dialog if the operation was successful
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isAdd ? `Add Shop Item` : `Remove Shop Item`}</DialogTitle>
      <DialogContent>
        {isAdd ? (
          <Typography>
            Are you sure you want to add the shop item:{" "}
            <strong>{shop.productName}</strong>?
            <br />
            <em>Value: {shop.value.toLocaleString()} coins</em>
          </Typography>
        ) : (
          <Box>
            <Typography>
              Are you sure you want to remove the shop item:{" "}
              <strong>{shop.productName}</strong>?
            </Typography>
            <Typography>Status: {shop.status}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{
            color: isAdd ? "green" : "red",
          }}
        >
          {isAdd ? "Add Item" : "Remove Item"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRemoveShopDialog;
