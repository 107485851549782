import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddRemoveShopDialog from "./AddRemoveShopDialog";

const PurchasedShops = ({ shops, onShopRemove }) => {
  const [selectedShop, setSelectedShop] = useState(null);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const handleRemoveClick = (shop) => {
    setSelectedShop(shop);
    setIsRemoveDialogOpen(true);
  };

  return (
    <Box>
      {shops.length > 0 ? (
        shops.map((shop) => (
          <Card key={shop.itemId} sx={{ mb: 2, display: "flex" }}>
            <CardContent
              sx={{ display: "flex", alignItems: "center", flex: 1 }}
            >
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  mr: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={shop.itemImage}
                  alt={shop.productName}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: '"Pixelify Sans", sans-serif',
                    fontWeight: "bold",
                  }}
                >
                  {shop.productName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Value: {shop.value.toLocaleString()} coins
                </Typography>
              </Box>
            </CardContent>
            <Box
              sx={{ display: "flex", alignItems: "center", paddingRight: 2 }}
            >
              <Stack direction="column" spacing={1}>
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  sx={{
                    backgroundColor: "#f5a6a6",
                    color: "black",
                    fontSize: "0.75rem",
                    padding: "4px 12px",
                    "&:hover": { backgroundColor: "#e08a8a" },
                  }}
                  onClick={() => handleRemoveClick(shop)}
                >
                  Remove
                </Button>
              </Stack>
            </Box>
          </Card>
        ))
      ) : (
        <Typography variant="body2">
          No purchased items from shops available.
        </Typography>
      )}

      {selectedShop && (
        <AddRemoveShopDialog
          open={isRemoveDialogOpen}
          onClose={() => setIsRemoveDialogOpen(false)}
          shop={selectedShop}
          onConfirm={async () => {
            const success = await onShopRemove(selectedShop.itemId);
            if (success) {
              setIsRemoveDialogOpen(false);
            }
          }}
          isAdd={false}
        />
      )}
    </Box>
  );
};

export default PurchasedShops;
