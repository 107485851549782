import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import SecureLS from "secure-ls";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PurchasedShops from "./PurchasedShops";
import UnpurchasedShops from "./UnpurchasedShops";

const UserShops = () => {
  const { userId } = useParams();
  const location = useLocation();
  const userName = location.state?.userName || "User";
  const [purchasedShops, setPurchasedShops] = useState([]);
  const [unpurchasedShops, setUnpurchasedShops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchShops = async () => {
      setIsLoading(true);
      try {
        const ls = new SecureLS({ isCompression: false });
        const adminDetails = ls.get("adminDetails");
        const token = adminDetails ? adminDetails.token : null;

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/shops/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const { shopInventory } = await response.json();
          const purchased = shopInventory.filter(
            (shop) => shop.status === "purchased"
          );
          const unpurchased = shopInventory.filter(
            (shop) => shop.status === "not_purchased"
          );
          setPurchasedShops(purchased);
          setUnpurchasedShops(unpurchased);
        } else {
          console.error("Failed to fetch shops.");
        }
      } catch (error) {
        console.error("Error fetching shops:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchShops();
  }, [userId]);

  const handleShopRemove = async (shopId) => {
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/shops/${userId}/${shopId}/remove`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adminId: adminId,
            adminName: adminName,
          }),
        }
      );

      if (response.ok) {
        setPurchasedShops((prevShops) =>
          prevShops.filter((shop) => shop.itemId !== shopId)
        );
        setUnpurchasedShops((prevShops) => [
          ...prevShops,
          purchasedShops.find((shop) => shop.itemId === shopId),
        ]);
        return true;
      } else {
        console.error("Failed to remove shop item.");
        return false;
      }
    } catch (error) {
      console.error("Error removing shop item:", error);
      return false;
    }
  };

  const handleShopAdd = async (shopId) => {
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/shops/${userId}/${shopId}/add`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adminId: adminId,
            adminName: adminName,
          }),
        }
      );

      if (response.ok) {
        setUnpurchasedShops((prevShops) =>
          prevShops.filter((shop) => shop.itemId !== shopId)
        );
        setPurchasedShops((prevShops) => [
          ...prevShops,
          unpurchasedShops.find((shop) => shop.itemId === shopId),
        ]);
        return true;
      } else {
        console.error("Failed to add shop item.");
        return false;
      }
    } catch (error) {
      console.error("Error adding shop item:", error);
      return false;
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: '"Pixelify Sans", sans-serif',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <IconButton
          onClick={() => window.history.back()}
          sx={{ marginRight: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: '"Pixelify Sans", sans-serif',
            textAlign: "center",
            flex: 1,
          }}
        >
          {userName}'s Shop Items
        </Typography>
      </Box>

      <Typography variant="h6" gutterBottom>
        Purchased Shop Items
      </Typography>
      <PurchasedShops shops={purchasedShops} onShopRemove={handleShopRemove} />

      <Divider sx={{ marginY: 4 }} />

      <Typography variant="h6" gutterBottom>
        Unpurchased Shop Items
      </Typography>
      <UnpurchasedShops shops={unpurchasedShops} onShopAdd={handleShopAdd} />
    </Box>
  );
};

export default UserShops;
