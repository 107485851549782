import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import { getExpNeededForNextLevel } from "../../../../System/Levels/locationLevels";

const FishLocationCard = ({
  location,
  locations,
  lockedLocations,
  onFish,
  items,
  userInventoryRef,
  userProfileRef,
  fishInventoryRef,
  isLocked = false, // Add isLocked prop
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fishingSkill = userProfileRef.current.skills.find(
    (skill) => skill.skillId === 4
  );

  const fishLocation = userProfileRef.current.locations.find(
    (loc) => loc.locationId === location.locationId
  );

  const expPercentage = fishLocation
    ? (fishLocation.exp /
        getExpNeededForNextLevel(fishLocation.level, location.locationId)) *
      100
    : 0;

  // Determine the unlock requirement for locked locations
  const getUnlockRequirement = () => {
    if (isLocked && location.itemPreq && location.itemPreq.length > 0) {
      // Check for the first location where the required fish item can be caught
      const prerequisiteLocations = location.itemPreq.map((prereq) => {
        let sourceLocation = location.fishItemIds.includes(prereq.itemId)
          ? location
          : lockedLocations.find((loc) =>
              loc.fishItemIds.includes(prereq.itemId)
            );

        // If not found in locked locations, check unlocked locations
        if (!sourceLocation) {
          sourceLocation = locations.find((loc) =>
            loc.fishItemIds.includes(prereq.itemId)
          );
        }

        return sourceLocation
          ? sourceLocation.locationName
          : "Unknown Location";
      });

      // Remove duplicate location names
      const uniqueNamedLocations = [
        ...new Set(
          prerequisiteLocations.filter(
            (locName) => locName !== "Unknown Location"
          )
        ),
      ];

      const locationToUse =
        uniqueNamedLocations.length > 1
          ? "Many Locations"
          : uniqueNamedLocations[0] || "Unknown Location";

      const caughtFishCount = 0;
      const totalFishNeeded = location.itemPreq.length;

      return `Catch ${caughtFishCount}/${totalFishNeeded} fish from ${locationToUse} to unlock.`;
    }
    return null;
  };

  const unlockRequirementText = getUnlockRequirement();

  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: 360,
        margin: "auto",
        mb: 2,
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        position: "relative",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: 175,
          height: 175,
          objectFit: "cover",
          display: "block",
          margin: "auto",
          marginLeft: "10px",
          filter: isLocked ? "grayscale(100%) blur(3px)" : "none", // Apply filter if locked
        }}
        image={location.imageUrl || "/assets/locations/default.webp"}
        alt={location.locationName}
        onClick={!isLocked ? handleClickOpen : undefined} // Disable click if locked
      />
      <CardContent
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            mb: 1,
            textAlign: "center",
            fontFamily: '"Pixelify Sans", sans-serif',
          }}
        >
          {isLocked ? "Location yet to be Found" : location.locationName}{" "}
          {/* Show ??? if locked */}
        </Typography>
        <Typography sx={{ mb: 1, textAlign: "center" }}>
          Level: {fishLocation ? fishLocation.level : "N/A"} Exp:{" "}
          {expPercentage.toFixed(2)}%
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onFish(location, items)}
          sx={{ mt: 2, alignSelf: "center" }}
          disabled={isLocked} // Disable button if locked
        >
          Fish Here
        </Button>
      </CardContent>

      {isLocked && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2,
            pointerEvents: "none",
          }}
        >
          <Typography
            variant="h4"
            color="white"
            sx={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            }}
          >
            LOCKED
          </Typography>
          <Typography
            variant="caption"
            color="white"
            sx={{
              mt: 1,
              textAlign: "center",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
            }}
          >
            {unlockRequirementText}
          </Typography>
        </Box>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}>
          {location.locationName}
        </DialogTitle>
        <DialogContent>
          <Typography>{location.locationDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default FishLocationCard;
