import React from "react";
import { Box, TextField, Button } from "@mui/material";

const ItemAnimalOptions = ({ data, onChange }) => {
  const handleAddAffectionProduce = () => {
    const newProduce = {
      affectionLvl: 0,
      produces: [{ produceId: 0, probability: 0 }],
    };
    const updatedAffectionProduce = [
      ...(data.affectionProduce || []),
      newProduce,
    ];
    onChange("affectionProduce", updatedAffectionProduce);
  };

  const handleRemoveAffectionProduce = (index) => {
    const updatedAffectionProduce = [...data.affectionProduce];
    updatedAffectionProduce.splice(index, 1);
    onChange("affectionProduce", updatedAffectionProduce);
  };

  const handleAffectionProduceChange = (index, key, value) => {
    const updatedAffectionProduce = [...data.affectionProduce];
    updatedAffectionProduce[index][key] = value;
    onChange("affectionProduce", updatedAffectionProduce);
  };

  const handleProduceDetailsChange = (
    produceIndex,
    produceDetailIndex,
    key,
    value
  ) => {
    const updatedAffectionProduce = [...data.affectionProduce];
    const produces = updatedAffectionProduce[produceIndex].produces || [];
    produces[produceDetailIndex][key] = value;
    updatedAffectionProduce[produceIndex].produces = produces;
    onChange("affectionProduce", updatedAffectionProduce);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        label="Affection Level"
        type="number"
        value={data.affectionLvl || ""}
        onChange={(e) => onChange("affectionLvl", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Life Stage"
        value={data.lifeStage || ""}
        onChange={(e) => onChange("lifeStage", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Feed Type"
        type="number"
        value={data.feedType || ""}
        onChange={(e) => onChange("feedType", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Feed Amount"
        type="number"
        value={data.feedAmount || ""}
        onChange={(e) => onChange("feedAmount", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Time to Adult (in days)"
        type="number"
        value={data.timeToAdult || ""}
        onChange={(e) => onChange("timeToAdult", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Adult Animal ID"
        type="number"
        value={data.adultAnimalId || ""}
        onChange={(e) => onChange("adultAnimalId", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Butcher IDs (comma-separated)"
        value={data.butcherIds?.join(", ") || ""}
        onChange={(e) =>
          onChange(
            "butcherIds",
            e.target.value.split(",").map((id) => parseInt(id.trim(), 10))
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Animal Type"
        value={data.animalType || ""}
        onChange={(e) => onChange("animalType", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Box sx={{ mt: 2, mb: 2 }}>
        <h4>Affection Produce</h4>
        {data.affectionProduce?.map((produce, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <TextField
              label="Affection Level"
              type="number"
              value={produce.affectionLvl || ""}
              onChange={(e) =>
                handleAffectionProduceChange(
                  index,
                  "affectionLvl",
                  e.target.value
                )
              }
              fullWidth
              sx={{ mb: 1 }}
            />
            {produce.produces?.map((detail, detailIndex) => (
              <Box key={detailIndex} sx={{ ml: 2, mb: 2 }}>
                <TextField
                  label="Produce ID"
                  type="number"
                  value={detail.produceId || ""}
                  onChange={(e) =>
                    handleProduceDetailsChange(
                      index,
                      detailIndex,
                      "produceId",
                      e.target.value
                    )
                  }
                  fullWidth
                  sx={{ mb: 1 }}
                />
                <TextField
                  label="Probability"
                  type="number"
                  inputProps={{ step: "0.01", min: "0", max: "1" }}
                  value={detail.probability || ""}
                  onChange={(e) =>
                    handleProduceDetailsChange(
                      index,
                      detailIndex,
                      "probability",
                      e.target.value
                    )
                  }
                  fullWidth
                  sx={{ mb: 1 }}
                />
              </Box>
            ))}
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveAffectionProduce(index)}
              sx={{ mt: 1 }}
            >
              Remove Affection Produce
            </Button>
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddAffectionProduce}
          sx={{ mt: 2 }}
        >
          Add Affection Produce
        </Button>
      </Box>
    </Box>
  );
};

export default ItemAnimalOptions;
