import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

function BatchProcessedDialog({
  batchProcessedDialogOpen,
  setBatchProcessedDialogOpen,
  items,
  userProfileRef,
  localGatherBatch,
  setLocalGatherBatch,
  setGatherBatch,
  userInventoryRef,
}) {
  const [isSelling, setIsSelling] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [gatherCoinsEarned, setGatherCoinsEarned] = useState(null);

  useEffect(() => {
    if (!batchProcessedDialogOpen) {
      resetDialogState();
    }
  }, [batchProcessedDialogOpen]);

  const resetDialogState = () => {
    setIsSelling(false);
    setGatherCoinsEarned(null);
    setLocalGatherBatch({});
    setGatherBatch({});
  };

  const findItemDetails = (itemId) =>
    items.find((item) => String(item.itemId) === String(itemId));

  const handleSellItems = async () => {
    console.log("Sell button clicked"); // Log when the function is invoked
    setIsSelling(true);

    const userId = userProfileRef.current.id;
    console.log("User ID:", userId); // Log the user ID

    try {
      let response;
      if (userProfileRef.current.afkDetails.isAfk) {
        // If AFK, sell items based on AFK details
        const afkItems = userProfileRef.current.afkDetails.gatherAfkItems;
        const afkStartTime = new Date(
          userProfileRef.current.afkDetails.gatherAfkStart
        );
        const currentTime = new Date();
        const timePassedInSeconds = Math.floor(
          (currentTime - afkStartTime) / 1000
        );
        const totalQuantity = Math.floor(timePassedInSeconds / 5);

        // Create an array of items with each item and its amount
        const itemsToSell = afkItems.reduce((acc, itemId) => {
          acc[itemId] = totalQuantity;
          return acc;
        }, {});

        console.log("AFK Items to sell:", itemsToSell); // Log AFK items being sold

        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/afkBatchSellGather`,
          {
            items: itemsToSell,
            afk: true, // Marking that this is an AFK sale
          }
        );
      } else {
        // If not AFK, sell items from localGatherBatch
        if (Object.keys(localGatherBatch).length === 0) {
          console.error("No items to sell.");
          setIsSelling(false);
          return;
        }

        console.log("Items to sell:", localGatherBatch); // Log the items being sold

        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/afkBatchSellGather`,
          {
            items: localGatherBatch,
            afk: false,
          }
        );
      }

      console.log("Sell response:", response.data); // Log the response from the server

      setGatherCoinsEarned(response.data.gatherCoins);

      // Find the Gather Coin in the inventory and add the earned coins to its quantity
      const gatherCoinItem = userInventoryRef.current.itemDetails.find(
        (item) => item.itemId === "85" // Assuming "85" is the itemId for Gather Coin
      );

      if (gatherCoinItem) {
        gatherCoinItem.quantity += response.data.gatherCoins;
      } else {
        // If Gather Coin is not in the inventory, add it
        userInventoryRef.current.itemDetails.push({
          itemId: "85",
          itemName: "Gather Coin",
          itemLevel: 1,
          itemType: "currency",
          imageUrl: "/assets/items/currencies/gather-coin.webp",
          quantity: response.data.gatherCoins,
          itemExp: 0,
          itemValue: 1.25,
          expNeeded: 100,
        });
      }
    } catch (error) {
      console.error("Error selling items:", error);
    } finally {
      setIsSelling(false);
      userProfileRef.current.afkDetails.isAfk = false;
    }
  };

  const handleStoreItems = async () => {
    setIsStoring(true);
    const userId = userProfileRef.current.id;

    try {
      const afkItems = userProfileRef.current.afkDetails.gatherAfkItems;

      // Calculate the total quantity based on the number of AFK items and time passed
      const afkStartTime = new Date(
        userProfileRef.current.afkDetails.gatherAfkStart
      );
      const currentTime = new Date();
      const timePassedInSeconds = Math.floor(
        (currentTime - afkStartTime) / 1000
      );
      const totalQuantity = Math.floor(timePassedInSeconds / 5);

      // Create an array of items with each item and its amount
      const itemIds = afkItems.map((itemId) => [itemId, totalQuantity]);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/afkGather`,
        {
          itemIds: itemIds,
          skillExpGain: 0,
        }
      );

      const updatedItems = response.data.updatedItems;
      const updatedItemDetails = userInventoryRef.current.itemDetails.map(
        (item) => {
          const updatedItem = updatedItems.find((updatedItem) => {
            const updatedItemIdString = String(updatedItem.itemId);
            const itemItemIdString = String(item.itemId);
            return updatedItemIdString === itemItemIdString;
          });
          if (updatedItem) {
            const newAmount = item.quantity + updatedItem.quantity;
            return { ...item, quantity: newAmount };
          } else {
            return item;
          }
        }
      );

      // Update the itemDetails in userInventoryRef
      userInventoryRef.current.itemDetails = updatedItemDetails;
    } catch (error) {
      console.error("Error storing items:", error);
    } finally {
      setIsStoring(false);
      setBatchProcessedDialogOpen(false);
      userProfileRef.current.afkDetails.isAfk = false;
      setLocalGatherBatch({});
      setGatherBatch({});
    }
  };

  if (gatherCoinsEarned !== null) {
    return (
      <Dialog
        open={batchProcessedDialogOpen}
        onClose={() => {
          setBatchProcessedDialogOpen(false);
          userProfileRef.current.afkDetails.isAfk = false;
          setLocalGatherBatch({});
          setGatherBatch({});
        }}
        aria-labelledby="gather-coin-dialog-title"
        aria-describedby="gather-coin-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="gather-coin-dialog-title">
          {"Gather Coins Earned"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="gather-coin-dialog-description">
            You have earned {gatherCoinsEarned.toLocaleString()}{" "}
            <img
              src="/assets/items/currencies/gather-coin.webp"
              alt="Gather Coin"
              style={{ height: "20px", verticalAlign: "middle" }}
            />{" "}
            Gather Coins from your gathering venture.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setBatchProcessedDialogOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (isSelling) {
    return (
      <Dialog
        open={batchProcessedDialogOpen}
        onClose={() => setBatchProcessedDialogOpen(false)}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="loading-dialog-title">{"Processing Sale"}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <CircularProgress />
          <DialogContentText id="loading-dialog-description">
            Selling gathered items...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  if (isStoring) {
    return (
      <Dialog
        open={batchProcessedDialogOpen}
        onClose={() => setBatchProcessedDialogOpen(false)}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="loading-dialog-title">
          {"Processing Store"}
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <CircularProgress />
          <DialogContentText id="loading-dialog-description">
            Storing gathered items...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  if (userProfileRef.current.afkDetails.isAfk) {
    const afkDetails = userProfileRef.current.afkDetails;
    const afkItems = afkDetails.gatherAfkItems;

    const afkStartTime = new Date(
      userProfileRef.current.afkDetails.gatherAfkStart
    );
    const currentTime = new Date();
    const timePassedInSeconds = Math.floor((currentTime - afkStartTime) / 1000);

    const totalQuantity = Math.floor(timePassedInSeconds / 5);

    return (
      <Dialog
        open={batchProcessedDialogOpen}
        onClose={() => {}}
        aria-labelledby="batch-process-dialog-title"
        aria-describedby="batch-process-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="batch-process-dialog-title">
          {"AFK Batch Processed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="batch-process-dialog-description">
            Here is the Summary of your AFK Gathering Venture:
          </DialogContentText>
          <List style={{ margin: "auto", textAlign: "center" }}>
            {afkItems.map((item) => {
              const itemDetails = findItemDetails(item);

              return (
                <ListItem key={item.itemId}>
                  <ListItemAvatar>
                    <Avatar
                      src={itemDetails?.imageUrl || "/path/to/default/image"}
                      alt={itemDetails?.name || "Item"}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={itemDetails?.name || "Unknown Item"}
                    secondary={`Quantity: ${totalQuantity.toLocaleString()}`}
                  />
                </ListItem>
              );
            })}

            <Divider />

            <ListItem>
              <ListItemText
                primary={`Total Gathered Items: ${(
                  totalQuantity * afkItems.length
                ).toLocaleString()}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Time Passed: ${Math.floor(
                  timePassedInSeconds / 86400
                )} days, ${Math.floor(
                  (timePassedInSeconds % 86400) / 3600
                )} hours, ${Math.floor(
                  (timePassedInSeconds % 3600) / 60
                )} minutes`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStoreItems} color="primary">
            Store Items
          </Button>
          <Button onClick={handleSellItems} color="primary">
            Sell Items
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    let totalQuantity = 0;
    Object.values(localGatherBatch).forEach((quantity) => {
      totalQuantity += quantity;
    });

    const formattedTotalQuantity = totalQuantity.toLocaleString();

    return (
      <Dialog
        open={batchProcessedDialogOpen}
        onClose={() => {
          setBatchProcessedDialogOpen(false);
          setLocalGatherBatch({});
          setGatherBatch({});
        }}
        aria-labelledby="batch-process-dialog-title"
        aria-describedby="batch-process-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="batch-process-dialog-title">
          {"Batch Processed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="batch-process-dialog-description">
            Here is the Summary of your Gathering Venture:
          </DialogContentText>
          <List style={{ margin: "auto", textAlign: "center" }}>
            {Object.entries(localGatherBatch).map(([itemId, quantity]) => {
              const item = findItemDetails(itemId);
              return (
                <ListItem key={itemId}>
                  <ListItemAvatar>
                    <Avatar
                      src={item?.imageUrl || "/path/to/default/image"}
                      alt={item?.name || "Item"}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item?.name || "Unknown Item"}
                    secondary={`Quantity: ${quantity}`}
                  />
                </ListItem>
              );
            })}

            <Divider />

            <ListItem>
              <ListItemText
                primary={`Total Gathered Items: ${formattedTotalQuantity}`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSellItems} color="primary">
            Sell Items
          </Button>
          <Button
            onClick={() => setBatchProcessedDialogOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default BatchProcessedDialog;
