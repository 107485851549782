import React, { useState } from "react";
import { Box, TextField, Button, Typography, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

const ItemPreqOptions = ({ data, onChange }) => {
  const [newPreq, setNewPreq] = useState({
    itemId: "",
    requiredLevel: "",
    preqSkillId: "",
    preqSkillLevel: "",
  });

  const handleNewPreqChange = (field, value) => {
    setNewPreq((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddPreq = () => {
    // Clear form fields and add the new prerequisite to the data list
    if (
      newPreq.itemId ||
      newPreq.requiredLevel ||
      newPreq.preqSkillId ||
      newPreq.preqSkillLevel
    ) {
      onChange([...data, newPreq]);
      setNewPreq({
        itemId: "",
        requiredLevel: "",
        preqSkillId: "",
        preqSkillLevel: "",
      });
    }
  };

  const handleRemovePreq = (index) => {
    const updatedData = data.filter((_, i) => i !== index);
    onChange(updatedData);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* Form for adding a new prerequisite */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Add New Prerequisite
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          mb: 2,
        }}
      >
        <TextField
          label="Item ID"
          type="number"
          value={newPreq.itemId}
          onChange={(e) => handleNewPreqChange("itemId", e.target.value)}
        />
        <TextField
          label="Required Level"
          type="number"
          value={newPreq.requiredLevel}
          onChange={(e) => handleNewPreqChange("requiredLevel", e.target.value)}
        />
        <TextField
          label="Skill ID"
          type="number"
          value={newPreq.preqSkillId}
          onChange={(e) => handleNewPreqChange("preqSkillId", e.target.value)}
        />
        <TextField
          label="Skill Level"
          type="number"
          value={newPreq.preqSkillLevel}
          onChange={(e) =>
            handleNewPreqChange("preqSkillLevel", e.target.value)
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddPreq}
          startIcon={<Add />}
        >
          Add Prerequisite
        </Button>
      </Box>

      {/* Display list of added prerequisites */}
      <Typography variant="h6" sx={{ mt: 2 }}>
        Added Prerequisites
      </Typography>
      {data.map((preq, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            alignItems: "center",
            border: "1px solid #ddd",
            borderRadius: "8px",
            p: 2,
            mt: 2,
          }}
        >
          <Typography>
            <strong>Item ID:</strong> {preq.itemId || "N/A"}
          </Typography>
          <Typography>
            <strong>Required Level:</strong> {preq.requiredLevel || "N/A"}
          </Typography>
          <Typography>
            <strong>Skill ID:</strong> {preq.preqSkillId || "N/A"}
          </Typography>
          <Typography>
            <strong>Skill Level:</strong> {preq.preqSkillLevel || "N/A"}
          </Typography>
          <IconButton color="error" onClick={() => handleRemovePreq(index)}>
            <Delete />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default ItemPreqOptions;
