import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
  useRef,
} from "react";
import axios from "axios";
import SecureLS from "secure-ls";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";

// Modules Import
import TabPanel from "../../modules/Home/Tabs/TabPanel";
import AddItemForm from "../../modules/Home/Tabs/AddItemTab";
import NotificationsDisplay from "../../modules/System/Components/Notification/NotificationsDisplay";
import { itemLevels } from "../../modules/System/Levels/itemLevels";
import HomeLayout from "./HomeLayout";

// Dialogs Import
import BatchProcessedDialog from "../../modules/Home/Dialogs/BatchProcessDialog";

// Tabs Import
import InventoryTab from "../../modules/Home/Tabs/InventoryTab/InventoryTab";
import ShopTab from "../../modules/Home/Tabs/ShopTab/ShopTab";
import MarketTab from "../../modules/Home/Tabs/MarketTab/MarketTab";
import ProfileTab from "../../modules/Home/Tabs/ProfileTab";
import LeaderboardTab from "../../modules/Home/Tabs/LeaderboardTab";
import SkillsTab from "../../modules/Home/Tabs/SkillsTab/SkillsTab";

// Context and Service Imports
import { useExp } from "../../modules/Home/ExpContext";
import { useNotifications } from "../../modules/System/Components/Notification/NotificationManager";
import { fetchUserInventory } from "../../services/inventoryService";
import { fetchUserProfile } from "../../services/userService";
import { getExpForNextLevel } from "../../modules/System/Levels/userlevels";
import { getExpForNextItemLevel } from "../../modules/System/Levels/itemLevels";
import {
  handleGather,
  handleAutoGather,
  processGatherBatch,
} from "../../modules/Home/Functions/gatherFunctions";
import { fetchItems } from "../../modules/Home/Functions/fetchItems";
import { handleProcess } from "../../modules/Home/Functions/processFunctions";
import { handleCraft } from "../../modules/Home/Functions/craftFunctions";
import { useNotificationsContext } from "../../modules/System/Components/Notification/NotificationContext";
import { useGatherCount } from "../../modules/System/Components/Gather/GatherCountContext";

// CSS
import "../../css/home.css";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Home() {
  const navigate = useNavigate();
  const { expPercentages, calculateAndUpdateExpPercentages } = useExp();
  const { addNotification } = useNotifications();
  const theme = useTheme();

  const [expNeededData, setExpNeededData] = useState({});
  const [gatherBatch, setGatherBatch] = useState([]);
  const [localGatherBatch, setLocalGatherBatch] = useState({});
  const [batchProcessedDialogOpen, setBatchProcessedDialogOpen] =
    useState(false);
  const [inventory, setInventory] = useState([]);
  const [localInventory, setLocalInventory] = useState({});
  const [isInventoryFetched, setIsInventoryFetched] = useState(false);
  const [userInventory, setUserInventory] = useState(null);
  const [purchasedItems] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gatheredItems, setGatheredItems] = useState({});
  const [resetGatheredItems, setResetGatheredItems] = useState(() => () => {});
  const [autoGathering, setAutoGathering] = useState({});
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [isAutoGather, setIsAutoGather] = useState(false);
  const [skills, setSkills] = useState([]);
  const [accumulatedSkillExp, setAccumulatedSkillExp] = useState(0);
  const [processBatch, setProcessBatch] = useState({});
  const [craftBatch, setCraftBatch] = useState({});
  const [loadingUserProfile, setLoadingUserProfile] = useState(true);
  const [loadingUserInventory, setLoadingUserInventory] = useState(false);
  const [fishingLocations, setFishingLocations] = useState([]);
  const [lockedFishingLocations, setLockedFishingLocations] = useState([]);
  const [foragingLocations, setForagingLocations] = useState([]);
  const [lockedForagingLocations, setLockedForagingLocations] = useState([]);
  const [loadingFishingLocations, setLoadingFishingLocations] = useState(false);
  const [loadingForagingLocations, setLoadingForagingLocations] =
    useState(false);
  const [isAfk, setIsAfk] = useState(false);

  const isBatchProcessing = useRef(false);
  const isRequestInProgress = useRef(false);
  const localGatherCountRef = useGatherCount();
  const expNeededFetched = useRef({});
  const inventoryItemRef = useRef();
  const userInventoryRef = useRef(null);
  const userProfileRef = useRef(null);
  const fishingLocationsRef = useRef([]);
  const foragingLocationsRef = useRef([]);
  const fishInventoryRef = useRef({});
  const forageInventoryRef = useRef({});
  const ls = new SecureLS({ isCompression: false });

  const { fetchNotifications } = useNotificationsContext();

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userId = userDetails ? userDetails.userId : null;
    if (userId) {
      fetchNotifications(userId);
    }
  }, []);

  useEffect(() => {
    const savedFishInventory = ls.get("fishInventory");
    fishInventoryRef.current = savedFishInventory || {};
  }, []);

  // Grouped states
  const [userState, setUserState] = useState({
    userProfile: null,
    userRole: "",
  });

  const [uiState, setUiState] = useState({
    isLoading: false,
    tabValue: 0,
    inventoryTabValue: 0,
    backgroundImageSize: 0,
  });

  const [dataState, setDataState] = useState({
    items: [],
    notifications: [],
  });

  // Destructuring for easier access
  const { userProfile, userRole } = userState;
  const { isLoading, tabValue, inventoryTabValue } = uiState;
  const { items, notifications } = dataState;

  const [backgroundImageSize, setBackgroundImageSize] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Loading States
  const [shouldFetchInventory, setShouldFetchInventory] = useState(false);

  const tabTitles = [
    "Skills",
    "Inventory",
    "Shop",
    "Market",
    "Profile",
    "Leaderboard",
    "Admin",
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleGatherHook = handleGather(
    accumulatedSkillExp,
    setAccumulatedSkillExp,
    userProfile,
    userProfileRef,
    setUserState,
    fetchUserProfile,
    items,
    expNeededData,
    inventory,
    setInventory,
    localInventory,
    setLocalInventory,
    setGatherBatch,
    calculateAndUpdateExpPercentages,
    addNotification,
    isInventoryFetched,
    setIsInventoryFetched,
    userInventoryRef,
    inventoryItemRef,
    isRequestInProgress,
    itemLevels,
    setDataState,
    getExpForNextLevel,
    gatheredItems,
    setGatheredItems
  );

  // Create the handleAutoGatherHook
  const handleAutoGatherHook = handleAutoGather(
    accumulatedSkillExp,
    setAccumulatedSkillExp,
    autoGathering,
    setAutoGathering,
    processGatherBatch,
    localGatherCountRef,
    handleGather,
    setGatherBatch,
    items,
    addNotification,
    userProfile,
    userProfileRef,
    setUserState,
    getExpForNextLevel,
    itemLevels,
    inventoryItemRef,
    userInventoryRef
  );

  const processGatherBatchHook = processGatherBatch(
    accumulatedSkillExp,
    setAccumulatedSkillExp,
    gatherBatch,
    resetGatheredItems,
    setInventory,
    setIsInventoryFetched,
    setLocalInventory,
    setBatchProcessedDialogOpen,
    fetchUserInventory,
    gatheredItems,
    isBatchProcessing,
    setGatherBatch,
    setShouldFetchInventory,
    userInventoryRef,
    setUserInventory,
    setGatheredItems
  );

  const fetchItemsHook = fetchItems(
    setUiState,
    setUserInventory,
    setDataState,
    isInventoryFetched,
    userInventoryRef,
    fetchUserInventory,
    setIsInventoryFetched
  );

  const handleProcessHook = handleProcess(
    userProfileRef.current,
    setUserState,
    fetchUserProfile,
    items,
    userInventoryRef,
    setProcessBatch,
    addNotification,
    accumulatedSkillExp,
    setAccumulatedSkillExp,
    itemLevels
  );

  const handleCraftHook = handleCraft(
    userProfileRef.current,
    setUserState,
    fetchUserProfile,
    items,
    userInventoryRef,
    setCraftBatch,
    addNotification,
    accumulatedSkillExp,
    setAccumulatedSkillExp,
    itemLevels
  );

  const handleSidebarTabChange = useCallback((index) => {
    setUiState((prevState) => ({
      ...prevState,
      tabValue: index,
      inventoryTabValue: index === 1 ? 0 : prevState.inventoryTabValue,
    }));
    setDrawerOpen(false);
  }, []);

  const optimizedImageSrc = useMemo(() => {
    return windowWidth < 768
      ? "/assets/home_banner.webp"
      : "/assets/home_banner.webp";
  }, [windowWidth]);

  // Handle window resize event
  const handleWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const onInventoryFetched = () => {
    setShouldFetchInventory(false);
  };

  useEffect(() => {
    // Retrieve the active tab from localStorage on component mount
    const storedActiveTab = localStorage.getItem("activeTab");
    if (storedActiveTab !== null) {
      // Check if activeTab is not null or undefined
      setUiState((prevState) => ({
        ...prevState,
        inventoryTabValue: parseInt(storedActiveTab),
      }));

      // Delete the activeTab from localStorage
      localStorage.removeItem("activeTab");
    }
  }, []);

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userId = userDetails ? userDetails.userId : null;
    const role = userDetails ? userDetails.role : null;

    setUserState((prevState) => ({
      ...prevState,
      userRole: role,
    }));

    if (!userId) {
      navigate("/login");
      return;
    }

    setIsDataFetching(true);
    setUiState((prevState) => ({ ...prevState, isLoading: true }));

    if (!userProfileRef.current) {
      setLoadingUserProfile(true);
      fetchUserProfile(userId)
        .then((data) => {
          userProfileRef.current = data;
          setLoadingUserProfile(false);
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
          setLoadingUserProfile(false);
        });
    }

    if (!userInventoryRef.current) {
      setLoadingUserInventory(true);
      fetchUserInventory(userId)
        .then((data) => {
          userInventoryRef.current = data;
          setLoadingUserInventory(false);
        })
        .catch((error) => {
          console.error("Error fetching user inventory:", error);
          setLoadingUserInventory(false);
        });
    }

    fetchItemsHook().catch(console.error);
    getBackgroundImageSize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [navigate]);

  useEffect(() => {
    setLoadingFishingLocations(true);
    setLoadingForagingLocations(true);

    const fetchLocations = async (userId) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/fish/locations`,
          {
            params: { userId },
          }
        );
        if (response.data) {
          const { unlockedLocations, lockedLocations } = response.data; // Destructure response

          const fishingLocations = unlockedLocations.filter(
            (location) => location.status === "fishlocation"
          );
          const lockedFishingLocations = lockedLocations.filter(
            (location) => location.status === "fishlocation"
          ); // New

          const foragingLocations = unlockedLocations.filter(
            (location) => location.status === "foragelocation"
          );
          const lockedForagingLocations = lockedLocations.filter(
            (location) => location.status === "foragelocation"
          ); // New

          setFishingLocations(fishingLocations);
          setLockedFishingLocations(lockedFishingLocations); // New
          fishingLocationsRef.current = fishingLocations;

          setForagingLocations(foragingLocations);
          setLockedForagingLocations(lockedForagingLocations); // New
          foragingLocationsRef.current = foragingLocations;
        }
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      } finally {
        setLoadingFishingLocations(false);
        setLoadingForagingLocations(false);
      }
    };

    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");

    if (userDetails && userDetails.userId) {
      fetchLocations(userDetails.userId);
    } else {
      console.log("User details not found or userId is missing");
      setLoadingFishingLocations(false);
      setLoadingForagingLocations(false);
    }
  }, []);

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const storedUserProfile = ls.get("userProfile");
    if (storedUserProfile) {
      userProfileRef.current = storedUserProfile;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!loadingUserProfile && !loadingUserInventory) {
        try {
          const skills = userProfileRef.current?.skills || [];
          const lockedSkills = userProfileRef.current?.lockedSkills || [];
          const allSkills = [...skills, ...lockedSkills]; // Combine known and locked skills

          setUserState((prevState) => ({
            ...prevState,
            userProfile: userProfileRef.current,
            userInventory: userInventoryRef.current,
          }));

          setSkills(allSkills); // Set the combined skills state
        } catch (error) {
          console.error("Error in setting user state:", error);
        } finally {
          setUiState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          setIsDataFetching(false);

          if (
            userProfileRef.current &&
            userProfileRef.current.afkDetails.isAfk
          ) {
            setBatchProcessedDialogOpen(true);
          }
        }
      }
    };

    if (isDataFetching) {
      fetchData();
    }
  }, [isDataFetching, loadingUserProfile, loadingUserInventory]);

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const storedUserProfile = ls.get("userProfile");
    if (storedUserProfile) {
      userProfileRef.current = storedUserProfile;
    }
  }, []);

  useEffect(() => {
    const updateState = async () => {
      if (!loadingUserProfile && !loadingUserInventory && isDataFetching) {
        const skills = userProfileRef.current?.skills || [];
        setUserState((prevState) => ({
          ...prevState,
          userProfile: userProfileRef.current,
          userInventory: userInventoryRef.current,
          skills: skills,
        }));

        setUiState((prevState) => ({ ...prevState, isLoading: false }));
        setIsDataFetching(false);
      }
    };

    updateState();
  }, [loadingUserProfile, loadingUserInventory, isDataFetching]);

  const handleLogout = useCallback(() => {
    const ls = new SecureLS({ isCompression: false });
    ls.remove("userDetails");
    navigate("/login");
  }, [navigate]);

  const handleInventoryTabChange = useCallback(
    (event, newValue) => {
      setUiState((prevState) => ({
        ...prevState,
        inventoryTabValue: newValue,
      }));

      if (newValue === 1 && !isInventoryFetched) {
        setShouldFetchInventory(true);
      }
    },
    [isInventoryFetched]
  );

  const fetchExpNeededData = useCallback(async (itemId, currentLevel) => {
    // Attempt to retrieve the data from the local item levels
    const expNeeded = getExpForNextItemLevel(currentLevel, itemId);

    // If the data is available locally, return it
    if (expNeeded !== null) {
      return expNeeded;
    }

    // If the data is not found locally, log an error or handle it accordingly
    console.error(
      `Exp data not found for item ID ${itemId} at level ${currentLevel}`
    );
    return null;
  }, []);

  // Fetch expNeeded data when the component mounts
  useEffect(() => {
    // Check if expNeededData has already been fetched before
    if (!expNeededFetched.current) {
      fetchExpNeededData().catch(console.error);
    }
  }, [fetchExpNeededData]);

  const resetUpdatedGatheredItems = useCallback(() => {
    setGatheredItems({}); // This resets the gatheredItems state
  }, [setGatheredItems]);

  useEffect(() => {
    setResetGatheredItems(() => resetUpdatedGatheredItems);
  }, [resetUpdatedGatheredItems]);

  useEffect(() => {
    userInventoryRef.current = userInventory;
  }, [userInventory]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        Object.keys(autoGathering).length === 0 &&
        Object.values(gatherBatch).some((count) => count > 0)
      ) {
        setLocalGatherBatch(gatherBatch);
        processGatherBatchHook();
      }
    }, 1500);

    return () => clearInterval(interval);
  }, [gatherBatch, autoGathering, setLocalGatherBatch, processGatherBatchHook]);

  const getBackgroundImageSize = () => {
    const backgroundImage = new Image();
    backgroundImage.src = "/assets/home_banner.webp";
    backgroundImage.onload = () => {
      setBackgroundImageSize(backgroundImage.width);
    };
  };

  // Calculate logo size based on background image size and window width
  const logoSize = useMemo(() => {
    const minLogoSize = 340;
    const maxLogoSize = 435.4;
    return windowWidth < 625 ? minLogoSize : maxLogoSize;
  }, [windowWidth]);

  const isAnyDataFetching =
    loadingFishingLocations || loadingUserProfile || loadingUserInventory;

  if (isAnyDataFetching) {
    // Show loading indicator while any data is fetching
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <HomeLayout
        tabTitles={tabTitles}
        tabValue={uiState.tabValue}
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        handleSidebarTabChange={handleSidebarTabChange}
        handleLogout={handleLogout}
        logoSize={logoSize}
        userProfile={userProfileRef.current}
        userRole={userState.userRole}
        optimizedImageSrc={optimizedImageSrc}
      >
        <TabPanel value={tabValue} index={0}>
          <SkillsTab
            skills={skills}
            items={items}
            handleGatherHook={handleGatherHook}
            handleAutoGatherHook={handleAutoGatherHook}
            handleProcessHook={handleProcessHook}
            handleCraftHook={handleCraftHook}
            expPercentages={expPercentages}
            userInventory={userInventory}
            isAutoGather={isAutoGather}
            setIsAutoGather={setIsAutoGather}
            setUserInventory={setUserInventory}
            fishingLocations={fishingLocationsRef.current}
            lockedFishingLocations={lockedFishingLocations}
            foragingLocations={foragingLocationsRef.current}
            lockedForagingLocations={lockedForagingLocations}
            userInventoryRef={userInventoryRef}
            userProfileRef={userProfileRef}
            fishInventoryRef={fishInventoryRef}
            forageInventoryRef={forageInventoryRef}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Tabs
            value={inventoryTabValue}
            onChange={handleInventoryTabChange}
            aria-label="inventory tabs"
            variant="scrollable"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Materials" {...a11yProps(1)} />
            <Tab label="Processed" {...a11yProps(2)} />
            <Tab label="Crafted" {...a11yProps(3)} />
            <Tab label="Fish" {...a11yProps(4)} />
            <Tab label="Seeds" {...a11yProps(5)} />
            <Tab label="Produce" {...a11yProps(6)} />
            <Tab label="Currency" {...a11yProps(7)} />
          </Tabs>
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <InventoryTab
              filterType={
                inventoryTabValue === 0
                  ? "all"
                  : inventoryTabValue === 1
                  ? "materials"
                  : inventoryTabValue === 2
                  ? "processed"
                  : inventoryTabValue === 3
                  ? "crafted"
                  : inventoryTabValue === 4
                  ? "fish"
                  : inventoryTabValue === 5
                  ? "seeds"
                  : inventoryTabValue === 6
                  ? "produce"
                  : "currency"
              }
              updatedInventory={userInventoryRef.current?.itemDetails} // Pass the user inventory data here
              localInventory={localInventory}
              setLocalInventory={setLocalInventory}
              shouldFetchInventory={shouldFetchInventory}
              onInventoryFetched={onInventoryFetched}
              userInventoryRef={userInventoryRef}
            />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ShopTab userInventoryRef={userInventoryRef} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <MarketTab userInventoryRef={userInventoryRef} items={items} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <ProfileTab userProfile={userProfileRef.current} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <LeaderboardTab />
        </TabPanel>
      </HomeLayout>
      {userRole === "admin" && (
        <TabPanel value={tabValue} index={6}>
          <AddItemForm />
        </TabPanel>
      )}
      <Box
        sx={{
          position: "fixed",
          bottom: 10,
          left: 20,
          zIndex: 1000,
          display: "flex",
          flexDirection: "column-reverse",
          gap: "10px",
        }}
      >
        <NotificationsDisplay />
      </Box>

      <BatchProcessedDialog
        batchProcessedDialogOpen={batchProcessedDialogOpen}
        setBatchProcessedDialogOpen={setBatchProcessedDialogOpen}
        items={items}
        localGatherBatch={localGatherBatch}
        setLocalGatherBatch={setLocalGatherBatch}
        setGatherBatch={setGatherBatch}
        userProfileRef={userProfileRef}
        userInventoryRef={userInventoryRef}
      />
      <ToastContainer position="top-center" autoClose={1500} hideProgressBar />
    </Suspense>
  );
}
