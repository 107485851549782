import React, { useState } from "react";
import { Box, TextField, Button, Typography, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

const ItemProcessOptions = ({ data, onChange }) => {
  const [newProcess, setNewProcess] = useState({
    itemId: "",
    itemAmount: "",
  });

  const handleNewProcessChange = (field, value) => {
    setNewProcess((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAddProcess = () => {
    if (newProcess.itemId && newProcess.itemAmount) {
      onChange([...data, newProcess]);
      setNewProcess({
        itemId: "",
        itemAmount: "",
      });
    }
  };

  const handleRemoveProcess = (index) => {
    const updatedData = data.filter((_, i) => i !== index);
    onChange(updatedData);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* Form for adding a new process */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Add New Process
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          mb: 2,
        }}
      >
        <TextField
          label="Item ID"
          type="number"
          value={newProcess.itemId}
          onChange={(e) => handleNewProcessChange("itemId", e.target.value)}
        />
        <TextField
          label="Item Amount"
          type="number"
          value={newProcess.itemAmount}
          onChange={(e) => handleNewProcessChange("itemAmount", e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddProcess}
          startIcon={<Add />}
        >
          Add Process
        </Button>
      </Box>

      {/* Display list of added processes */}
      <Typography variant="h6" sx={{ mt: 2 }}>
        Added Processes
      </Typography>
      {data.map((process, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            alignItems: "center",
            border: "1px solid #ddd",
            borderRadius: "8px",
            p: 2,
            mt: 2,
          }}
        >
          <Typography>
            <strong>Item ID:</strong> {process.itemId || "N/A"}
          </Typography>
          <Typography>
            <strong>Item Amount:</strong> {process.itemAmount || "N/A"}
          </Typography>
          <IconButton color="error" onClick={() => handleRemoveProcess(index)}>
            <Delete />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default ItemProcessOptions;
