export const formatQuantity = (quantity) => {
  if (quantity === 0) {
    return "0";
  } else if (quantity >= 1e15) {
    // Quadrillions
    return `${(quantity / 1e15).toFixed(2)}Q`;
  } else if (quantity >= 1e12) {
    // Trillions
    return `${(quantity / 1e12).toFixed(2)}T`;
  } else if (quantity >= 1e9) {
    // Billions
    return `${(quantity / 1e9).toFixed(2)}B`;
  } else if (quantity >= 1e6) {
    // Millions
    return `${(quantity / 1e6).toFixed(2)}M`;
  } else if (quantity >= 1e3) {
    // Thousands
    return `${(quantity / 1e3).toFixed(2)}K`;
  } else {
    return quantity.toFixed(0).toString();
  }
};
