import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SecureLS from "secure-ls";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { useAutoGather } from "../GatherTab/AutoGatherContext";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import "../../../../../css/home.css";

const ls = new SecureLS({ isCompression: false });

const CraftItemCard = ({
  item,
  currentItems,
  onCraft,
  userInventory,
  setUserInventory,
  craftItems,
  allItems,
  lockedItems,
  otherLockedItems,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [craftAmount, setCraftAmount] = useState(1);
  const [isCrafting, setIsCrafting] = useState(false);
  const [currentCraft, setCurrentCraft] = useState(0);
  const [totalCrafts, setTotalCrafts] = useState(0);
  const [hasCrafted, setHasCrafted] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { craftingProgress, updateCraftingProgress } = useAutoGather();
  const [infoDialog, setInfoDialog] = useState(false);
  const [isSelling, setIsSelling] = useState(false);
  const [sellButtonText, setSellButtonText] = useState("Sell");
  const [showSellDialog, setShowSellDialog] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [earnedCoins, setEarnedCoins] = useState(0);
  const [modalInfo, setModalInfo] = useState(null);
  const inventoryUpdatedRef = useRef(false);

  const matchingItem = userInventory.itemDetails.find(
    (itemDetail) => itemDetail.itemId.toString() === item.itemId.toString()
  );

  const { updateCraftingState, setCraftingInterval, clearCraftingInterval } =
    useAutoGather();

  useEffect(() => {
    const craftDetails = ls.get(`craftSkill_${item.itemId}`);
    const currentTime = new Date().getTime();

    if (craftDetails) {
      if (currentTime >= craftDetails.endTime) {
        setIsCrafting(false);
        setCurrentCraft(100);
      } else {
        const elapsedTime = currentTime - craftDetails.startTime;
        const totalCraftTime = craftDetails.endTime - craftDetails.startTime;
        const progress = (elapsedTime / totalCraftTime) * 100;
        setIsCrafting(true);
        setCurrentCraft(progress);

        const remainingTime = craftDetails.endTime - currentTime;
        const interval = updateCraftProgress(100 - progress, remainingTime);
        setCraftingInterval(item.itemId, interval);
      }
    } else {
      setIsCrafting(false);
      setCurrentCraft(0);
    }
  }, [
    item.itemId,
    craftingProgress,
    updateCraftingProgress,
    setCraftingInterval,
  ]);

  useEffect(() => {
    // Create an interval to check for modal data every 500ms
    const interval = setInterval(() => {
      const modalData = ls.get("craftModalData");
      if (modalData) {
        setModalInfo(modalData);
        ls.remove("craftModalData"); // Remove the localStorage entry immediately
        clearInterval(interval); // Clear the interval once modal data is found
      }
    }, 500);

    return () => clearInterval(interval); // Cleanup interval when component unmounts
  }, []);

  const handleCloseModal = () => {
    setModalInfo(null);
  };

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  const toggleInfoDialog = () => setInfoDialog(!isDialogOpen);

  const isLockedItem =
    lockedItems.some((lockedItem) => lockedItem.itemId === item.itemId) ||
    otherLockedItems.some((lockedItem) => lockedItem.itemId === item.itemId);

  const findItemDetailsById = (id) => {
    let foundItem = allItems.find((item) => item.itemId === id);
    if (!foundItem) {
      foundItem = craftItems.find((item) => item.itemId === id);
    }
    if (!foundItem) {
      foundItem = currentItems.find((item) => item.itemId === id);
    }

    const userItem = userInventory.itemDetails.find(
      (invItem) => invItem.itemId === id.toString()
    );

    const isLockedItem = lockedItems.some(
      (lockedItem) => lockedItem.itemId === id
    );

    return {
      name: foundItem && !isLockedItem ? foundItem.name : "???",
      imageUrl: foundItem ? foundItem.imageUrl : "/assets/items/default.webp",
      userAmount: userItem ? userItem.quantity : 0,
    };
  };

  const getUnlockRequirement = () => {
    if (item.itemPreq && item.itemPreq.length > 0) {
      const preq = item.itemPreq[0];
      const requiredItem = allItems.find((itm) => itm.itemId === preq.itemId);

      const isLockedItem =
        lockedItems.some((lockedItem) => lockedItem.itemId === preq.itemId) ||
        otherLockedItems.some(
          (lockedItem) => lockedItem.itemId === preq.itemId
        );

      if (requiredItem) {
        const itemName = isLockedItem ? "???" : requiredItem.name;
        return `Progress "${itemName}" to level ${preq.requiredLevel} to unlock.`;
      }
    }
    return null;
  };

  const unlockRequirementText = getUnlockRequirement();

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setHasCrafted(false);
  };
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenInfoDialog = () => setInfoDialog(true);
  const handleCloseInfoDialog = () => setInfoDialog(false);

  const getMaxCraftAmount = () => {
    return Math.min(
      ...item.itemProcess.map((craft) => {
        const { userAmount } = findItemDetailsById(craft.itemId);
        return Math.floor(userAmount / craft.itemAmount);
      })
    );
  };

  const handleChangeAmount = (event) => {
    let amount =
      event.target.value === "" ? "" : parseInt(event.target.value, 10);

    const maxCraftableAmount = getMaxCraftAmount();
    if (amount > maxCraftableAmount) {
      amount = maxCraftableAmount;
    }

    setCraftAmount(amount);
    setHasCrafted(false);
  };

  const isConfirmDisabled = () => {
    if (craftAmount === "" || craftAmount === 0) {
      return true;
    }

    return item.itemProcess.some((craft) => {
      const { userAmount } = findItemDetailsById(craft.itemId);
      return craftAmount * craft.itemAmount > userAmount;
    });
  };

  // Function to handle cancel craft
  const handleCancelCraft = () => {
    setCancelDialogOpen(true);
  };

  // Function to handle the cancel confirmation
  const handleConfirmCancel = async () => {
    const craftDetails = ls.get(`craftSkill_${item.itemId}`);

    if (!craftDetails) return;

    try {
      const userDetails = ls.get("userDetails");
      const userId = userDetails ? userDetails.userId : null;

      // Restore 60% of the materials
      const updatedMaterials = item.itemProcess.map((craft) => {
        const inventoryItem = userInventory.itemDetails.find(
          (invItem) => invItem.itemId === craft.itemId.toString()
        );

        const restoreAmount = Math.floor(
          craft.itemAmount * 0.6 * craftDetails.amountToCraft
        );

        let finalAmount = restoreAmount;
        if (inventoryItem) {
          inventoryItem.quantity += restoreAmount;
          finalAmount = inventoryItem.quantity;
        }

        return {
          itemId: craft.itemId,
          restoreAmount: restoreAmount,
          finalAmount: finalAmount,
        };
      });

      // Send the final amounts to the backend to update the inventory
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/updateItems/${userId}`,
        { updatedMaterials }
      );

      setUserInventory({ ...userInventory }); // Trigger a re-render to reflect changes

      // Remove the craft from local storage
      ls.remove(`craftSkill_${item.itemId}`);
      setCancelDialogOpen(false);
      setIsCrafting(false);
      setCurrentCraft(0);
    } catch (error) {
      console.error("Error updating materials:", error);
    }
  };

  const handleConfirmCraft = async () => {
    const maxAmount = Math.min(
      ...item.itemProcess.map((craft) => {
        const { userAmount } = findItemDetailsById(craft.itemId);
        return Math.floor(userAmount / craft.itemAmount);
      })
    );

    const amountToCraft = Math.min(craftAmount, maxAmount);

    const hasMaterials = item.itemProcess.every((craft) => {
      const { userAmount } = findItemDetailsById(craft.itemId);
      return amountToCraft * craft.itemAmount <= userAmount;
    });

    if (!hasMaterials) {
      console.error("Not enough materials to craft this amount.");
      return;
    }

    const totalTime = item.processTime * amountToCraft * 1000;
    const startTime = new Date().getTime();
    const endTime = startTime + totalTime;

    const craftDetails = {
      itemId: item.itemId,
      amountToCraft,
      startTime,
      endTime,
    };
    ls.set(`craftSkill_${item.itemId}`, craftDetails);

    // Deduct the materials immediately after confirming the craft
    const updatedMaterials = item.itemProcess.map((craft) => {
      const inventoryItem = userInventory.itemDetails.find(
        (invItem) => invItem.itemId.toString() === craft.itemId.toString()
      );
      let finalAmount = 0;
      if (inventoryItem) {
        inventoryItem.quantity -= craft.itemAmount * amountToCraft;
        if (inventoryItem.quantity < 0) inventoryItem.quantity = 0; // Avoid negative quantities
        finalAmount = inventoryItem.quantity; // Store the final quantity after deduction
      } else {
        console.warn(`Item ${craft.itemId} not found in inventory.`);
      }

      return {
        itemId: craft.itemId,
        finalAmount: finalAmount, // The final amount to send to the backend
      };
    });

    setUserInventory({ ...userInventory }); // Trigger a re-render with the updated inventory

    try {
      const userDetails = ls.get("userDetails");
      const userId = userDetails ? userDetails.userId : null;

      // Send updated material quantities to the backend to persist the changes
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/updateItems/${userId}`,
        { updatedMaterials }
      );
      console.log("Inventory updated successfully on the backend.");
    } catch (error) {
      console.error("Error updating inventory on the backend:", error);
    }

    setIsCrafting(true);
    setTotalCrafts(amountToCraft);
    setCurrentCraft(0);
    handleCloseDialog();
    updateCraftingState(item.itemId, true);

    const interval = updateCraftProgress(amountToCraft);
    setCraftingInterval(item.itemId, interval);
  };

  const handleFinalCraft = (itemId) => {
    const craftDetails = ls.get(`craftSkill_${itemId}`);
    const currentTime = new Date().getTime();

    if (craftDetails) {
      if (currentTime >= craftDetails.endTime) {
        const craftedItem = userInventory.itemDetails.find(
          (invItem) => invItem.itemId === craftDetails.itemId.toString()
        );
        if (craftedItem) {
          craftedItem.quantity += 1 * craftDetails.amountToCraft;
        } else {
          userInventory.itemDetails.push({
            itemId: craftDetails.itemId.toString(),
            quantity: 1 * craftDetails.amountToCraft,
          });
        }

        // Call onCraft if needed for further logic
        onCraft(craftDetails.itemId, craftDetails.amountToCraft);

        // Remove the craft from secure storage
        ls.remove(`craftSkill_${craftDetails.itemId}`);

        // After collection, reset the state
        setIsCrafting(false);
        setCurrentCraft(0);
      } else {
        console.log("Craft still ongoing, waiting for completion");
      }
    } else {
      console.log("No craft found in local storage");
    }
  };

  const handleSellCraft = async (itemId) => {
    const craftDetails = ls.get(`craftSkill_${itemId}`);

    if (!craftDetails) {
      console.error("No craft details found for this item.");
      return;
    }

    const userDetails = ls.get("userDetails");
    const userId = userDetails ? userDetails.userId : null;

    try {
      setIsSelling(true);
      setSellButtonText("Selling...");

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/allSell/${userId}`,
        {
          itemId,
          quantityToSell: craftDetails.amountToCraft,
          currencyId: 84,
        }
      );

      if (response.status === 200) {
        const earnedCoins =
          response.data.earnedCoins || craftDetails.amountToCraft;
        setEarnedCoins(earnedCoins);

        const craftCoinId = "84";
        const inventoryItem = userInventory.itemDetails.find(
          (invItem) => invItem.itemId === craftCoinId
        );
        if (inventoryItem) {
          inventoryItem.quantity += earnedCoins;
        } else {
          userInventory.itemDetails.push({
            itemId: craftCoinId,
            quantity: earnedCoins,
          });
        }

        setUserInventory({ ...userInventory });

        ls.remove(`craftSkill_${itemId}`);
        setShowSellDialog(true);
      } else {
        console.error("Sell failed:", response.data.message);
      }
      setShowSellDialog(true);
    } catch (error) {
      console.error("Error during sell craft:", error);
    } finally {
      setIsSelling(false);
      setSellButtonText("Sell");
    }
  };

  const updateCraftProgress = (remainingProgress, remainingTime) => {
    const progressIncrement = remainingProgress / (remainingTime / 1000);

    clearCraftingInterval(item.itemId);

    let progress = currentCraft;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += progressIncrement;
        setCurrentCraft(progress);
        updateCraftingProgress(item.itemId, progress);
      } else {
        clearInterval(interval);
        setIsCrafting(false);
        setCurrentCraft(100);
        updateCraftingState(item.itemId, false);
      }
    }, 1000);

    return interval;
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        m: 1,
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        position: "relative",
      }}
    >
      <CardHeader
        title={isLockedItem ? "???" : item.name}
        subheader={`Level: ${matchingItem ? matchingItem.itemLevel : 0} Exp: ${
          item.itemExp ? ((item.itemExp / item.expNeeded) * 100).toFixed(2) : 0
        }%`}
        action={
          <>
            <IconButton onClick={handleOpenInfoDialog}>
              <InfoIcon style={{ color: "gray" }} />
            </IconButton>
            <Dialog
              open={infoDialog}
              onClose={handleCloseInfoDialog}
              PaperProps={{
                sx: {
                  border: "2px solid rgba(173, 216, 230)",
                  borderRadius: "8px",
                },
              }}
            >
              <div style={{ margin: "10px" }}>
                <Typography variant="subtitle1" gutterBottom>
                  Requirements:
                </Typography>
                <List style={{ padding: 0 }}>
                  {item.itemProcess.map((craft, index) => {
                    const { name, imageUrl, userAmount } = findItemDetailsById(
                      craft.itemId
                    );
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar src={imageUrl} alt={name} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${name}: ${craft.itemAmount}`}
                          secondary={`(${userAmount})`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <DialogActions>
                <Button onClick={handleCloseInfoDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        }
      />
      <CardMedia
        component="img"
        sx={{
          width: 190,
          maxWidth: 190,
          height: 190,
          maxHeight: 190,
          objectFit: "contain",
          display: "block",
          margin: "auto",
          textAlign: "center",
          filter: isLockedItem ? "grayscale(100%) blur(3px)" : "none",
        }}
        image={item.imageUrl || "/assets/items/default.webp"}
        alt={item.name}
        onClick={!isLockedItem ? toggleDialog : undefined}
      />

      {isLockedItem && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 2,
            pointerEvents: "none",
          }}
        >
          <Typography
            variant="h4"
            color="white"
            sx={{
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            }}
          >
            LOCKED
          </Typography>
          <Typography
            variant="caption"
            color="white"
            sx={{
              mt: 1,
              textAlign: "center",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)",
            }}
          >
            {unlockRequirementText}
          </Typography>
        </Box>
      )}

      <CardContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ width: "100%", mt: 0.15 }}
        >
          {ls.get(`craftSkill_${item.itemId}`) ? (
            (() => {
              const craftDetails = ls.get(`craftSkill_${item.itemId}`);
              const currentTime = new Date().getTime();

              if (craftDetails) {
                const endDate = new Date(craftDetails.endTime).toLocaleString(); // Format endTime

                if (currentTime >= craftDetails.endTime) {
                  // Show "Collect" button, no "Claim Items on" text
                  return (
                    <>
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "center",
                          display: "block",
                          marginBottom: "8px",
                        }}
                      >
                        Amount crafted: {craftDetails.amountToCraft}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleFinalCraft(item.itemId)}
                          sx={{ width: "48%", height: "40px" }}
                        >
                          Collect
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleSellCraft(item.itemId)}
                          sx={{ width: "48%", height: "40px" }}
                          disabled={isSelling} // Disable button while selling
                        >
                          {isSelling ? (
                            <CircularProgress size={24} color="inherit" /> // Show circular progress
                          ) : (
                            sellButtonText // Display "Sell" or "Selling..." text
                          )}
                        </Button>
                      </Box>
                    </>
                  );
                } else {
                  const elapsedTime = currentTime - craftDetails.startTime;
                  const totalCraftTime =
                    craftDetails.endTime - craftDetails.startTime;
                  const progress = (elapsedTime / totalCraftTime) * 100;
                  return (
                    <>
                      {/* Show Claim Items on: date only when progress bar is visible */}
                      <Typography
                        variant="caption"
                        sx={{
                          textAlign: "center",
                          display: "block",
                          marginBottom: "8px",
                        }}
                      >
                        Claim Items on: {endDate}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                            height: "40px",
                          }}
                        >
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{ width: "100%", height: "100%" }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "white" }}
                            >{`${Math.round(progress)}%`}</Typography>
                          </Box>
                        </Box>

                        {/* Cancel button with tooltip */}
                        <Tooltip title="Cancel Craft">
                          <IconButton
                            onClick={handleCancelCraft}
                            sx={{ color: "red" }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </>
                  );
                }
              }
            })()
          ) : (
            // Show "Craft" button, no "Claim Items on" text
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDialog}
              sx={{ width: "100%", height: "40px" }}
              disabled={isLockedItem}
            >
              Craft
            </Button>
          )}
        </Box>
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Craft How Many?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="craftAmount"
            label="Amount to Craft"
            type="number"
            fullWidth
            variant="standard"
            value={craftAmount}
            onChange={handleChangeAmount}
            inputProps={{ min: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmCraft} disabled={isConfirmDisabled()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDialogOpen}
        onClose={toggleDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}>
          {item.name}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{item.description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showSellDialog} onClose={() => setShowSellDialog(false)}>
        <DialogTitle>Sell Completed</DialogTitle>
        <DialogContent>
          <Typography display="inline">
            You have earned {earnedCoins}{" "}
          </Typography>
          <Box display="inline" component="span">
            <img
              src="/assets/items/currencies/craft-coin.webp"
              alt="Craft Coin"
              style={{ verticalAlign: "middle", marginLeft: 4 }}
              width="20"
            />
          </Box>
          <Typography display="inline"> Craft Coins.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSellDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
      >
        <DialogTitle>Cancel Craft</DialogTitle>
        <DialogContent>
          {(() => {
            const craftDetails = ls.get(`craftSkill_${item.itemId}`);
            if (!craftDetails)
              return (
                <Typography>
                  No craft details found for cancellation.
                </Typography>
              );

            return (
              <>
                <Typography>
                  Do you want to cancel the current craft?
                </Typography>
                <Typography>
                  Upon cancellation, you will receive only 60% of the materials
                  back:
                </Typography>
                <List>
                  {item.itemProcess.map((craft, index) => {
                    const { name, imageUrl, userAmount } = findItemDetailsById(
                      craft.itemId
                    );
                    const usedAmount =
                      craft.itemAmount * craftDetails.amountToCraft;
                    const restoredAmount = Math.floor(usedAmount * 0.6);

                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar src={imageUrl} alt={name} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${name}: ${usedAmount.toLocaleString()} -> ${restoredAmount.toLocaleString()}`}
                          secondary={`You currently have: ${userAmount.toLocaleString()}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            );
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogOpen(false)}>Exit</Button>
          <Button onClick={handleConfirmCancel} color="error">
            Cancel Craft
          </Button>
        </DialogActions>
      </Dialog>

      {modalInfo && (
        <Dialog open={Boolean(modalInfo)} onClose={handleCloseModal}>
          <DialogTitle style={{ textAlign: "center" }}>
            Craft Collected
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={modalInfo.img || "/assets/items/default.webp"} // Ensure default image in case of a missing or black image
              alt={modalInfo.name}
              style={{ width: 100, objectFit: "contain", marginBottom: 10 }} // Fix style to contain and fit the image correctly
            />
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {modalInfo.name}
            </Typography>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              {modalInfo.amountcrafted
                ? `${modalInfo.amountcrafted.toLocaleString()} crafted`
                : "N/A"}
              {modalInfo.newQuantity
                ? ` (New: ${modalInfo.newQuantity.toLocaleString()} in inventory)`
                : ""}
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
};

export default CraftItemCard;
