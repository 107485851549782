import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Badge,
} from "@mui/material";

const FarmStore = ({ userInventoryRef, userProfileRef, setUserInventory, userId }) => {
  const [storeProducts, setStoreProducts] = useState([]);
  const [currencyDetails, setCurrencyDetails] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const fetchStoreItems = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/store`
      );

      if (!response.ok) throw new Error("Failed to fetch store products");
      const data = await response.json();
      setStoreProducts(data.products);
      setCurrencyDetails(data.currencyDetails);
    } catch (error) {
      console.error("Error fetching store products:", error);
      setSnackbar({ open: true, message: "Failed to load store products" });
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrentFarmCoins = () => {
    const farmCoin = userInventoryRef.current?.itemDetails.find(
      (item) => item.itemId === "64"
    );
    return farmCoin ? farmCoin.quantity : 0;
  };

  const getFarmingSkillLevel = () => {
    const farmingSkill = userProfileRef.current?.skills.find(
      (skill) => skill.skillId === 6
    );
    return farmingSkill ? farmingSkill.skillLevel : 0;
  };

  const handleBuyProduct = async (product) => {
    const currentCoins = getCurrentFarmCoins();

    if (currentCoins < product.price) {
      setSnackbar({ open: true, message: "Insufficient Farm Coins" });
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/purchase/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ productName: product.productName }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setSnackbar({
          open: true,
          message: errorData.message || "Purchase failed",
        });
        return;
      }

      const data = await response.json();

      // Update purchased items in inventory
      const updatedPurchasedItems = [
        ...userInventoryRef.current.purchasedItems,
        { productName: product.productName, status: "purchased" },
      ];

      // Deduct farm coins from inventory
      const farmCoinIndex = userInventoryRef.current.itemDetails.findIndex(
        (item) => item.itemId === "64"
      );

      if (farmCoinIndex !== -1) {
        userInventoryRef.current.itemDetails[farmCoinIndex].quantity -=
          product.price;
      }

      // Update inventory ref and state
      userInventoryRef.current = {
        ...userInventoryRef.current,
        purchasedItems: updatedPurchasedItems,
      };
      setUserInventory({ ...userInventoryRef.current });
      getCurrentFarmCoins()

      setSnackbar({
        open: true,
        message: `${product.productName} purchased successfully!`,
      });
    } catch (error) {
      console.error("Error purchasing product:", error);
      setSnackbar({
        open: true,
        message: "An error occurred during purchase.",
      });
    }
  };

  useEffect(() => {
    fetchStoreItems();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Display Current Farm Coins */}
          {currencyDetails && (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              marginBottom={3}
            >
              <Typography variant="h6">
                Farm Coins: {getCurrentFarmCoins().toLocaleString()}
              </Typography>
              <Badge color="primary">
                <Avatar
                  src={currencyDetails.imageUrl}
                  alt={currencyDetails.name}
                />
              </Badge>
            </Box>
          )}

          {/* Store Products */}
          <Grid container spacing={3}>
            {storeProducts.map((product, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Box display="flex" justifyContent="center" mb={2}>
                      {/* Display Product Image */}
                      <Avatar
                        src={product.imageUrl}
                        alt={product.productName}
                        sx={{
                          width: 80,
                          height: 80,
                        }}
                      />
                    </Box>
                    <Typography variant="h6" textAlign="center">
                      {product.productName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textAlign="center"
                      sx={{ marginY: 1 }}
                    >
                      Price: {product.price.toLocaleString()}{" "}
                      <Avatar
                        src={currencyDetails?.imageUrl}
                        alt="Farm Coin"
                        sx={{
                          width: 16,
                          height: 16,
                          display: "inline-block",
                          verticalAlign: "middle",
                          marginLeft: 0.5,
                        }}
                      />
                    </Typography>
                    {userInventoryRef.current?.purchasedItems.some(
                      (item) => item.productName === product.productName
                    ) ? (
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        disabled
                      >
                        Purchased
                      </Button>
                    ) : getFarmingSkillLevel() >= product.requiredSkillLevel ? (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={getCurrentFarmCoins() < product.price}
                        onClick={() => handleBuyProduct(product)}
                      >
                        Purchase
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled
                      >
                        Required Level: {product.requiredSkillLevel}
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ open: false, message: "" })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default FarmStore;
