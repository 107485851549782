import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";

const EditSkillDialog = ({ open, onClose, skill, onSave }) => {
  const [skillLevel, setSkillLevel] = useState(skill.skillLevel);
  const [skillExp, setSkillExp] = useState(skill.skillExp);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const isSkillLevelValid =
      skillLevel !== "" && !isNaN(skillLevel) && parseInt(skillLevel, 10) > 0;
    const isSkillExpValid =
      skillExp !== "" && !isNaN(skillExp) && parseFloat(skillExp) >= 0;

    // Enable Save button if there are valid changes in either level or experience
    setIsSaveDisabled(
      !(
        (skillLevel !== skill.skillLevel || skillExp !== skill.skillExp) &&
        isSkillLevelValid &&
        isSkillExpValid
      )
    );
  }, [skillLevel, skillExp, skill.skillLevel, skill.skillExp]);

  const handleSave = () => {
    onSave({
      ...skill,
      skillLevel: parseInt(skillLevel, 10),
      skillExp: parseFloat(skillExp),
    });
    onClose(); // Close the dialog after saving
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Skill: {skill.skillName}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            label="Skill Level"
            type="number"
            value={skillLevel}
            onChange={(e) => setSkillLevel(e.target.value)}
            inputProps={{ min: 1 }} // Skill level cannot be below 1
            fullWidth
          />
          <TextField
            label={`Skill Experience (EXP Needed: ${skill.expNeeded})`}
            type="number"
            value={skillExp}
            onChange={(e) => setSkillExp(e.target.value)}
            inputProps={{ min: 0 }} // Skill experience cannot be negative
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="success" disabled={isSaveDisabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSkillDialog;
