import React from "react";
import { Box, TextField } from "@mui/material";

const ItemFarmOptions = ({ data, onChange }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        label="Days to Harvest"
        type="number"
        value={data.dateToHarvest?.days || ""}
        onChange={(e) => onChange("dateToHarvest.days", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Hours to Harvest"
        type="number"
        value={data.dateToHarvest?.hours || ""}
        onChange={(e) => onChange("dateToHarvest.hours", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Minutes to Harvest"
        type="number"
        value={data.dateToHarvest?.minutes || ""}
        onChange={(e) => onChange("dateToHarvest.minutes", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Days to Wilt"
        type="number"
        value={data.dateToWilt?.days || ""}
        onChange={(e) => onChange("dateToWilt.days", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Hours to Wilt"
        type="number"
        value={data.dateToWilt?.hours || ""}
        onChange={(e) => onChange("dateToWilt.hours", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Minutes to Wilt"
        type="number"
        value={data.dateToWilt?.minutes || ""}
        onChange={(e) => onChange("dateToWilt.minutes", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Harvest Product"
        type="number"
        value={data.harvestProduct || ""}
        onChange={(e) => onChange("harvestProduct", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Wilt Product"
        type="number"
        value={data.wiltProduct || ""}
        onChange={(e) => onChange("wiltProduct", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Type of Water"
        type="number"
        value={data.typeOfWater || ""}
        onChange={(e) => onChange("typeOfWater", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Water Amount"
        type="number"
        value={data.waterAmount || ""}
        onChange={(e) => onChange("waterAmount", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Water Image URL"
        type="text"
        value={data.waterImg || ""}
        onChange={(e) => onChange("waterImg", e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Bonus Products (comma-separated)"
        type="text"
        value={data.bonusProducts?.join(", ") || ""}
        onChange={(e) =>
          onChange(
            "bonusProducts",
            e.target.value.split(",").map((prod) => prod.trim())
          )
        }
        fullWidth
        sx={{ mb: 2 }}
      />
    </Box>
  );
};

export default ItemFarmOptions;
