import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

const AddRemoveSkillDialog = ({ open, onClose, skill, onConfirm, isAdd }) => {
  const handleConfirm = async () => {
    const success = await onConfirm(skill.skillId);
    if (success) {
      onClose(); // Close the dialog if the operation was successful
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isAdd ? `Unlock Skill` : `Remove Skill`}</DialogTitle>
      <DialogContent>
        {isAdd ? (
          <Typography>
            Are you sure you want to unlock the skill:{" "}
            <strong>{skill.skillName}</strong>?
            <br />
            <em>{skill.description}</em>
          </Typography>
        ) : (
          <Box>
            <Typography>
              Are you sure you want to remove the skill:{" "}
              <strong>{skill.skillName}</strong>?
            </Typography>
            <Typography>Current Level: {skill.skillLevel}</Typography>
            <Typography>Current Experience: {skill.skillExp}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{
            color: isAdd ? "green" : "red",
          }}
        >
          {isAdd ? "Unlock Skill" : "Remove Skill"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRemoveSkillDialog;
