import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableSortLabel,
} from "@mui/material";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";
import AddItemModal from "./AddItemModal";
import EditItemModal from "./EditItemModal";
import { calculateAverageRTP } from "./ItemRTP";

const AdminItems = () => {
  const [items, setItems] = useState([]);
  const [addItemOpen, setAddItemOpen] = useState(false);
  const [editItemOpen, setEditItemOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: "itemId",
    direction: "asc",
  });

  const ls = new SecureLS({ isCompression: false });
  const adminDetails = ls.get("adminDetails");
  const token = adminDetails ? adminDetails.token : null;

  if (!token) {
    console.error("No admin token found.");
    setLoading(false);
    return;
  }

  const fetchItems = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/items/all`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setItems(data.sort((a, b) => a.itemId - b.itemId)); // Default sorting by itemId
      } else {
        console.error("Failed to fetch items:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = async (itemData) => {
    console.log("Adding item:", itemData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/items/add`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(itemData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Item added successfully:", result);

        Swal.fire({
          icon: "success",
          title: "Item Added",
          text: "The item was added successfully!",
          timer: 2000,
          showConfirmButton: false,
        });

        await fetchItems();
      } else {
        const error = await response.json();
        console.error("Error adding item:", error);

        Swal.fire({
          icon: "error",
          title: "Failed to Add Item",
          text: error.message || "An unknown error occurred.",
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const handleEditItem = async (updatedItem) => {
    console.log("Editing item:", updatedItem);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/items/edit`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedItem),
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Item Updated",
          text: "The item was updated successfully!",
          timer: 2000,
          showConfirmButton: false,
        });
        await fetchItems();
      } else {
        const error = await response.json();
        console.error("Error updating item:", error);
        Swal.fire({
          icon: "error",
          title: "Failed to Update Item",
          text: error.message || "An unknown error occurred.",
        });
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
      });
    }
  };

  const updateExistingQuantities = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/items/update-existing`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        await fetchItems();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${data.updatedItems} items updated successfully!`,
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: errorData.message || "Failed to update existing quantities.",
        });
      }
    } catch (error) {
      console.error("Error updating existing quantities:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating quantities.",
      });
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const filterItems = (items) => {
    const lowercasedFilter = searchTerm.toLowerCase();
    return items.filter(
      (item) =>
        item.name.toLowerCase().includes(lowercasedFilter) ||
        item.itemId.toString().includes(lowercasedFilter)
    );
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    const sortedItems = [...items].sort((a, b) => {
      if (key === "itemId") {
        return direction === "asc" ? a.itemId - b.itemId : b.itemId - a.itemId;
      }
      if (key === "name") {
        return direction === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      if (key === "existingQty") {
        const qtyA = a.stats?.existingQty || 0;
        const qtyB = b.stats?.existingQty || 0;
        return direction === "asc" ? qtyA - qtyB : qtyB - qtyA;
      }
      if (key === "category") {
        return direction === "asc"
          ? (a.status || "Unknown").localeCompare(b.status || "Unknown")
          : (b.status || "Unknown").localeCompare(a.status || "Unknown");
      }
      if (key === "rtp") {
        const rtpA =
          a.stats?.recentTradeValue?.reduce((sum, val) => sum + val, 0) /
            (a.stats?.recentTradeValue?.length || 1) || 0;
        const rtpB =
          b.stats?.recentTradeValue?.reduce((sum, val) => sum + val, 0) /
            (b.stats?.recentTradeValue?.length || 1) || 0;
        return direction === "asc" ? rtpA - rtpB : rtpB - rtpA;
      }
      return 0;
    });
    setSortConfig({ key, direction });
    setItems(sortedItems);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 4, px: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontFamily: '"Pixelify Sans", sans-serif',
          }}
        >
          Item Management
        </Typography>

        {/* Action Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontFamily: '"Pixelify Sans", sans-serif',
              mr: 2,
            }}
            onClick={() => setAddItemOpen(true)}
          >
            Add Item
          </Button>
          <Button
            variant="contained"
            color="info"
            sx={{
              fontFamily: '"Pixelify Sans", sans-serif',
            }}
            onClick={updateExistingQuantities}
          >
            Update Existing
          </Button>
        </Box>

        {/* Search Bar */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <TextField
            label="Search Items"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: "100%", maxWidth: 400 }}
          />
        </Box>

        {/* Items Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "itemId"}
                    direction={sortConfig.direction}
                    onClick={() => handleSort("itemId")}
                  >
                    Item ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "name"}
                    direction={sortConfig.direction}
                    onClick={() => handleSort("name")}
                  >
                    Item Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "category"}
                    direction={sortConfig.direction}
                    onClick={() => handleSort("category")}
                  >
                    Category
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "existingQty"}
                    direction={sortConfig.direction}
                    onClick={() => handleSort("existingQty")}
                  >
                    Existing Quantity
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === "rtp"}
                    direction={sortConfig.direction}
                    onClick={() => handleSort("rtp")}
                  >
                    RTV
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filterItems(items).length > 0 ? (
                filterItems(items).map((item) => {
                  const rtp = item.stats?.recentTradeValue || [];
                  const averageRTP = calculateAverageRTP(rtp);

                  return (
                    <TableRow key={item.itemId}>
                      <TableCell>{item.itemId}</TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={item.imageUrl || "/assets/items/default.webp"}
                            alt={item.name}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                              borderRadius: "4px",
                              marginRight: "8px",
                            }}
                          />
                          {item.name}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {item.status
                          ? item.status.charAt(0).toUpperCase() +
                            item.status.slice(1).toLowerCase()
                          : "Unknown"}
                      </TableCell>
                      <TableCell>
                        {item.stats?.existingQty
                          ? item.stats.existingQty.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            })
                          : "0"}
                      </TableCell>
                      <TableCell>{averageRTP}</TableCell>{" "}
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setSelectedItem(item);
                            setEditItemOpen(true);
                          }}
                          sx={{ mr: 1 }}
                        >
                          Edit
                        </Button>
                        {/* <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() =>
                            console.log(`Delete item ${item.itemId}`)
                          }
                        >
                          Delete
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Items Found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <AddItemModal
        open={addItemOpen}
        onClose={() => setAddItemOpen(false)}
        onSubmit={handleAddItem}
      />

      <EditItemModal
        open={editItemOpen}
        onClose={() => setEditItemOpen(false)}
        itemData={selectedItem}
        onSave={handleEditItem}
      />
    </>
  );
};

export default AdminItems;
