import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import SecureLS from "secure-ls";
import levels from "../../../../modules/System/Levels/userlevelsData";

const maxLevel = levels.length;

const UserInfosDialog = ({ userId, open, onClose, onSave }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [editedDetails, setEditedDetails] = useState({});
  const [originalDetails, setOriginalDetails] = useState(null);
  const [isValidLevel, setIsValidLevel] = useState(true);

  useEffect(() => {
    if (open && userId) {
      const fetchUserDetails = async () => {
        const ls = new SecureLS({ isCompression: false });
        const adminDetails = ls.get("adminDetails");
        const token = adminDetails ? adminDetails.token : null;

        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/info/${userId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            setUserDetails(data);
            setEditedDetails(data);
            setOriginalDetails(data); // Save original details for restore
            console.log("Fetched user details:", data);
          } else {
            console.error("Failed to fetch user details.");
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };

      fetchUserDetails();
    }
  }, [open, userId]);

  if (!userDetails) {
    return null;
  }

  const { name, email, level, exp, profileImg } = editedDetails;
  const expNeeded = levels[level - 1] ? levels[level - 1].expNeeded : null;
  const expPercentage = expNeeded
    ? ((exp / expNeeded) * 100).toFixed(2)
    : "0.00";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({
      ...editedDetails,
      [name]: value,
    });
  };

  const handleLevelChange = (e) => {
    let newLevel = e.target.value === "" ? "" : parseInt(e.target.value, 10);

    if (newLevel === "" || isNaN(newLevel)) {
      setIsValidLevel(false);
      setEditedDetails({
        ...editedDetails,
        level: "",
        exp: 0, // Reset experience to 0 when level is invalid
      });
    } else {
      if (newLevel < 1) {
        newLevel = 1;
      } else if (newLevel > maxLevel) {
        newLevel = maxLevel;
      }

      const validExp = levels[newLevel - 1]
        ? levels[newLevel - 1].expNeeded
        : null;
      setIsValidLevel(!!validExp);

      setEditedDetails({
        ...editedDetails,
        level: newLevel,
        exp: 0, // Reset experience to 0
      });
    }
  };

  const handleSave = async () => {
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/info/${userId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: editedDetails.name,
            email: editedDetails.email,
            level: editedDetails.level,
            exp: editedDetails.exp,
            adminId: adminId,
            adminName: adminName,
          }),
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        console.log("User details updated successfully");

        // Call onSave to update the user card
        if (onSave) {
          onSave(updatedUser.user);
        }

        onClose();
      } else {
        console.error("Failed to update user details.");
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const handleRestore = () => {
    setEditedDetails(originalDetails); // Restore original details
    setIsValidLevel(true); // Reset the validity check
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit User Information</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Box mr={2}>
            {profileImg ? (
              <img
                src={`/assets/profile/${profileImg}`}
                alt={name}
                style={{ width: 100, height: 100, borderRadius: "50%" }}
              />
            ) : (
              <Avatar sx={{ width: 100, height: 100 }} alt={name}>
                {name?.[0] || "A"}
              </Avatar>
            )}
          </Box>
          <Box flex={1}>
            <TextField
              fullWidth
              margin="normal"
              label={`Level (Max: ${maxLevel})`}
              name="level"
              type="number"
              value={level}
              onChange={handleLevelChange}
              title={`Max Level: ${maxLevel}`}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              name="name"
              value={name}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              value={email}
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Typography variant="body1" gutterBottom>
          Level: {level}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          EXP:{" "}
          {level > 0 && expNeeded
            ? `${exp.toLocaleString()} / ${expNeeded.toLocaleString()} (${expPercentage}%)`
            : "0/?? (Invalid Level)"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRestore} color="secondary">
          Restore
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isValidLevel}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserInfosDialog;
