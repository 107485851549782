import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Paper,
  Backdrop,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "../../modules/System/Components/Sidebar/Sidebar";
import { useTheme } from "@mui/material/styles";

export default function HomeLayout({
  tabTitles,
  tabValue,
  drawerOpen,
  toggleDrawer,
  handleSidebarTabChange,
  handleLogout,
  logoSize,
  userProfile,
  userRole,
  optimizedImageSrc,
  children,
}) {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{ backgroundColor: "rgba(173, 216, 230, 0.8)" }}
        >
          <Toolbar>
            <IconButton
              color="black"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer(true)}
              sx={{
                zIndex: theme.zIndex.drawer - 1,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                color: "black",
                fontFamily: '"Pixelify Sans", sans-serif',
              }}
            >
              {tabTitles[tabValue]}
            </Typography>
            <Typography
              sx={{ color: "black", display: { xs: "none", md: "block" } }}
            >
              {userProfile?.name || "User"}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
        <Paper
          sx={{
            background: "rgba(173, 216, 230, 0.8)",
            width: 240,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Sidebar
            tabTitles={tabTitles}
            tabValue={tabValue}
            handleSidebarTabChange={handleSidebarTabChange}
            handleLogout={handleLogout}
            logoSize={logoSize}
            userProfile={userProfile}
            userRole={userRole}
          />
        </Paper>
      </Drawer>

      {drawerOpen && (
        <Backdrop
          style={{ color: "#fff", zIndex: theme.zIndex.drawer - 1 }}
          open={drawerOpen}
          onClick={() => toggleDrawer(false)}
        />
      )}

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
          backgroundImage: `url(${optimizedImageSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -1,
        }}
      />

      {children}
    </>
  );
}
