import React, {
  createContext,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { getExpForNextItemLevel } from "../System/Levels/itemLevels";

const ExpContext = createContext();

export const useExp = () => useContext(ExpContext);

const fetchExpNeeded = async (itemId, level) => {
  try {
    // Use the local function to get the experience needed for the next level
    const expNeeded = getExpForNextItemLevel(level, itemId);

    if (expNeeded !== null) {
      return expNeeded;
    } else {
      console.error("Exp needed data not found in local data.");
      return null;
    }
  } catch (error) {
    console.error("Error while fetching expNeeded", error);
    return null;
  }
};

export const ExpProvider = ({ children }) => {
  const [expPercentages, setExpPercentages] = useState({});
  const expNeededFetched = useRef({});

  const calculateAndUpdateExpPercentages = useCallback(
    async (inventoryItems, updatedItem) => {
      if (updatedItem && typeof updatedItem.itemLvl !== "undefined") {
        let expNeeded =
          expNeededFetched.current[
            `${updatedItem.itemId}-${updatedItem.itemLvl}`
          ];

        if (typeof expNeeded === "undefined") {
          expNeeded = await fetchExpNeeded(
            updatedItem.itemId,
            updatedItem.itemLvl
          );
          if (expNeeded !== null) {
            expNeededFetched.current[
              `${updatedItem.itemId}-${updatedItem.itemLvl}`
            ] = expNeeded;
          } else {
            console.error(
              "Failed to fetch expNeeded for item and level ",
              updatedItem.itemId,
              updatedItem.itemLvl
            );
            return;
          }
        }

        setExpPercentages((state) => ({
          ...state,
          [updatedItem.itemId]: (updatedItem.itemExp / expNeeded) * 100,
        }));
      }
    },
    []
  );

  return (
    <ExpContext.Provider
      value={{ expPercentages, calculateAndUpdateExpPercentages }}
    >
      {children}
    </ExpContext.Provider>
  );
};
