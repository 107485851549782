import React, { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Shop from "./Shop";
import Animal from "./Animal";
import AnimalTypeMenu from "./Component/AnimalTypeMenu";

const AnimalTab = ({ onBackToSkills, userInventoryRef, setUserInventory }) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedType, setSelectedType] = useState(null);
  const [userId, setUserId] = useState("");
  const [actionStatus, setActionStatus] = useState({
    isLoading: false,
    message: "",
  });

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userIdFromStorage = userDetails ? userDetails.userId : null;
    setUserId(userIdFromStorage);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedType(null); // Reset selected type when switching tabs
  };

  const animalTypes = [
    ...new Set(
      userInventoryRef.current.itemDetails
        .filter((item) => item.itemType === "animal")
        .map((animal) => animal.itemName.split(" ")[1])
    ),
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ color: "green" }}>Animal Management</h2>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mt: "15px",
        }}
      >
        <IconButton onClick={onBackToSkills}>
          <ArrowBack />
        </IconButton>
        {tabValue === 0 && selectedType !== null && (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              ml: 2,
            }}
          >
            <button
              onClick={() => setSelectedType(null)}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#6ec5ff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Back to Ranch
            </button>
          </Box>
        )}
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{
            display: "flex",
            marginLeft: "auto",
            "& .MuiTabs-flexContainer": {
              backgroundColor: "transparent",
              borderRadius: "10px 10px 0 0",
              overflow: "hidden",
            },
            "& .MuiTab-root": {
              textTransform: "none",
              fontWeight: "bold",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              minHeight: "50px",
              backgroundColor: "lightgray",
            },
            "& .Mui-selected": {
              backgroundColor: tabValue === 0 ? "yellow" : "lightcoral",
            },
          }}
        >
          <Tab label="Ranch" />
          <Tab label="Store" />
        </Tabs>
      </Box>

      {tabValue === 0 && selectedType === null && (
        <AnimalTypeMenu
          animalTypes={animalTypes}
          userInventoryRef={userInventoryRef}
          setSelectedType={setSelectedType}
          setUserInventory={setUserInventory}
          userId={userId}
        />
      )}

      {tabValue === 0 && selectedType !== null && (
        <Animal
          userInventoryRef={userInventoryRef}
          setUserInventory={setUserInventory}
          selectedType={selectedType}
          userId={userId}
        />
      )}

      {tabValue === 1 && (
        <Shop
          userInventoryRef={userInventoryRef}
          setUserInventory={setUserInventory}
          userId={userId}
        />
      )}

      <Dialog
        open={actionStatus.isLoading}
        onClose={() => setActionStatus({ ...actionStatus, isLoading: false })}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogContent style={{ textAlign: "center" }}>
          <CircularProgress style={{ color: "#d2b48c" }} />
          <Typography variant="h6" style={{ marginTop: 16 }}>
            {actionStatus.message}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AnimalTab;
