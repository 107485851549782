import React from "react";
import { Box } from "@mui/material";
import Slider from "react-slick";
import ForageLocationCard from "./ForageLocationCard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ForageTabCardsSlider = ({
  locations,
  lockedLocations, // Accept locked locations as a prop
  onForage,
  userInventory,
  setUserInventory,
  items,
  userInventoryRef,
  userProfileRef,
  forageInventoryRef,
}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 0.35,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <>
      {window.innerWidth <= 1024 ? (
        <Slider {...settings}>
          {locations.map((location, idx) => (
            <Box key={idx}>
              <ForageLocationCard
                location={location}
                locations={locations}
                lockedLocations={lockedLocations}
                onForage={onForage}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                items={items}
                userInventoryRef={userInventoryRef}
                userProfileRef={userProfileRef}
                forageInventoryRef={forageInventoryRef}
              />
            </Box>
          ))}
          {lockedLocations.map((location, idx) => (
            <Box key={`locked-${idx}`}>
              <ForageLocationCard
                location={location}
                locations={locations}
                lockedLocations={lockedLocations}
                onForage={onForage}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                items={items}
                userInventoryRef={userInventoryRef}
                userProfileRef={userProfileRef}
                forageInventoryRef={forageInventoryRef}
                isLocked={true} // Mark this card as locked
              />
            </Box>
          ))}
        </Slider>
      ) : (
        <div style={{ overflowY: "scroll", maxHeight: "370px" }}>
          {locations.map((location, idx) => (
            <Box key={idx}>
              <ForageLocationCard
                location={location}
                locations={locations}
                lockedLocations={lockedLocations}
                onForage={onForage}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                items={items}
                userInventoryRef={userInventoryRef}
                userProfileRef={userProfileRef}
                forageInventoryRef={forageInventoryRef}
              />
            </Box>
          ))}
          {lockedLocations.map((location, idx) => (
            <Box key={`locked-${idx}`}>
              <ForageLocationCard
                location={location}
                locations={locations}
                lockedLocations={lockedLocations}
                onForage={onForage}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                items={items}
                userInventoryRef={userInventoryRef}
                userProfileRef={userProfileRef}
                forageInventoryRef={forageInventoryRef}
                isLocked={true} // Mark this card as locked
              />
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default ForageTabCardsSlider;
