import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Box,
  Divider,
  Typography,
  Collapse,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LogoutIcon from "@mui/icons-material/Logout";
import NoteIcon from "@mui/icons-material/Note";
import StoreIcon from "@mui/icons-material/Store";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ScienceIcon from "@mui/icons-material/Science";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FolderIcon from "@mui/icons-material/Folder";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

const AdminSidebar = ({ open, handleMenuClick, handleLogout }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const [openAssets, setOpenAssets] = React.useState(false);

  const handleAssetsClick = () => {
    setOpenAssets(!openAssets);
  };

  return (
    <Drawer
      variant="persistent"
      open={open}
      sx={{
        width: open ? drawerWidth : 0,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "#f5f5f5",
          transition: "width 0.3s ease",
          fontFamily: "'Roboto', sans-serif",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto", flexGrow: 1 }}>
        <List>
          <ListItem
            button
            onClick={() => handleMenuClick("/admin/dashboard")}
            sx={{
              backgroundColor: isActive("/admin/dashboard")
                ? "rgba(63, 81, 181, 0.1)"
                : "inherit",
              boxShadow: isActive("/admin/dashboard")
                ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                : "none",
              borderLeft: isActive("/admin/dashboard")
                ? "4px solid #3f51b5"
                : "none",
            }}
          >
            <ListItemIcon>
              <HomeIcon sx={{ color: "#3f51b5" }} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography sx={{ color: "#3f51b5" }}>Home</Typography>}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => handleMenuClick("/admin/users")}
            sx={{
              backgroundColor: isActive("/admin/users")
                ? "rgba(63, 81, 181, 0.1)"
                : "inherit",
              boxShadow: isActive("/admin/users")
                ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                : "none",
              borderLeft: isActive("/admin/users")
                ? "4px solid #3f51b5"
                : "none",
            }}
          >
            <ListItemIcon>
              <PeopleIcon sx={{ color: "#3f51b5" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#3f51b5" }}>Players</Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={() => handleMenuClick("/admin/leaderboard")}
            sx={{
              backgroundColor: isActive("/admin/leaderboard")
                ? "rgba(63, 81, 181, 0.1)"
                : "inherit",
              boxShadow: isActive("/admin/leaderboard")
                ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                : "none",
              borderLeft: isActive("/admin/leaderboard")
                ? "4px solid #3f51b5"
                : "none",
            }}
          >
            <ListItemIcon>
              <LeaderboardIcon sx={{ color: "#3f51b5" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#3f51b5" }}>Leaderboard</Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={() => handleAssetsClick()}
            sx={{
              backgroundColor: openAssets
                ? "rgba(63, 81, 181, 0.1)"
                : "inherit",
              boxShadow: openAssets ? "0 3px 6px rgba(0, 0, 0, 0.1)" : "none",
              borderLeft: openAssets ? "4px solid #3f51b5" : "none",
            }}
          >
            <ListItemIcon>
              <FolderIcon sx={{ color: "#3f51b5" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#3f51b5" }}>Assets</Typography>
              }
            />
            {openAssets ? (
              <ExpandLessIcon sx={{ color: "#3f51b5" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#3f51b5" }} />
            )}
          </ListItem>
          <Collapse in={openAssets} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => handleMenuClick("/admin/assets/items")}
                sx={{
                  backgroundColor: isActive("/admin/assets/items")
                    ? "rgba(63, 81, 181, 0.1)"
                    : "inherit",
                  boxShadow: isActive("/admin/assets/items")
                    ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                    : "none",
                  borderLeft: isActive("/admin/assets/items")
                    ? "4px solid #3f51b5"
                    : "none",
                  pl: 4,
                }}
              >
                <ListItemIcon>
                  <AssignmentIcon sx={{ color: "#3f51b5" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ color: "#3f51b5" }}>Items</Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                onClick={() => handleMenuClick("/admin/assets/skills")}
                sx={{
                  backgroundColor: isActive("/admin/assets/skills")
                    ? "rgba(63, 81, 181, 0.1)"
                    : "inherit",
                  boxShadow: isActive("/admin/assets/skills")
                    ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                    : "none",
                  borderLeft: isActive("/admin/assets/skills")
                    ? "4px solid #3f51b5"
                    : "none",
                  pl: 4,
                }}
              >
                <ListItemIcon>
                  <ScienceIcon sx={{ color: "#3f51b5" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ color: "#3f51b5" }}>Skills</Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                onClick={() => handleMenuClick("/admin/assets/locations")}
                sx={{
                  backgroundColor: isActive("/admin/assets/locations")
                    ? "rgba(63, 81, 181, 0.1)"
                    : "inherit",
                  boxShadow: isActive("/admin/assets/locations")
                    ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                    : "none",
                  borderLeft: isActive("/admin/assets/locations")
                    ? "4px solid #3f51b5"
                    : "none",
                  pl: 4,
                }}
              >
                <ListItemIcon>
                  <LocationOnIcon sx={{ color: "#3f51b5" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ color: "#3f51b5" }}>Locations</Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                onClick={() => handleMenuClick("/admin/assets/shop")}
                sx={{
                  backgroundColor: isActive("/admin/assets/shop")
                    ? "rgba(63, 81, 181, 0.1)"
                    : "inherit",
                  boxShadow: isActive("/admin/assets/shop")
                    ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                    : "none",
                  borderLeft: isActive("/admin/assets/shop")
                    ? "4px solid #3f51b5"
                    : "none",
                  pl: 4,
                }}
              >
                <ListItemIcon>
                  <ShoppingCartIcon sx={{ color: "#3f51b5" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ color: "#3f51b5" }}>Shop</Typography>
                  }
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            onClick={() => handleMenuClick("/admin/notifications")}
            sx={{
              backgroundColor: isActive("/admin/notifications")
                ? "rgba(63, 81, 181, 0.1)"
                : "inherit",
              boxShadow: isActive("/admin/notifications")
                ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                : "none",
              borderLeft: isActive("/admin/notifications")
                ? "4px solid #3f51b5"
                : "none",
            }}
          >
            <ListItemIcon>
              <NotificationsIcon sx={{ color: "#3f51b5" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#3f51b5" }}>Notifications</Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={() => handleMenuClick("/admin/market-listings")}
            sx={{
              backgroundColor: isActive("/admin/market-listings")
                ? "rgba(63, 81, 181, 0.1)"
                : "inherit",
              boxShadow: isActive("/admin/market-listings")
                ? "0 3px 6px rgba(0, 0, 0, 0.1)"
                : "none",
              borderLeft: isActive("/admin/market-listings")
                ? "4px solid #3f51b5"
                : "none",
            }}
          >
            <ListItemIcon>
              <StoreIcon sx={{ color: "#3f51b5" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ color: "#3f51b5" }}>
                  Market Listings
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <ListItem
          button
          onClick={() => handleMenuClick("/admin/Logs")}
          sx={{
            backgroundColor: isActive("/admin/Logs")
              ? "rgba(63, 81, 181, 0.1)"
              : "inherit",
            boxShadow: isActive("/admin/Logs")
              ? "0 3px 6px rgba(0, 0, 0, 0.1)"
              : "none",
            borderLeft: isActive("/admin/Logs") ? "4px solid #3f51b5" : "none",
          }}
        >
          <ListItemIcon>
            <NoteIcon sx={{ color: "#3f51b5" }} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={{ color: "#3f51b5" }}>Logs</Typography>}
          />
        </ListItem>
        <ListItem
          button
          onClick={handleLogout}
          sx={{
            backgroundColor: "inherit",
          }}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ color: "#3f51b5" }} />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={{ color: "#3f51b5" }}>Logout</Typography>}
          />
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default AdminSidebar;
