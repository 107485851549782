import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const ShopItemCard = ({
  userId,
  products,
  product,
  userInventoryRef,
  setBronzeCoinQuantity,
}) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [quantity, setQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(1);
  const isPurchased = product.status === "purchased";
  const [totalPrice, setTotalPrice] = useState(product.value);
  const [dialogContent, setDialogContent] = useState("");
  const [autogatherDialogOpen, setAutogatherDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const isItemAvailableInInventory =
    product.shopTag === "items" &&
    product.shopProducts.length > 0 &&
    userInventoryRef.current.itemDetails.some((inventoryItem) => {
      const productItemId = product.shopProducts[0].itemId.toString();
      const inventoryItemId = inventoryItem.itemId.toString();
      return inventoryItemId === productItemId;
    });

  // Determine the currency image to display
  const currencyImg =
    product.shopCurrency &&
    product.shopCurrency.length > 0 &&
    product.shopCurrency[0].itemImage
      ? product.shopCurrency[0].itemImage
      : "/assets/items/currencies/bronze-coin2.webp";

  // Calculate the max quantity the user can afford
  useEffect(() => {
    const currencyToUse =
      product.shopCurrency && product.shopCurrency.length > 0
        ? product.shopCurrency[0]
        : { itemId: "5", amount: product.value };

    const pricePerItem = currencyToUse.amount;
    const userCurrency = userInventoryRef.current.itemDetails.find(
      (item) => item.itemId.toString() === currencyToUse.itemId.toString()
    );

    const maxAffordableQuantity = userCurrency
      ? Math.floor(userCurrency.quantity / pricePerItem)
      : 0;

    setMaxQuantity(maxAffordableQuantity);
    calculateTotalPrice(quantity);
  }, [product, userInventoryRef, quantity]);

  // Handle opening the purchase dialog
  const handleOpen = () => {
    if (product.shopTag !== "autogather") {
      setOpen(true);
    } else {
      onPurchase(product._id, 1);
    }
  };

  // Handle closing the purchase dialog
  const handleClose = () => {
    setOpen(false);
    setQuantity(1);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle change in purchase quantity
  const handleChangeQuantity = (event) => {
    let inputQuantity = event.target.value.trim();
    if (inputQuantity === "") {
      setQuantity("");
      setTotalPrice(0);
    } else {
      inputQuantity = parseInt(inputQuantity, 10);
      if (!isNaN(inputQuantity)) {
        inputQuantity = Math.max(1, inputQuantity);
        inputQuantity = Math.min(inputQuantity, maxQuantity);
        setQuantity(inputQuantity);
        calculateTotalPrice(inputQuantity);
      }
    }
  };

  // Calculate total price based on quantity
  const calculateTotalPrice = (quantity) => {
    const pricePerItem =
      product.shopCurrency && product.shopCurrency.length > 0
        ? product.shopCurrency[0].amount
        : product.value;
    setTotalPrice(pricePerItem * quantity);
  };

  const finalizePurchase = () => {
    if (quantity <= maxQuantity) {
      onPurchase(product._id, quantity);
      handleClose();
    } else {
      alert(
        `You cannot purchase more than ${maxQuantity} based on your current inventory.`
      );
    }
  };

  const updateInventory = () => {
    const bronzeCoinIndex = userInventoryRef.current.itemDetails.findIndex(
      (item) => item.itemId === "5"
    );
    if (bronzeCoinIndex !== -1) {
      const newBronzeCoinQuantity =
        userInventoryRef.current.itemDetails[bronzeCoinIndex].quantity;
      setBronzeCoinQuantity(newBronzeCoinQuantity.toFixed(2));
    } else {
      console.error("Bronze coin not found in inventory.");
    }
  };

  // Determine the displayed value: use shopCurrency amount if available, otherwise use product.value
  const formattedValue =
    product.shopCurrency && product.shopCurrency.length > 0
      ? product.shopCurrency[0].amount.toLocaleString()
      : product.value.toLocaleString();

  const BRONZE_COIN_ITEM_ID = "5";

  const checkSufficientCurrency = (totalCost) => {
    const currencyToUse =
      product.shopCurrency && product.shopCurrency.length > 0
        ? product.shopCurrency[0]
        : { itemId: BRONZE_COIN_ITEM_ID, amount: product.value };

    const currencyIndex = userInventoryRef.current.itemDetails.findIndex(
      (item) => item.itemId.toString() === currencyToUse.itemId.toString()
    );

    if (currencyIndex !== -1) {
      const userCurrencyAmount =
        userInventoryRef.current.itemDetails[currencyIndex].quantity;
      return userCurrencyAmount >= totalCost;
    }

    return false;
  };

  const onPurchase = async (productId, quantity) => {
    const productDetails = products.find(
      (product) => product._id === productId
    );

    if (!productDetails) {
      console.error("Product details not found for ID:", productId);
      return;
    }

    // Check if the user has sufficient coins or currency to purchase
    const totalCost = productDetails.value * quantity;
    const hasSufficientCurrency = checkSufficientCurrency(totalCost);

    if (!hasSufficientCurrency) {
      handleSnackbarOpen("Insufficient currency to purchase", "error");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/purchase/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ productId, quantity }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setDialogContent("Product successfully purchased!");

        if (product.shopTag === "autogather") {
          setDialogContent("Purchase Successful for autogather item!");
          setAutogatherDialogOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setDialogContent("Purchase Successful!");
          setSuccessDialogOpen(true);
        }

        // Add shopProducts to the inventory
        productDetails.shopProducts?.forEach(({ itemId, amount }) => {
          const itemIndex = userInventoryRef.current.itemDetails.findIndex(
            (item) => item.itemId === itemId.toString()
          );
          if (itemIndex !== -1) {
            userInventoryRef.current.itemDetails[itemIndex].quantity +=
              amount * quantity;
          } else {
            // Add the item if it does not exist in the inventory
            userInventoryRef.current.itemDetails.push({
              itemId: itemId.toString(),
              itemName: "",
              itemLevel: 1,
              itemType: "",
              imageUrl: "",
              quantity: amount * quantity,
            });
          }
        });

        // Deduct shopCurrency or default to bronze coins
        if (
          productDetails.shopCurrency &&
          productDetails.shopCurrency.length > 0
        ) {
          productDetails.shopCurrency.forEach(({ itemId, amount }) => {
            const currencyIndex =
              userInventoryRef.current.itemDetails.findIndex(
                (item) => item.itemId === itemId.toString()
              );
            if (currencyIndex !== -1) {
              userInventoryRef.current.itemDetails[currencyIndex].quantity -=
                amount * quantity;
            }
          });
        } else {
          // Default deduction for bronze coins
          const bronzeIndex = userInventoryRef.current.itemDetails.findIndex(
            (item) => item.itemId === BRONZE_COIN_ITEM_ID
          );
          if (bronzeIndex !== -1) {
            userInventoryRef.current.itemDetails[bronzeIndex].quantity -=
              productDetails.value * quantity;
          }
        }
        updateInventory();
      } else {
        setDialogContent(
          data.message || "An error occurred. Please try again."
        );
        handleSnackbarOpen("Insufficient currency to purchase", "error");
      }
    } catch (error) {
      console.error("Purchase error:", error);
      setDialogContent("An error occurred. Please try again.");
      setOpen(true);
      handleSnackbarOpen("Insufficient currency to purchase", "error");
    }
  };

  return (
    <>
      <Card
        sx={{
          // maxWidth: 345,
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.4)",
        }}
      >
        <CardHeader
          sx={{ textAlign: "center" }}
          title={product.productName}
          subheader={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ paddingRight: ".25rem" }}>{formattedValue}</span>
              <img
                src={currencyImg}
                style={{ height: "25px" }}
                alt="Currency"
              />
            </div>
          }
        />
        <CardMedia
          component="img"
          sx={{
            width: 100,
            maxWidth: 190,
            height: 100,
            maxHeight: 190,
            objectFit: "contain",
            display: "block",
            margin: "auto",
            textAlign: "center",
          }}
          image={product.productImg || "/assets/products/default.png"}
          alt={product.productName}
        />
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {isItemAvailableInInventory || product.shopTag !== "items" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                disabled={isPurchased}
                sx={{
                  backgroundColor: isPurchased ? "grey" : "",
                  "&:hover": { backgroundColor: isPurchased ? "grey" : "" },
                }}
              >
                {isPurchased ? "Purchased" : "Purchase"}
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled
                sx={{
                  backgroundColor: "grey",
                }}
              >
                Unavailable
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Purchase {product.productName}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Quantity"
            type="number"
            fullWidth
            variant="outlined"
            value={quantity}
            onChange={handleChangeQuantity}
          />
          <TextField
            margin="dense"
            label="Total Price"
            type="text"
            fullWidth
            variant="outlined"
            value={totalPrice.toLocaleString()}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={finalizePurchase}
            disabled={quantity <= 0 || quantity > maxQuantity}
          >
            Confirm Purchase
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={autogatherDialogOpen}
        onClose={() => setAutogatherDialogOpen(false)}
      >
        <DialogTitle>Purchase Successful for autogather item!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have successfully purchased the autogather item.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAutogatherDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
      >
        <DialogTitle>Purchase Successful!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have successfully purchased the item/s.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default React.memo(ShopItemCard);
