import React from "react";
import { Route, Navigate } from "react-router-dom";
import SecureLS from "secure-ls";
import AdminLogin from "./Auth/AdminLogin";
import AdminLayout from "./Admin/AdminLayout";
import AdminDashboard from "./Admin/AdminDashboard";
import AdminUsers from "./Admin/AdminUsers";
import AdminLogs from "./Admin/Logs/AdminLogs";
import UserInventory from "./Admin/Users/Inventory/UserInventory";
import UserPlot from "./Admin/Users/Plots/UserPlots";
import UserSkills from "./Admin/Users/Skills/UserSkills";
import UserShops from "./Admin/Users/Shops/UserShops";
import AdminItems from "./Admin/Assets/Items/AdminItems";

const ls = new SecureLS({ isCompression: false });
const adminDetails = ls.get("adminDetails");
const isAdmin = adminDetails && adminDetails.role === "admin";

export const AdminRoutes = () => {
  return [
    <Route key="login" path="/admin/login" element={<AdminLogin />} />,
    <Route
      key="admin"
      path="/admin"
      element={isAdmin ? <AdminLayout /> : <Navigate to="/admin/login" />}
    >
      <Route path="dashboard" element={<AdminDashboard />} />
      <Route path="users" element={<AdminUsers />} />
      <Route path="users/inventory/:userId" element={<UserInventory />} />
      <Route path="users/plots/:userId" element={<UserPlot />} />
      <Route path="users/skills/:userId" element={<UserSkills />} />
      <Route path="users/shops/:userId" element={<UserShops />} />
      <Route path="assets/items" element={<AdminItems />} />
      <Route path="logs" element={<AdminLogs />} /> 
    </Route>,
  ];
};
