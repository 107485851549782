import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const CurrencyFunction = ({
  currencies,
  fromCurrency,
  toCurrency,
  userInventoryRef,
  userId,
  resetTrigger,
}) => {
  const [amount, setAmount] = useState("");
  const [toCurrencyAmount, setToCurrencyAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAmount("");
    setToCurrencyAmount("");
    setErrorMessage("");
  }, [resetTrigger]);

  const calculateToCurrency = (inputAmount) => {
    if (String(fromCurrency) === String(toCurrency)) {
      setErrorMessage("Cannot trade the same currency.");
      setToCurrencyAmount("");
      return;
    }

    const fromCurrencyDetails = currencies.find(
      (currency) => currency.itemId === fromCurrency
    );
    const toCurrencyDetails = currencies.find(
      (currency) => currency.itemId === toCurrency
    );

    if (!fromCurrencyDetails || !toCurrencyDetails) {
      setErrorMessage("Please select valid currencies.");
      return;
    }

    const fromValue = fromCurrencyDetails.currentValue;
    const toValue = toCurrencyDetails.currentValue;

    const conversionRate = fromValue / toValue;
    const calculatedAmount = inputAmount * conversionRate;

    setToCurrencyAmount(calculatedAmount);
  };

  const handleAmountChange = (e) => {
    const inputAmount = e.target.value; // Keep raw input as a string
    setAmount(inputAmount);

    const parsedAmount = parseFloat(inputAmount);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      setToCurrencyAmount("");
      setErrorMessage("Please enter a valid amount.");
      return;
    }

    const userInventory = userInventoryRef.current.itemDetails;
    const fromCurrencyInventory = userInventory.find(
      (item) => String(item.itemId) === String(fromCurrency)
    );

    const maxTradable = fromCurrencyInventory
      ? fromCurrencyInventory.quantity
      : 0;

    if (parsedAmount > maxTradable) {
      setAmount(maxTradable.toString());
      calculateToCurrency(maxTradable);
      setErrorMessage(
        `You cannot trade more than ${maxTradable.toLocaleString()} of ${
          fromCurrencyInventory.itemName
        }`
      );
    } else {
      calculateToCurrency(parsedAmount);
      setErrorMessage("");
    }
  };

  const handleToCurrencyAmountChange = (e) => {
    const inputToAmount = e.target.value; // Keep raw input as a string
    setToCurrencyAmount(inputToAmount);

    const parsedToAmount = parseFloat(inputToAmount);
    if (isNaN(parsedToAmount) || parsedToAmount <= 0) {
      setAmount("");
      setErrorMessage("Please enter a valid amount.");
      return;
    }

    const fromCurrencyDetails = currencies.find(
      (currency) => String(currency.itemId) === String(fromCurrency)
    );
    const toCurrencyDetails = currencies.find(
      (currency) => String(currency.itemId) === String(toCurrency)
    );

    if (!fromCurrencyDetails || !toCurrencyDetails) {
      setErrorMessage("Please select valid currencies.");
      return;
    }

    const toValue = toCurrencyDetails.currentValue;
    const fromValue = fromCurrencyDetails.currentValue;

    const conversionRate = toValue / fromValue;
    const calculatedFromAmount = parsedToAmount * conversionRate;

    const userInventory = userInventoryRef.current.itemDetails;
    const fromCurrencyInventory = userInventory.find(
      (item) => String(item.itemId) === String(fromCurrency)
    );

    const maxTradable = fromCurrencyInventory
      ? fromCurrencyInventory.quantity
      : 0;

    if (calculatedFromAmount > maxTradable) {
      setAmount(maxTradable.toString());
      setToCurrencyAmount((maxTradable / conversionRate).toFixed(2));
      setErrorMessage(
        `You cannot trade more than ${maxTradable.toLocaleString()} of ${
          fromCurrencyInventory.itemName
        }`
      );
    } else {
      setAmount(calculatedFromAmount.toFixed(2));
      setErrorMessage("");
    }
  };

  const handleTrade = async () => {
    if (!amount || amount <= 0 || toCurrencyAmount <= 0) {
      setErrorMessage("Invalid trade. Please check the amounts.");
      return;
    }

    try {
      setIsLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/coin/purchase/${userId}`,
        {
          fromCurrencyId: fromCurrency,
          toCurrencyId: toCurrency,
          amount,
        }
      );

      if (response.status === 200) {
        const { tradedFrom, tradedTo } = response.data;

        // Update inventory in the frontend
        const userInventory = userInventoryRef.current.itemDetails;

        const fromCurrencyDetails = currencies.find(
          (currency) => String(currency.itemId) === String(fromCurrency)
        );
        const toCurrencyDetails = currencies.find(
          (currency) => String(currency.itemId) === String(toCurrency)
        );

        const fromCurrencyInventory = userInventory.find(
          (item) => String(item.itemId) === String(fromCurrency)
        );
        const toCurrencyInventory = userInventory.find(
          (item) => String(item.itemId) === String(toCurrency)
        );

        if (fromCurrencyInventory) {
          fromCurrencyInventory.quantity -= tradedFrom.amount;
        }

        if (toCurrencyInventory) {
          toCurrencyInventory.quantity += tradedTo.amount;
        } else {
          userInventory.push({
            itemId: toCurrency,
            itemName: toCurrencyDetails.name,
            quantity: tradedTo.amount,
            itemType: "currency",
            imageUrl: toCurrencyDetails.imageUrl,
          });
        }

        // SweetAlert for Success
        Swal.fire({
          title: "Trade Successful!",
          html: `
              <div style="display: flex; align-items: center; justify-content: center; gap: 15px;">
                <div>
                  <img src="${fromCurrencyDetails.imageUrl}" alt="${fromCurrencyDetails.name}" style="width: 50px; height: 50px;" />
                  <p>${amount} ${fromCurrencyDetails.name}</p>
                </div>
                <span style="font-size: 24px;">→</span>
                <div>
                  <img src="${toCurrencyDetails.imageUrl}" alt="${toCurrencyDetails.name}" style="width: 50px; height: 50px;" />
                  <p>${toCurrencyAmount} ${toCurrencyDetails.name}</p>
                </div>
              </div>
            `,
          icon: "success",
          confirmButtonText: "Confirm Trade",
        });

        setErrorMessage("");
        setAmount("");
        setToCurrencyAmount("");
      } else {
        throw new Error("Unexpected response status.");
      }
    } catch (error) {
      console.error("Error processing trade:", error);

      // SweetAlert for Error
      Swal.fire({
        title: "Trade Failed",
        text: "An error occurred while processing the trade. Please try again.",
        icon: "error",
        confirmButtonText: "Close",
      });

      setErrorMessage("Failed to process trade. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h6">Trade Currency</Typography>
      <Box
        sx={{ display: "flex", gap: 2, marginBottom: 3, alignItems: "center" }}
      >
        <TextField
          label={`Amount of ${
            currencies.find(
              (currency) => String(currency.itemId) === String(fromCurrency)
            )?.name || "Unknown Currency"
          }`}
          type="number"
          value={amount}
          onChange={handleAmountChange}
          fullWidth
        />
        <Typography>=</Typography>
        <TextField
          label={`Amount of ${
            currencies.find(
              (currency) => String(currency.itemId) === String(toCurrency)
            )?.name || "Unknown Currency"
          }`}
          type="number"
          value={toCurrencyAmount}
          onChange={handleToCurrencyAmountChange}
          fullWidth
        />
      </Box>
      {errorMessage && (
        <Typography color="error" sx={{ marginBottom: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleTrade}
          disabled={!amount || !toCurrencyAmount || !!errorMessage || isLoading}
          sx={{
            width: "50%",
            padding: "12px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Trade"}
        </Button>
      </Box>
    </Box>
  );
};

export default CurrencyFunction;
