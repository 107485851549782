// ImportLogs.js

import React from "react";
import EditInventoryLogs from "./InventoryLogs/EditInventoryLogs";
import DeleteInventoryLogs from "./InventoryLogs/DeleteInventoryLogs";
import EditEmptyFarmPlotLogs from "./FarmLogs/EditEmptyFarmPlotLogs";
import DeleteAddFarmPlotLogs from "./FarmLogs/DeleteAddFarmPlotLogs";
import EditEmptyAnimalPlotLogs from "./AnimalLogs/EditEmptyAnimalPlotLogs";
import DeleteAddAnimalPlotLogs from "./AnimalLogs/DeleteAddAnimalPlotLogs";
import EditSkillLogs from "./SkillLogs/EditSkillLogs";
import AddRemoveSkillLogs from "./SkillLogs/AddRemoveSkillLogs";
import AddRemoveShopLogs from "./ShopLogs/AddRemoveShopLogs";
import EditUserInfoLogs from "./InfoLogs/EditUserInfoLogs";

export const renderLogComponent = (log) => {
  switch (log.transactType) {
    case "Edit Inventory":
    case "Add Item":
      return <EditInventoryLogs key={log._id} log={log} />;
    case "Delete Item":
      return <DeleteInventoryLogs key={log._id} log={log} />;
    case "Edit Farm Plot":
    case "Empty Farm Plot":
      return <EditEmptyFarmPlotLogs key={log._id} log={log} />;
    case "Add Farm Plot":
    case "Delete Farm Plot":
      return <DeleteAddFarmPlotLogs key={log._id} log={log} />;
    case "Edit Animal Plot":
    case "Empty Animal Plot":
      return <EditEmptyAnimalPlotLogs key={log._id} log={log} />;
    case "Add Animal Plot":
    case "Delete Animal Plot":
      return <DeleteAddAnimalPlotLogs key={log._id} log={log} />;
    case "Edit Skill":
      return <EditSkillLogs key={log._id} log={log} />;
    case "Add Skill":
    case "Remove Skill":
      return <AddRemoveSkillLogs key={log._id} log={log} />;
    case "Add Shop Item":
    case "Remove Shop Item":
      return <AddRemoveShopLogs key={log._id} log={log} />;
      case "Edit User Info":
        return <EditUserInfoLogs key={log._id} log={log} />;
    default:
      return null;
  }
};
