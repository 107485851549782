import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CraftItemCardsSlider from "./CraftItemCardSlider";

const CraftTab = ({
  items,
  currentItems,
  craftItems,
  lockedItems, // Add this prop to pass locked items
  onCraft,
  onBackToSkills,
  userInventory,
  setUserInventory,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filter available craft items and locked items separately based on the search term
  const filteredItems = craftItems.filter(
    (item) =>
      item.status === "craft" && item.name.toLowerCase().includes(searchTerm)
  );

  // Filter items that have a status of "craft"
  const filteredLockedItems = lockedItems.filter(
    (item) =>
      item.status === "craft" && item.name.toLowerCase().includes(searchTerm)
  );

  // Filter the remaining items (those that do not have a status of "craft")
  const filteredLockedOtherItems = lockedItems.filter(
    (item) =>
      item.status !== "craft" && item.name.toLowerCase().includes(searchTerm)
  );

  return (
    <div
      style={{
        padding: "0 20px",
        margin: "auto",
        maxWidth: "350px",
        marginBottom: "10px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2 style={{ color: "green" }}>Craft</h2>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <IconButton onClick={onBackToSkills}>
          <ArrowBack />
        </IconButton>
        <TextField
          label="Search Items"
          variant="outlined"
          margin="normal"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { borderRadius: "20px" },
          }}
          style={{ width: "266px", marginLeft: "10px" }}
        />
      </div>
      <div style={{ marginTop: "7px" }}>
        {filteredItems.length > 0 || filteredLockedItems.length > 0 ? (
          <CraftItemCardsSlider
            items={filteredItems}
            lockedItems={filteredLockedItems}
            currentItems={currentItems}
            otherLockedItems={filteredLockedOtherItems}
            onCraft={onCraft}
            userInventory={userInventory}
            setUserInventory={setUserInventory}
            craftItems={filteredItems}
            allItems={[...items, ...lockedItems]}
          />
        ) : (
          <Typography textAlign="center" mt={2}>
            No items available or no match found.
          </Typography>
        )}
      </div>
    </div>
  );
};

export default CraftTab;
