import React from "react";
import { Box, TextField } from "@mui/material";

const ItemProbabilityOptions = ({ data, onChange }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        label="Probability"
        type="number"
        value={data.probability || ""}
        onChange={(e) => onChange("probability", parseFloat(e.target.value))}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Amount Min"
        type="number"
        value={data.amountProbability?.min || ""}
        onChange={(e) =>
          onChange("amountProbability.min", parseFloat(e.target.value))
        }
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Amount Max"
        type="number"
        value={data.amountProbability?.max || ""}
        onChange={(e) =>
          onChange("amountProbability.max", parseFloat(e.target.value))
        }
        fullWidth
      />
    </Box>
  );
};

export default ItemProbabilityOptions;
