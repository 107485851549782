// src/components/DeleteLogs.js
import React from "react";
import { Typography, Card, CardContent } from "@mui/material";

const DeleteInventoryLogs = ({ log }) => {
  return (
    <Card sx={{ marginBottom: 2, boxShadow: "0px 3px 10px rgba(0,0,0,0.2)" }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          {new Date(log.date).toLocaleString()}
        </Typography>
        <Typography variant="h6">{log.transactType}</Typography>
        <Typography variant="body1">
          {log.userName} <small>({log.userId})</small>
        </Typography>
        <Typography variant="body2">
          {log.deleteItemLog.itemName &&
            `Item Name: ${log.deleteItemLog.itemName} `}
          <small>({log.deleteItemLog.itemId})</small>
        </Typography>
        <Typography variant="caption">
          Admin: {log.adminName} <small>({log.adminId})</small>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DeleteInventoryLogs;
