import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AnimalPlotsDialog from "./AnimalPlotsDialog";
import SecureLS from "secure-ls";

const AnimalPlots = ({ plot, animals, onPlotUpdate }) => {
  const { userId } = useParams();
  const { plotNum, animalDetails } = plot;
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isEmptying, setIsEmptying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const plotNumber = plotNum;

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleSaveEditDialog = async (newDetails) => {
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/animal-edit/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber,
            newDetails: {
              ...newDetails,
              animalName: newDetails.addedAnimalId
                ? animals.find(
                    (animal) => animal.itemId === newDetails.addedAnimalId
                  )?.name
                : "Unknown Animal",
              animalImg: newDetails.addedAnimalId
                ? animals.find(
                    (animal) => animal.itemId === newDetails.addedAnimalId
                  )?.imageUrl
                : null,
            },
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        onPlotUpdate(); // Refresh the entire list of plots
        setIsEditDialogOpen(false);
      } else {
        console.error("Failed to update the plot.");
      }
    } catch (error) {
      console.error("Error updating the plot:", error);
    }
  };

  const handleEmptyPlot = async () => {
    setIsEmptying(true);
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/animal-empty/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber,
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        onPlotUpdate(); // Refresh the entire list of plots
        setIsEmptying(false);
      } else {
        console.error("Failed to empty the plot.");
        setIsEmptying(false);
      }
    } catch (error) {
      console.error("Error emptying the plot:", error);
      setIsEmptying(false);
    }
  };

  const handleDeletePlot = async () => {
    setIsDeleting(true);
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/animal-delete/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber,
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        await onPlotUpdate(); // Refetch the entire list of plots
        setIsDeleting(false);
      } else {
        console.error("Failed to delete the plot.");
        setIsDeleting(false);
      }
    } catch (error) {
      console.error("Error deleting the plot:", error);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          fontFamily: '"Pixelify Sans", sans-serif',
          height: 200,
        }}
      >
        <Box
          sx={{
            width: 180,
            height: 180,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            backgroundColor: animalDetails.animalImg
              ? "transparent"
              : "#f0f0f0",
          }}
        >
          {animalDetails.animalImg ? (
            <img
              src={animalDetails.animalImg}
              alt={animalDetails.animalName}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <Typography
              variant="h6"
              sx={{ fontFamily: '"Pixelify Sans", sans-serif', color: "#999" }}
            >
              N/A
            </Typography>
          )}
        </Box>
        <CardContent sx={{ flex: 1 }}>
          <Typography
            variant="h510"
            sx={{
              fontFamily: '"Pixelify Sans", sans-serif',
              fontWeight: "bold",
              mb: 1,
            }}
          >
            Plot Number: {plotNumber} - {animalDetails.animalName || "None"}
          </Typography>
          <Typography variant="body2">
            Life Stage: {animalDetails.currentLifeStage}
          </Typography>
          <Typography variant="body2">
            Placed: {new Date(animalDetails.datePlaced).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Next Feeding: {new Date(animalDetails.dateToFeed).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Harvest Time:{" "}
            {new Date(animalDetails.dateToHarvest).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Maturity Time:{" "}
            {new Date(animalDetails.dateToMaturity).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Death Time: {new Date(animalDetails.dateToDeath).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Affection Level: {animalDetails.affectionLvl}
          </Typography>
          <Typography variant="body2">
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "center" }}
            >
              Fed: {animalDetails.isFed ? "Yes" : "No"}{" "}
              {animalDetails.isFed && (
                <img
                  src={animalDetails.feedImg}
                  alt="Feed"
                  style={{ width: 20, height: 20, marginLeft: 4 }}
                />
              )}
            </Box>
          </Typography>
        </CardContent>
        <Stack direction="column" spacing={1} sx={{ pr: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#a6c9f2",
              color: "black",
              fontSize: "0.7rem",
              padding: "4px 8px",
            }}
            startIcon={<EditIcon />}
            onClick={handleEditClick}
          >
            Edit Plot
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#444444", // Pastel Black
              color: "white",
              fontSize: "0.7rem",
              padding: "4px 8px",
            }}
            startIcon={<RemoveCircleOutlineIcon />} // Icon for Empty Plot
            onClick={handleEmptyPlot} // Handle empty plot
            disabled={isEmptying || !animalDetails.animalImg} // Disable if emptying or animalImg is not available
          >
            Empty Plot
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: "#f5a6a6",
              color: "black",
              fontSize: "0.7rem",
              padding: "4px 8px",
            }}
            startIcon={<DeleteIcon />}
            onClick={handleDeletePlot} // Handle delete plot
            disabled={isDeleting} // Disable button during loading
          >
            Delete Plot
          </Button>
        </Stack>
      </Card>

      {/* Edit Animal Plot Dialog */}
      <AnimalPlotsDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        plot={plot}
        animals={animals} // Pass animals to the dialog
        onSave={handleSaveEditDialog}
      />
    </>
  );
};

export default AnimalPlots;
