import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const EditFarmPlotDialog = ({ open, onClose, plot, onSave, seeds }) => {
  const [originalDetails, setOriginalDetails] = useState({});
  const [newDetails, setNewDetails] = useState({
    seedPlanted: plot.details.seedPlanted || "",
    dateToHarvest: plot.details.dateToHarvest
      ? dayjs(plot.details.dateToHarvest)
      : null,
    dateToWilt: plot.details.dateToWilt ? dayjs(plot.details.dateToWilt) : null,
    datePlanted: plot.details.datePlanted
      ? dayjs(plot.details.datePlanted)
      : null,
    isWatered: plot.details.isWatered || false,
    dateWatered: plot.details.dateWatered
      ? dayjs(plot.details.dateWatered)
      : null,
    waterInterval: plot.details.waterInterval || 24,
    productStatus: plot.details.productStatus || "none",
    harvestAmount: plot.details.harvestAmount || 0,
    typeOfWater: plot.details.typeOfWater || "",
    waterAmount: plot.details.waterAmount || 0,
    harvestProduct: plot.details.harvestProduct?.productId || "",
    wiltProduct: plot.details.wiltProduct || "",
  });

  // Store the original plot details when the component mounts
  useEffect(() => {
    setOriginalDetails({
      seedPlanted: plot.details.seedPlanted || "",
      dateToHarvest: plot.details.dateToHarvest
        ? dayjs(plot.details.dateToHarvest)
        : null,
      dateToWilt: plot.details.dateToWilt
        ? dayjs(plot.details.dateToWilt)
        : null,
      datePlanted: plot.details.datePlanted
        ? dayjs(plot.details.datePlanted)
        : null,
      isWatered: plot.details.isWatered || false,
      dateWatered: plot.details.dateWatered
        ? dayjs(plot.details.dateWatered)
        : null,
      waterInterval: plot.details.waterInterval || 24,
      productStatus: plot.details.productStatus || "none",
      harvestAmount: plot.details.harvestAmount || 0,
      typeOfWater: plot.details.typeOfWater || "",
      waterAmount: plot.details.waterAmount || 0,
      harvestProduct: plot.details.harvestProduct?.productId || "",
      wiltProduct: plot.details.wiltProduct || "",
    });
  }, [plot.details]);

  // Prefill the details based on the selected seed
  useEffect(() => {
    if (newDetails.seedPlanted) {
      const selectedSeed = seeds.find(
        (seed) => seed.itemId === newDetails.seedPlanted
      );
      if (selectedSeed) {
        const currentDate = dayjs();
        const harvestTime = currentDate
          .add(selectedSeed.itemFarm.dateToHarvest.minutes, "minutes")
          .add(selectedSeed.itemFarm.dateToHarvest.hours, "hours")
          .add(selectedSeed.itemFarm.dateToHarvest.days, "days");
        const wiltTime = harvestTime
          .add(selectedSeed.itemFarm.dateToWilt.minutes, "minutes")
          .add(selectedSeed.itemFarm.dateToWilt.hours, "hours")
          .add(selectedSeed.itemFarm.dateToWilt.days, "days");

        const dateWatered = currentDate.subtract(3, "hours");

        setNewDetails((prevDetails) => ({
          ...prevDetails,
          datePlanted: prevDetails.datePlanted || currentDate, // Only set if datePlanted is not already set
          dateToHarvest: harvestTime,
          dateToWilt: wiltTime,
          harvestProduct: selectedSeed.itemFarm.harvestProduct,
          wiltProduct: selectedSeed.itemFarm.wiltProduct,
          typeOfWater: selectedSeed.itemFarm.typeOfWater,
          waterAmount: selectedSeed.itemFarm.waterAmount,
          dateWatered: dateWatered, // Set to three hours before current date
          productStatus: "growing",
        }));
      }
    }
  }, [newDetails.seedPlanted, seeds]);

  // Handle the change in form fields
  const handleChange = (field) => (event) => {
    setNewDetails({
      ...newDetails,
      [field]: event.target.value,
    });
  };

  // Handle the change in date fields
  const handleDateChange = (field) => (date) => {
    setNewDetails({
      ...newDetails,
      [field]: date,
    });
  };

  // Save the updated plot details
  const handleSave = () => {
    const updatedDetails = {
      ...newDetails,
      dateToHarvest: newDetails.dateToHarvest
        ? newDetails.dateToHarvest.toISOString()
        : null,
      dateToWilt: newDetails.dateToWilt
        ? newDetails.dateToWilt.toISOString()
        : null,
      datePlanted: newDetails.datePlanted
        ? newDetails.datePlanted.toISOString()
        : null,
      dateWatered: newDetails.dateWatered
        ? newDetails.dateWatered.toISOString()
        : null,
    };
    onSave(updatedDetails);
  };

  // Clear all the fields
  const handleClearAll = () => {
    setNewDetails({
      seedPlanted: "",
      dateToHarvest: null,
      dateToWilt: null,
      datePlanted: null,
      isWatered: false,
      dateWatered: null,
      waterInterval: 24,
      productStatus: "none",
      harvestAmount: 0,
      typeOfWater: "",
      waterAmount: 0,
      harvestProduct: "",
      wiltProduct: "",
    });
  };

  // Reset to original details
  const handleReset = () => {
    setNewDetails(originalDetails);
  };

  const isSaveDisabled =
    !newDetails.seedPlanted ||
    Object.values(newDetails).some((value) => value === null || value === "");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Farm Plot</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Seed Planted"
                value={newDetails.seedPlanted}
                onChange={handleChange("seedPlanted")}
                fullWidth
                sx={{ mt: 1 }}
              >
                {seeds.map((seed) => (
                  <MenuItem key={seed.itemId} value={seed.itemId}>
                    <img
                      src={seed.imageUrl}
                      alt={seed.name}
                      style={{ width: 20, marginRight: 8 }}
                    />
                    {seed.name} ({seed.itemId})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Date Planted"
                value={newDetails.datePlanted}
                onChange={handleDateChange("datePlanted")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Harvest Time"
                value={newDetails.dateToHarvest}
                onChange={handleDateChange("dateToHarvest")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Wilt Time"
                value={newDetails.dateToWilt}
                onChange={handleDateChange("dateToWilt")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                label="Date Watered"
                value={newDetails.dateWatered}
                onChange={handleDateChange("dateWatered")}
                renderInput={(params) => <TextField fullWidth {...params} />}
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Harvest Product ID"
                value={newDetails.harvestProduct}
                onChange={handleChange("harvestProduct")}
                fullWidth
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Wilt Product ID"
                value={newDetails.wiltProduct}
                onChange={handleChange("wiltProduct")}
                fullWidth
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Harvest Amount"
                value={newDetails.harvestAmount}
                onChange={handleChange("harvestAmount")}
                type="number"
                fullWidth
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Product Status"
                value={newDetails.productStatus}
                onChange={handleChange("productStatus")}
                fullWidth
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Water Interval (hours)"
                value={newDetails.waterInterval}
                onChange={handleChange("waterInterval")}
                type="number"
                fullWidth
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Water Type"
                value={newDetails.typeOfWater}
                onChange={handleChange("typeOfWater")}
                fullWidth
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Water Amount"
                value={newDetails.waterAmount}
                onChange={handleChange("waterAmount")}
                type="number"
                fullWidth
                disabled={!newDetails.seedPlanted}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Watered"
                value={newDetails.isWatered}
                onChange={handleChange("isWatered")}
                select
                fullWidth
                disabled={!newDetails.seedPlanted}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Clear all Fields" arrow>
            <Button onClick={handleClearAll} color="warning">
              Clear All
            </Button>
          </Tooltip>
          <Tooltip title="Restore Previous Field" arrow>
            <Button
              onClick={handleReset}
              color="secondary"
              sx={{ color: "red" }}
            >
              Reset
            </Button>
          </Tooltip>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EditFarmPlotDialog;
