import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SecureLS from "secure-ls";
import {
  Box,
  Typography,
  TextField,
  Tab,
  Tabs,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
}

const styles = {
  typographyStyle: {
    fontFamily: '"Pixelify Sans", sans-serif',
  },
};

function MarketTab({ userInventoryRef, items }) {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTitle, setLoadingTitle] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [listings, setListings] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(10);
  const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemSearchTerm, setItemSearchTerm] = useState("");
  const [currencySearchTerm, setCurrencySearchTerm] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [purchaseListingId, setPurchaseListingId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const npcListingsRef = useRef([]);

  // SecureLS UserId
  const ls = new SecureLS({ isCompression: false });
  const userDetails = ls.get("userDetails");
  const userId = userDetails ? userDetails.userId : null;
  const npcUserId = "65f57614f68640734c3ebe94";

  // Create Listing
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isSelectItemDialogOpen, setIsSelectItemDialogOpen] = useState(false);
  const [isSelectCurrencyDialogOpen, setIsSelectCurrencyDialogOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [itemAmount, setItemAmount] = useState("");
  const [itemAmountError, setItemAmountError] = useState("");
  const [itemCost, setItemCost] = useState("");
  const [currencyId, setCurrencyId] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencyImage, setCurrencyImage] = useState("");
  const [sellerComment, setSellerComment] = useState("");
  const [selectedComment, setSelectedComment] = useState("");
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);

  // Purchase Listing
  const [selectedListingDetails, setSelectedListingDetails] = useState(null);

  useEffect(() => {
    fetchListings();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let interval;

    // This function will start the interval when handleRefreshListings is called
    const startInterval = () => {
      interval = setInterval(() => {
        if (lastFetchTime && Date.now() - lastFetchTime >= 10000) {
          setRefreshButtonDisabled(false);
          clearInterval(interval);
        }
      }, 1000);
    };

    if (refreshButtonDisabled) {
      startInterval();
    }

    return () => clearInterval(interval);
  }, [lastFetchTime, refreshButtonDisabled]);

  const fetchListings = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/market/fetch-listings`
      );

      const sortedListings = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setListings(sortedListings);
      setIsLoading(false);
    } catch (error) {
      console.error(
        "Error fetching listings:",
        error.response?.data || error.message
      );
      setIsLoading(false);
    }
  };

  const handleRefreshListings = async () => {
    try {
      await fetchListings();
      setLastFetchTime(Date.now());
      setRefreshButtonDisabled(true);
      setRemainingTime(10);
      handleSnackbarOpen("Listings refreshed successfully", "success");
    } catch (error) {
      console.error(
        "Error refreshing listings:",
        error.response?.data || error.message
      );
      handleSnackbarOpen("Error refreshing listings", "error");
    }
  };

  const handleOpenCreateDialog = () => {
    setIsCreateDialogOpen(true);
    setSelectedItem(null);
    setSelectedItemDetails(null);
    setItemAmount("");
    setItemCost("");
    setCurrencyId("");
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    setSelectedItem(null);
    setSelectedItemDetails(null);
    setItemAmount("");
    setItemCost("");
    setCurrencyId("");
  };

  const handleOpenSelectItemDialog = () => {
    setIsSelectItemDialogOpen(true);
  };

  const handleCloseSelectItemDialog = () => {
    setIsSelectItemDialogOpen(false);
    setItemSearchTerm("");
  };

  const handleOpenSelectCurrencyDialog = () => {
    setIsSelectCurrencyDialogOpen(true);
  };

  const handleCloseSelectCurrencyDialog = () => {
    setIsSelectCurrencyDialogOpen(false);
    setCurrencySearchTerm("");
  };

  const handleCurrencySelect = (currency) => {
    setCurrencyId(currency.itemId);
    setCurrencyName(currency.name);
    setCurrencyImage(currency.imageUrl);
    handleCloseSelectCurrencyDialog();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmitListing = async () => {
    try {
      setLoading(true);
      setLoadingTitle("Create Listing");
      setLoadingMessage("Adding listing to marketplace...");

      const parsedAmount = parseInt(itemAmount, 10);
      if (isNaN(parsedAmount) || parsedAmount < 1) {
        setItemAmountError(
          "Amount must be a number greater than or equal to 1"
        );
        return;
      }

      const payload = {
        itemId: selectedItem,
        itemAmount: parseInt(itemAmount, 10),
        itemCost: parseInt(itemCost, 10),
        coinId: currencyId,
        sellerComment,
      };
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/market/${userId}/create-listing`,
        payload
      );

      // Deduct the listed amount from the local userInventoryRef for the selected item
      const itemIndex = userInventoryRef.current.itemDetails.findIndex(
        (item) => item.itemId === selectedItem
      );
      if (itemIndex > -1) {
        const updatedItem = {
          ...userInventoryRef.current.itemDetails[itemIndex],
        };
        updatedItem.quantity = Math.max(
          updatedItem.quantity - parseInt(itemAmount, 10),
          0
        ); // Ensure quantity doesn't go negative
        userInventoryRef.current.itemDetails[itemIndex] = updatedItem;
      }

      handleCloseCreateDialog();
      fetchListings();
      handleSnackbarOpen("Listing created successfully", "success");
    } catch (error) {
      console.error(
        "Error creating listing:",
        error.response?.data || error.message
      );
      handleSnackbarOpen("Error creating listing", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item.itemId);
    setSelectedItemDetails(item); // Save the full item details
    setItemAmount(item.quantity.toString()); // Pre-populate with the item's available amount
    handleCloseSelectItemDialog();
  };

  const clearSelectedItem = () => {
    setSelectedItem(null);
    setSelectedItemDetails(null); // Clear the selected item details
    setItemAmount(""); // Reset the item amount
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePurchase = (listingId, itemDetails) => {
    // Check if itemDetails is provided
    if (itemDetails) {
      setSelectedListingDetails(itemDetails);
    }
    setPurchaseListingId(listingId);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmPurchase = async () => {
    const currencyDetails = userInventoryRef.current.itemDetails.find(
      (item) => item.itemId === selectedListingDetails.coinId.toString()
    );

    if (
      selectedListingDetails.itemCostTax === 0 ||
      (currencyDetails &&
        currencyDetails.quantity >= selectedListingDetails.itemCostTax)
    ) {
      try {
        setLoading(true);
        setLoadingTitle("Purchase Listing");
        setLoadingMessage("Purchasing listing...");

        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/market/${userId}/purchase-listing`,
          { listingId: purchaseListingId }
        );

        // Add the purchased item and deduct currency from the local userInventoryRef
        const updatedItemDetails = userInventoryRef.current.itemDetails.map(
          (item) => {
            if (item.itemId === selectedListingDetails.itemId.toString()) {
              // Handle adding purchased item
              return {
                ...item,
                quantity: item.quantity + selectedListingDetails.itemAmount,
              };
            } else if (
              item.itemId === selectedListingDetails.coinId.toString()
            ) {
              // Handle deducting currency
              return {
                ...item,
                quantity: item.quantity - selectedListingDetails.itemCostTax,
              };
            }
            return item;
          }
        );

        userInventoryRef.current.itemDetails = updatedItemDetails;

        fetchListings(); // Refresh listings after purchase
        setConfirmationDialogOpen(false);
        handleSnackbarOpen("Purchase successful", "success");
      } catch (error) {
        console.error(
          "Error purchasing listing:",
          error.response?.data || error.message
        );
        handleSnackbarOpen("Insufficient currency to purchase", "error");
      } finally {
        setLoading(false);
      }
    } else {
      // If the user doesn't have enough currency, display an error
      handleSnackbarOpen("Insufficient currency to purchase", "error");
    }
  };

  const handleCancelListing = async (listingId) => {
    try {
      setLoading(true);
      setLoadingTitle("Cancel Listing");
      setLoadingMessage("Cancelling listing...");

      const cancelledListing = listings.find(
        (listing) => listing._id === listingId
      );
      if (cancelledListing) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/market/${userId}/cancel-listing`,
          { listingId }
        );

        // Add the cancelled item back to the local userInventoryRef
        const itemIndex = userInventoryRef.current.itemDetails.findIndex(
          (item) => item.itemId === cancelledListing.itemId.toString()
        );

        if (itemIndex > -1) {
          const updatedItem = {
            ...userInventoryRef.current.itemDetails[itemIndex],
          };
          updatedItem.quantity += cancelledListing.itemAmount;
          userInventoryRef.current.itemDetails[itemIndex] = updatedItem;
        } else {
          // If the item wasn't found, create a new entry
          userInventoryRef.current.itemDetails.push({
            itemId: cancelledListing.itemId,
            itemName: cancelledListing.itemName,
            quantity: cancelledListing.itemAmount,
            imageUrl: cancelledListing.itemImg,
          });
        }

        fetchListings();
        handleSnackbarOpen("Listing cancelled successfully", "success");
      } else {
        handleSnackbarOpen("Listing not found", "error");
      }
    } catch (error) {
      console.error(
        "Error cancelling listing:",
        error.response?.data || error.message
      );
      handleSnackbarOpen("Error cancelling listing", "error");
    } finally {
      setLoading(false);
    }
  };

  // Filter listings based on the search term and tab
  const filteredListings = listings.filter(
    (listing) =>
      listing.userId._id !== npcUserId &&
      listing.itemName?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (activeTab === 0 || (activeTab === 1 && listing.userId._id === userId))
  );

  const filteredNpcListings = listings.filter(
    (listing) =>
      listing.userId._id === npcUserId &&
      listing.itemName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredItems = userInventoryRef.current.itemDetails.filter((item) =>
    item.itemName?.toLowerCase().includes(itemSearchTerm.toLowerCase())
  );

  const filteredCurrencies = items.filter((item) =>
    item.name?.toLowerCase().includes(currencySearchTerm.toLowerCase())
  );

  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" gutterBottom style={styles.typographyStyle}>
        Marketplace
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenCreateDialog}
          sx={{
            backgroundColor: "#8B4513",
            color: "white",
            "&:hover": {
              backgroundColor: "#D2B48C",
              color: "#8B4513",
            },
          }}
        >
          Create Listing
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRefreshListings}
          disabled={refreshButtonDisabled}
          sx={{
            marginLeft: "10px",
            backgroundColor: "#8B4513",
            color: "white",
            "&:hover": {
              backgroundColor: "#D2B48C",
              color: "#8B4513",
            },
          }}
        >
          {refreshButtonDisabled
            ? `${formatTime(
                Math.ceil((lastFetchTime + 10000 - Date.now()) / 1000)
              )}`
            : "Refresh Listings"}
        </Button>
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        label="Search for listings"
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        aria-label="marketplace-tabs"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#8B4513",
          },
        }}
      >
        <Tab
          label="All Listings"
          sx={{ "&.Mui-selected": { color: "#8B4513" } }}
        />
        <Tab
          label="My Listings"
          sx={{ "&.Mui-selected": { color: "#8B4513" } }}
        />
        <Tab
          label="NPC Market"
          sx={{ "&.Mui-selected": { color: "#8B4513" } }}
        />
      </Tabs>
      <Box sx={{ pt: 2 }}>
        {isLoading ? (
          <CircularProgress sx={{ color: "#8B4513" }} />
        ) : (
          (activeTab === 2 ? filteredNpcListings : filteredListings).map(
            (listing) => (
              <Card
                key={listing._id}
                sx={{
                  mb: 2,
                  width: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderColor: "#8B4513",
                  borderWidth: "4px",
                  borderStyle: "solid",
                  bgcolor: "#ffeed8",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar src={listing.itemImg} sx={{ marginRight: 0.5 }} />
                    <Typography variant="h5" style={styles.typographyStyle}>
                      {listing.itemName}
                    </Typography>
                  </Box>
                  <Typography variant="subtitle1">
                    Seller: {listing.sellerName}
                  </Typography>
                  {/* Optionally display seller's profile image */}
                  <Typography variant="body2">
                    Amount: {listing.itemAmount.toLocaleString()}
                  </Typography>
                  {listing.sellerComment && (
                    <Typography variant="body2" style={{ fontStyle: "italic" }}>
                      {listing.sellerComment.length > 50
                        ? `"${listing.sellerComment.substring(0, 47)}`
                        : `"${listing.sellerComment}"`}
                      {listing.sellerComment.length > 50 && (
                        <Button
                          size="small"
                          onClick={() => {
                            setSelectedComment(listing.sellerComment);
                            setIsCommentDialogOpen(true);
                          }}
                          style={{
                            textTransform: "none",
                            padding: 0,
                            minWidth: "auto",
                          }}
                        >
                          ..."
                        </Button>
                      )}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Card
                      variant="outlined"
                      sx={{
                        p: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#e0d6c8",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        sx={{ fontWeight: "bold" }}
                      >
                        Price:
                      </Typography>
                      {listing.itemCostTax === 0 ? (
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Free
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            {Math.floor(listing.itemCostTax).toLocaleString()}
                          </Typography>
                          {listing.itemCostTax > 0 && (
                            <Tooltip title={listing.coinName || ""}>
                              <Avatar
                                src={listing.coinImageUrl}
                                sx={{
                                  width: 24,
                                  height: 24,
                                  ml: 0.5,
                                  verticalAlign: "middle",
                                }}
                              />
                            </Tooltip>
                          )}
                          {listing.itemCostTax % 1 > 0 && (
                            <>
                              <Typography variant="body2" sx={{ mx: 1 }}>
                                |
                              </Typography>
                              <Typography variant="body2">
                                {(listing.itemCostTax % 1).toFixed(2)}
                              </Typography>
                              <Tooltip title="Bronze Coin">
                                <Avatar
                                  src="/assets/items/currencies/bronze-coin2.webp"
                                  alt="Bronze Coin"
                                  sx={{ width: 24, height: 24, ml: 0.5 }}
                                />
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                    </Card>
                    {(() => {
                      const item = items.find(
                        (item) => item.itemId === listing.itemId
                      );

                      const recentTradeValues =
                        item?.stats?.recentTradeValue || [];

                      const avgRTP =
                        recentTradeValues.length > 0
                          ? recentTradeValues.reduce(
                              (acc, value) => acc + value,
                              0
                            ) / recentTradeValues.length
                          : 0;

                      if (avgRTP > 0) {
                        const percentageDifference =
                          ((listing.itemCostTax - avgRTP) / avgRTP) * 100;

                        const isIncrease = percentageDifference > 0;
                        const color = isIncrease ? "red" : "green";

                        return (
                          <Typography
                            variant="body2"
                            sx={{
                              color,
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            {Math.abs(percentageDifference).toFixed(2)}%
                            {isIncrease ? " ↑" : " ↓"}
                          </Typography>
                        );
                      }

                      return null;
                    })()}
                  </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: "center", width: "100%" }}>
                  {userId === listing.userId._id ? (
                    <Button
                      size="small"
                      onClick={() => handleCancelListing(listing._id)}
                      color="error"
                    >
                      Cancel Listing
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={() => handlePurchase(listing._id, listing)}
                      color="success"
                    >
                      Purchase
                    </Button>
                  )}
                </CardActions>
              </Card>
            )
          )
        )}
      </Box>

      {/* Dialog for creating a listing */}
      <Dialog
        open={isCreateDialogOpen}
        onClose={handleCloseCreateDialog}
        PaperProps={{
          sx: {
            border: "2px solid #D2B48C",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Create a Listing</DialogTitle>
        <DialogContent>
          {selectedItemDetails ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <Typography variant="subtitle1">Item Selected: </Typography>
              <Avatar src={selectedItemDetails.imageUrl} />
              <Typography>{selectedItemDetails.itemName}</Typography>
              <IconButton onClick={clearSelectedItem} size="small">
                <CloseIcon color="error" />
              </IconButton>
            </Box>
          ) : (
            <Button
              onClick={handleOpenSelectItemDialog}
              fullWidth
              sx={{ color: "#8B4513" }}
            >
              Choose Item
            </Button>
          )}
          <TextField
            label="Item Amount"
            type="number"
            fullWidth
            value={itemAmount}
            onChange={(e) => {
              const inputAmount = e.target.value.trim();
              const maxAmount = selectedItemDetails
                ? parseInt(selectedItemDetails.quantity, 10)
                : 0;

              if (
                inputAmount === "" ||
                isNaN(inputAmount) ||
                parseInt(inputAmount, 10) < 1
              ) {
                setItemAmountError(
                  "Amount must be a number greater than or equal to 1"
                );
                setItemAmount("");
              } else {
                setItemAmountError("");
                setItemAmount(
                  Math.min(parseInt(inputAmount, 10), maxAmount).toString()
                );
              }
            }}
            margin="dense"
            error={!!itemAmountError}
            helperText={itemAmountError}
          />
          {currencyId ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mb: 2,
                mt: 2,
              }}
            >
              <Typography variant="subtitle1">Currency Selected: </Typography>
              <img
                src={currencyImage}
                alt={currencyName}
                style={{ width: 35, height: 35 }}
              />
              <Typography variant="body1">{currencyName}</Typography>
              <IconButton onClick={() => setCurrencyId("")} size="small">
                <CloseIcon color="error" />
              </IconButton>
            </Box>
          ) : (
            <Button
              onClick={handleOpenSelectCurrencyDialog}
              fullWidth
              sx={{ color: "#8B4513" }}
            >
              Choose Currency
            </Button>
          )}
          <TextField
            label="Cost per Item"
            type="number"
            fullWidth
            value={itemCost}
            onChange={(e) => {
              const cost = parseInt(e.target.value, 10);
              if (!isNaN(cost) && cost >= 0) {
                setItemCost(cost.toString());
              } else {
                setItemCost("");
              }
            }}
            margin="dense"
            helperText={
              <>
                Tip: If you want to offer this for free, set it to '0'.
                <br />
                <span style={{ marginLeft: "2em" }}>
                  Subject to 2% Listing Tax.
                </span>
              </>
            }
          />
          <TextField
            label="Seller Comment (Optional)"
            type="text"
            fullWidth
            value={sellerComment}
            onChange={(e) => setSellerComment(e.target.value)}
            margin="dense"
            inputProps={{ maxLength: 100 }}
            helperText="Max 100 characters"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog} color="error">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitListing}
            disabled={
              !!itemAmountError ||
              !selectedItemDetails ||
              currencyId === "" ||
              itemCost === ""
            }
            sx={{ color: "green" }}
          >
            Create Listing
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for selecting an item from user inventory */}
      <Dialog
        open={isSelectItemDialogOpen}
        onClose={handleCloseSelectItemDialog}
        PaperProps={{
          sx: {
            border: "2px solid #D2B48C",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Select an Item</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            label="Search for items"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setItemSearchTerm(e.target.value)}
          />
          <List>
            {filteredItems.map((item) => (
              <ListItem
                button
                onClick={() => handleItemSelect(item)}
                key={item.itemId}
              >
                <ListItemAvatar>
                  <Avatar src={item.imageUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.itemName}
                  secondary={`Amount: ${item.quantity}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectItemDialog} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for selecting a currency */}
      <Dialog
        open={isSelectCurrencyDialogOpen}
        onClose={handleCloseSelectCurrencyDialog}
        PaperProps={{
          sx: {
            border: "2px solid #D2B48C",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Select a Currency</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            label="Search for currencies"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setCurrencySearchTerm(e.target.value)}
          />
          <List>
            {filteredCurrencies.map((currency) => (
              <ListItem
                button
                onClick={() => handleCurrencySelect(currency)}
                key={currency.itemId}
              >
                <ListItemAvatar>
                  <Avatar src={currency.imageUrl} />
                </ListItemAvatar>
                <ListItemText primary={currency.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectCurrencyDialog} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Purchase Confirmation Dialog */}
      {selectedListingDetails && (
        <Dialog
          open={confirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
          PaperProps={{
            sx: {
              border: "2px solid #D2B48C",
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle>Confirm Purchase</DialogTitle>
          <DialogContent>
            <Typography>
              Item: {selectedListingDetails.itemName}
              <img
                src={selectedListingDetails.itemImg}
                alt={selectedListingDetails.itemName}
                style={{
                  width: "24px",
                  height: "24px",
                  verticalAlign: "middle",
                }}
              />
            </Typography>
            <Typography style={{ marginBottom: "8px" }}>
              Price per Item: {selectedListingDetails.itemCostTax}{" "}
              <img
                src={selectedListingDetails.coinImageUrl}
                alt="Currency"
                style={{
                  width: "24px",
                  height: "24px",
                  verticalAlign: "middle",
                }}
              />
            </Typography>
            <TextField
              label="Quantity"
              type="number"
              fullWidth
              value={selectedListingDetails.quantityToBuy || ""}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                const maxAmount = selectedListingDetails.itemAmount;

                // Ensure quantity doesn't exceed max amount
                if (isNaN(value) || value < 1) {
                  setSelectedListingDetails((prev) => ({
                    ...prev,
                    quantityToBuy: 1,
                  }));
                } else {
                  setSelectedListingDetails((prev) => ({
                    ...prev,
                    quantityToBuy: Math.min(value, maxAmount),
                  }));
                }
              }}
              helperText={`Max: ${selectedListingDetails.itemAmount}`}
            />
            <Typography>
              Total Price:{" "}
              {(
                selectedListingDetails.itemCostTax *
                (selectedListingDetails.quantityToBuy || 1)
              ).toLocaleString()}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmationDialogOpen(false)}
              color="error"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                const payload = {
                  listingId: purchaseListingId,
                  quantity: selectedListingDetails.quantityToBuy || 1,
                };
                try {
                  setLoading(true);
                  setLoadingTitle("Purchase Listing");
                  setLoadingMessage("Purchasing listing...");
                  await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/v1/api/market/${userId}/purchase-listing`,
                    payload
                  );
                  handleSnackbarOpen("Purchase successful.", "success");
                  setConfirmationDialogOpen(false);
                  fetchListings();
                } catch (error) {
                  console.error("Error purchasing listing:", error);
                  handleSnackbarOpen("Purchase failed.", "error");
                } finally {
                  setLoading(false);
                }
              }}
              color="success"
            >
              Confirm Purchase
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Loading State Dialog */}
      <Dialog
        open={loading}
        onClose={() => {}}
        aria-labelledby="loading-dialog-title"
        PaperProps={{
          sx: {
            border: "2px solid #D2B48C",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="loading-dialog-title">{loadingTitle}</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center">
            <CircularProgress
              size={24}
              thickness={4}
              sx={{ color: "#8B4513" }}
            />
            <Typography variant="body1" style={{ marginLeft: 10 }}>
              {loadingMessage}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCommentDialogOpen}
        onClose={() => setIsCommentDialogOpen(false)}
        aria-labelledby="comment-dialog-title"
        PaperProps={{
          sx: {
            border: "2px solid #D2B48C",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="comment-dialog-title">Seller Comment</DialogTitle>
        <DialogContent>
          <Typography>{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsCommentDialogOpen(false)}
            style={{ color: "#8B4513" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default MarketTab;
