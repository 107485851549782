import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Stack,
  Collapse,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InventoryIcon from "@mui/icons-material/Inventory";
import BuildIcon from "@mui/icons-material/Build";
import EditIcon from "@mui/icons-material/Edit";
import CategoryIcon from "@mui/icons-material/Category";
import MapIcon from "@mui/icons-material/Map";
import StoreIcon from "@mui/icons-material/Store";
import UserInfosDialog from "./Info/UserInfosDialog";
import ActiveDeactiveUserDialog from "./ActiveDeactiveUserDialog";
import { useNavigate } from "react-router-dom";

const UserCard = ({ user, refetchUsers, token }) => {
  const { _id, profileImg, name, email, level, exp, status } = user;
  const [showInventoryOptions, setShowInventoryOptions] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(user);
  const navigate = useNavigate();

  const handleInventoryClick = () => {
    setShowInventoryOptions(!showInventoryOptions);
  };

  const handleItemsClick = () => {
    navigate(`/admin/users/inventory/${_id}`, { state: { userName: name } });
  };

  const handlePlotsClick = () => {
    navigate(`/admin/users/plots/${_id}`, { state: { userName: name } });
  };

  const handleShopsClick = () => {
    navigate(`/admin/users/shops/${_id}`, { state: { userName: name } });
  };

  const handleSkillsClick = () => {
    navigate(`/admin/users/skills/${_id}`, { state: { userName: name } });
  };

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleUserSave = (updatedUser) => {
    setCurrentUser(updatedUser);
    setEditDialogOpen(false);
  };

  const handleToggleStatus = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/${_id}/status`,
        {
          method: "PATCH", 
          headers: {
            Authorization: `Bearer ${token}`, 
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        await refetchUsers();
        setDeactivateDialogOpen(false);
      } else {
        console.error("Failed to toggle user status.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 1.5,
          width: "100%",
          p: 1.5,
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            width: 60,
            height: 60,
            mr: 1,
            backgroundColor: "#f5f5f5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 1,
          }}
        >
          {currentUser.profileImg ? (
            <img
              src={`/assets/profile/${currentUser.profileImg}`}
              alt={currentUser.name}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <AccountCircleIcon
              sx={{ color: "rgba(0, 0, 0, 0.8)", fontSize: 40 }}
            />
          )}
        </Box>
        <CardContent sx={{ flex: 1, minWidth: 0, pr: 2 }}>
          <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 500 }}>
            {currentUser.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontSize: "0.875rem" }}
          >
            {currentUser.email}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, fontSize: "0.875rem" }}>
            Level: {currentUser.level.toLocaleString()} | EXP:{" "}
            {currentUser.exp.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </CardContent>
        <Box
          sx={{
            ml: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Stack direction="column" spacing={1} sx={{ alignItems: "flex-end" }}>
            <Button
              variant="contained"
              startIcon={<InventoryIcon />}
              sx={{
                backgroundColor: "#a6c9f2",
                color: "black",
                fontSize: "0.75rem",
                padding: "4px 12px",
                minWidth: "100px",
                "&:hover": { backgroundColor: "#8bb4e0" },
              }}
              onClick={handleInventoryClick}
            >
              Inventory
            </Button>
            <Collapse in={showInventoryOptions} timeout="auto" unmountOnExit>
              <Stack
                direction="column"
                spacing={1}
                sx={{ mt: 1, ml: 2, borderLeft: "2px solid #8bb4e0", pl: 1 }}
              >
                <Button
                  variant="contained"
                  startIcon={<CategoryIcon />}
                  sx={{
                    backgroundColor: "#a6e0e0",
                    color: "black",
                    fontSize: "0.75rem",
                    padding: "4px 12px",
                    minWidth: "100px",
                    "&:hover": { backgroundColor: "#8bd1e0" },
                  }}
                  onClick={handleItemsClick}
                >
                  Items
                </Button>
                <Button
                  variant="contained"
                  startIcon={<MapIcon />}
                  sx={{
                    backgroundColor: "#d0e0f2",
                    color: "black",
                    fontSize: "0.75rem",
                    padding: "4px 12px",
                    minWidth: "100px",
                    "&:hover": { backgroundColor: "#bcd1e0" },
                  }}
                  onClick={handlePlotsClick}
                >
                  Plots
                </Button>
                <Button
                  variant="contained"
                  startIcon={<StoreIcon />}
                  sx={{
                    backgroundColor: "#b0e0f2",
                    color: "black",
                    fontSize: "0.75rem",
                    padding: "4px 12px",
                    minWidth: "100px",
                    "&:hover": { backgroundColor: "#8bd1d1" },
                  }}
                  onClick={handleShopsClick}
                >
                  Shops
                </Button>
              </Stack>
            </Collapse>
            <Button
              variant="contained"
              startIcon={<BuildIcon />}
              sx={{
                backgroundColor: "#f5c396",
                color: "black",
                fontSize: "0.75rem",
                padding: "4px 12px",
                minWidth: "100px",
                "&:hover": { backgroundColor: "#e3a97a" },
              }}
              onClick={handleSkillsClick}
            >
              Skills
            </Button>
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              sx={{
                backgroundColor: "#f5e596",
                color: "black",
                fontSize: "0.75rem",
                padding: "4px 12px",
                minWidth: "100px",
                "&:hover": { backgroundColor: "#e3d47a" },
              }}
              onClick={handleEditClick}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: status === "Active" ? "#f5a6a6" : "#a6f5a6",
                color: "black",
                fontSize: "0.75rem",
                padding: "4px 12px",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor: status === "Active" ? "#e08a8a" : "#8ae08a",
                },
              }}
              onClick={() => setDeactivateDialogOpen(true)}
            >
              {status === "Active" ? "Deactivate" : "Activate"}
            </Button>
          </Stack>
        </Box>
      </Card>
      <UserInfosDialog
        userId={_id}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleUserSave}
      />
      <ActiveDeactiveUserDialog
        open={deactivateDialogOpen}
        status={status}
        name={name}
        onClose={() => setDeactivateDialogOpen(false)}
        onConfirm={handleToggleStatus}
      />
    </>
  );
};

export default UserCard;
