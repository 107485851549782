import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  MenuItem,
} from "@mui/material";

// Import modular option components
import ItemPreqOptions from "./Options/ItemPreqOptions";
import ItemProcessOptions from "./Options/ItemProcessOptions";
import ItemProbabilityOptions from "./Options/ItemProbabilityOptions";
import ItemFarmOptions from "./Options/ItemFarmOptions";
import ItemAnimalOptions from "./Options/ItemAnimalOptions";

const AddItemModal = ({ open, onClose, onSubmit }) => {
  const [itemData, setItemData] = useState({
    name: "",
    description: "",
    value: 0,
    level: 1,
    amount: 0,
    itemType: "",
    imageUrl: "/assets/items/default.webp",
    itemPreq: [],
    itemProcess: [],
    itemProbability: { probability: 0, amountProbability: { min: 0, max: 0 } },
    itemFarm: {
      dateToHarvest: { days: 0, hours: 0, minutes: 0 },
      dateToWilt: { days: 0, hours: 0, minutes: 0 },
      harvestProduct: 0,
      wiltProduct: 0,
      typeOfWater: 0,
      waterAmount: 0,
      waterImg: "",
      bonusProducts: [],
    },
    itemAnimal: {
      affectionLvl: 0,
      lifeStage: "",
      affectionProduce: [],
      feedType: 0,
      feedAmount: 0,
      timeToAdult: 0,
      adultAnimalId: 0,
      butcherIds: [],
      animalType: "",
    },
    stats: { existingQty: 0, recentTradeValue: [] },
    status: "gather",
    processTime: 1,
    subtags: [],
  });

  const [expandedSections, setExpandedSections] = useState({
    itemPreq: false,
    itemProcess: false,
    itemProbability: false,
    itemFarm: false,
    itemAnimal: false,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleChange = (field, value) => {
    setItemData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleNestedChange = (section, keyPath, value) => {
    setItemData((prev) => {
      const keys = keyPath.split(".");
      let updatedSection = { ...prev[section] };

      let current = updatedSection;
      for (let i = 0; i < keys.length - 1; i++) {
        current[keys[i]] = { ...current[keys[i]] };
        current = current[keys[i]];
      }

      current[keys[keys.length - 1]] = value;

      return {
        ...prev,
        [section]: updatedSection,
      };
    });
  };

  const handleSubmit = () => {
    const filteredItemData = { ...itemData };

    // Only include expanded sections in the payload
    Object.keys(expandedSections).forEach((section) => {
      if (!expandedSections[section]) {
        delete filteredItemData[section];
      }
    });

    onSubmit(filteredItemData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add New Item</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Item Name"
            fullWidth
            value={itemData.name}
            onChange={(e) => handleChange("name", e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={itemData.description}
            onChange={(e) => handleChange("description", e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Value"
            type="number"
            fullWidth
            value={itemData.value}
            onChange={(e) => handleChange("value", parseFloat(e.target.value))}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Level"
            type="number"
            fullWidth
            value={itemData.level}
            onChange={(e) => handleChange("level", parseInt(e.target.value))}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Amount"
            type="number"
            fullWidth
            value={itemData.amount}
            onChange={(e) => handleChange("amount", parseInt(e.target.value))}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Item Type"
            fullWidth
            value={itemData.itemType}
            onChange={(e) => handleChange("itemType", e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Image URL"
            fullWidth
            value={itemData.imageUrl}
            onChange={(e) => handleChange("imageUrl", e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Status"
            select
            fullWidth
            value={itemData.status}
            onChange={(e) => handleChange("status", e.target.value)}
            sx={{ mb: 2 }}
          >
            {[
              "gather",
              "inventory",
              "process",
              "craft",
              "fish",
              "key",
              "forage",
              "harvest",
              "coin",
              "animal",
              "produce",
              "potion",
            ].map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Process Time"
            type="number"
            fullWidth
            value={itemData.processTime}
            onChange={(e) =>
              handleChange("processTime", parseInt(e.target.value))
            }
            sx={{ mb: 2 }}
          />
          <TextField
            label="Subtags (comma-separated)"
            fullWidth
            value={itemData.subtags.join(", ")}
            onChange={(e) =>
              handleChange(
                "subtags",
                e.target.value.split(",").map((tag) => tag.trim())
              )
            }
            sx={{ mb: 2 }}
          />
        </Box>

        {/* Dynamically render options */}
        {Object.keys(expandedSections).map((section) => (
          <Box key={section} sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => toggleSection(section)}
            >
              {expandedSections[section]
                ? `Hide ${section} Options`
                : `Show ${section} Options`}
            </Button>
            {expandedSections[section] && (
              <Box sx={{ mt: 2 }}>
                {section === "itemPreq" && (
                  <ItemPreqOptions
                    data={itemData.itemPreq}
                    onChange={(updatedData) =>
                      handleChange("itemPreq", updatedData)
                    }
                  />
                )}
                {section === "itemProcess" && (
                  <ItemProcessOptions
                    data={itemData.itemProcess}
                    onChange={(updatedData) =>
                      handleChange("itemProcess", updatedData)
                    }
                  />
                )}
                {section === "itemProbability" && (
                  <ItemProbabilityOptions
                    data={itemData.itemProbability}
                    onChange={(key, value) =>
                      handleNestedChange("itemProbability", key, value)
                    }
                  />
                )}
                {section === "itemFarm" && (
                  <ItemFarmOptions
                    data={itemData.itemFarm}
                    onChange={(key, value) =>
                      handleNestedChange("itemFarm", key, value)
                    }
                  />
                )}
                {section === "itemAnimal" && (
                  <ItemAnimalOptions
                    data={itemData.itemAnimal}
                    onChange={(key, value) =>
                      handleNestedChange("itemAnimal", key, value)
                    }
                  />
                )}
              </Box>
            )}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Add Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemModal;
