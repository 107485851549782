import React, { useEffect, useState } from "react";
import SecureLS from "secure-ls";
import {
  Box,
  Typography,
  Link,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { renderLogComponent } from "./ImportLogs"; // Import the render function

const AdminLogs = () => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterType, setFilterType] = useState("userName");
  const [exactMatch, setExactMatch] = useState(false); // State for exact match
  const [startDate, setStartDate] = useState(""); // Start date state
  const [endDate, setEndDate] = useState(""); // End date state
  const [dateDialogOpen, setDateDialogOpen] = useState(false); // State for dialog open/close
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchLogs(1);
  }, [filters]);

  const fetchLogs = async (pageNumber) => {
    if (isFetching) return;

    setIsFetching(true);

    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;

    // Create the query params, conditionally including startDate and endDate
    const queryParams = new URLSearchParams({
      page: pageNumber,
      limit: 10,
      exactMatch,
      ...filters, // Includes searchQuery and filterType
    });

    // Conditionally add startDate and endDate if they are valid
    if (startDate && !isNaN(new Date(startDate).getTime())) {
      queryParams.set("startDate", startDate);
    }
    if (endDate && !isNaN(new Date(endDate).getTime())) {
      queryParams.set("endDate", endDate);
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/v1/api/admin/logs?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        const filteredLogs = data.logs.filter(
          (log) => !logs.some((existingLog) => existingLog._id === log._id)
        );

        if (filteredLogs.length > 0) {
          setLogs((prevLogs) => [...prevLogs, ...filteredLogs]);
          setPage(pageNumber + 1);

          if (filteredLogs.length < 10) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      } else {
        console.error("Failed to fetch logs.");
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      setHasMore(false);
    } finally {
      setIsFetching(false);
    }
  };

  const loadMoreLogs = () => {
    fetchLogs(page);
  };

  const handleSearch = () => {
    const newFilters = {};

    if (searchQuery.trim() !== "") {
      newFilters[filterType] = searchQuery;
    }

    if (startDate) {
      newFilters.startDate = startDate;
    }

    if (endDate) {
      newFilters.endDate = endDate;
    }

    if (Object.keys(newFilters).length === 0) {
      setFilters({});
    } else {
      setFilters(newFilters);
    }

    setLogs([]); // Clear previous logs to avoid duplication
    setPage(1);
  };

  const handleDateDialogOpen = () => {
    setDateDialogOpen(true);
  };

  const handleDateDialogClose = () => {
    setDateDialogOpen(false);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f4f6f8", borderRadius: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: "center",
          fontFamily: '"Pixelify Sans", sans-serif',
          fontWeight: 600,
          color: "#3a3a3a",
        }}
      >
        Admin Logs
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          marginBottom: -1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Filter By</InputLabel>
          <Select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Filter By"
          >
            <MenuItem value="userName">User Name</MenuItem>
            <MenuItem value="adminName">Admin Name</MenuItem>
            <MenuItem value="transactType">Transaction Type</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          placeholder={`Search by ${filterType
            .replace(/([A-Z])/g, " $1")
            .trim()}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            flex: 1,
            maxWidth: 400,
            backgroundColor: "#fff",
            borderRadius: 1,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          sx={{
            paddingX: 4,
            paddingY: 1.5,
            backgroundColor: "#3a77f5",
            fontSize: "1rem",
            fontWeight: "bold",
            textTransform: "none",
            borderRadius: 1,
            "&:hover": {
              backgroundColor: "#336ad6",
            },
          }}
        >
          Search
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <Button
          variant="outlined"
          onClick={handleDateDialogOpen}
          sx={{ minWidth: 150 }}
        >
          {startDate && endDate
            ? `Date: ${startDate} - ${endDate}`
            : "Select Date Range"}
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={exactMatch}
              onChange={(e) => setExactMatch(e.target.checked)}
            />
          }
          label="Exact Match"
          sx={{
            margin: 0,
            fontSize: "0.6rem",
            color: "#3a3a3a",
            display: "inline-flex",
            alignItems: "center",
            transform: "scale(0.8)",
            transformOrigin: "center",
          }}
        />
      </Box>
      <Dialog open={dateDialogOpen} onClose={handleDateDialogClose}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setStartDate("");
              setEndDate("");
            }}
            color="secondary"
          >
            Clear Date
          </Button>
          <Button onClick={handleDateDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <InfiniteScroll
        dataLength={logs.length}
        next={loadMoreLogs}
        hasMore={hasMore}
        loader={<Typography>Loading more logs...</Typography>}
        endMessage={
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2">No More Logs</Typography>
            <Link
              href="#"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              sx={{ display: "block", marginTop: 1, fontSize: "0.875rem" }}
            >
              Move to Top
            </Link>
          </Box>
        }
      >
        {logs.map((log) => renderLogComponent(log))}
      </InfiniteScroll>
    </Box>
  );
};

export default AdminLogs;
