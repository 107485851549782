import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddRemoveSkillDialog from "./AddRemoveSkillDialog";

const LockedSkills = ({ skills, onSkillAdd }) => {
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const handleAddClick = (skill) => {
    setSelectedSkill(skill);
    setIsAddDialogOpen(true);
  };

  return (
    <Box>
      {skills.length > 0 ? (
        skills.map((skill) => (
          <Card key={skill._id} sx={{ mb: 2, display: "flex" }}>
            <CardContent
              sx={{ display: "flex", alignItems: "center", flex: 1 }}
            >
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  mr: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  filter: "grayscale(100%)", // Grayscale only the image
                }}
              >
                <img
                  src={skill.skillImage}
                  alt={skill.skillName}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: '"Pixelify Sans", sans-serif',
                    fontWeight: "bold",
                  }}
                >
                  {skill.skillName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {skill.description}
                </Typography>
              </Box>
            </CardContent>
            <Box
              sx={{ display: "flex", alignItems: "center", paddingRight: 2 }}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{
                  backgroundColor: "#c8e6c9",
                  color: "black",
                  fontSize: "0.75rem",
                  padding: "4px 12px",
                  "&:hover": { backgroundColor: "#a5d6a7" },
                }}
                onClick={() => handleAddClick(skill)}
              >
                Add
              </Button>
            </Box>
          </Card>
        ))
      ) : (
        <Typography variant="body2">No locked skills available.</Typography>
      )}

      {selectedSkill && (
        <AddRemoveSkillDialog
          open={isAddDialogOpen}
          onClose={() => setIsAddDialogOpen(false)}
          skill={selectedSkill}
          onConfirm={async () => {
            const success = await onSkillAdd(selectedSkill.skillId);
            if (success) {
              setIsAddDialogOpen(false);
            }
          }}
          isAdd={true}
        />
      )}
    </Box>
  );
};

export default LockedSkills;
