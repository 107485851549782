import React from "react";
import { Typography, Card, CardContent } from "@mui/material";

const EditUserInfoLogs = ({ log }) => {
  return (
    <Card sx={{ marginBottom: 2, boxShadow: "0px 3px 10px rgba(0,0,0,0.2)" }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          {new Date(log.date).toLocaleString()}
        </Typography>
        <Typography variant="h6">{log.transactType}</Typography>
        <Typography variant="body1">
          {log.userName} <small>({log.userId})</small>
        </Typography>
        {log.editUserInfoLog.map((edit, index) => (
          <React.Fragment key={index}>
            {edit.previousName !== edit.newName && (
              <Typography variant="body2">
                Name: {edit.previousName} &rarr; {edit.newName}
              </Typography>
            )}
            {edit.previousEmail !== edit.newEmail && (
              <Typography variant="body2">
                Email: {edit.previousEmail} &rarr; {edit.newEmail}
              </Typography>
            )}
            {edit.previousLevel !== edit.newLevel && (
              <Typography variant="body2">
                Level: {edit.previousLevel} &rarr; {edit.newLevel}
              </Typography>
            )}
            {edit.previousExp !== edit.newExp && (
              <Typography variant="body2">
                EXP: {edit.previousExp.toLocaleString()} &rarr;{" "}
                {edit.newExp.toLocaleString()}
              </Typography>
            )}
          </React.Fragment>
        ))}
        <Typography variant="caption">
          Admin: {log.adminName} <small>({log.adminId})</small>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EditUserInfoLogs;
