import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import LoadingDialog from "../../components/LoadingDialog";

const UserItemDialog = ({ open, onClose, item, onSave, onDelete, isCollected }) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const [itemLvl, setItemLvl] = useState(item.itemLvl || 1);
  const [exp, setExp] = useState(item.itemExp);
  const [isSaving, setIsSaving] = useState(false); 

  const handleSave = async () => {
    setIsSaving(true);
    await onSave(item.itemId, item.name, quantity, itemLvl, exp);
    setIsSaving(false);
    onClose();
  };

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${item.name}?`)) {
      setIsSaving(true);
      await onDelete(item.itemId);
      setIsSaving(false);
      onClose();
    }
  };

  const checkValue = (value) => {
    if (value === "") return true; 
    const num = Number(value);
    return !isNaN(num) && num >= 0;
  };

  const quantityValid = checkValue(quantity);
  const itemLvlValid = checkValue(itemLvl);
  const expValid = checkValue(exp);

  const normalizeValue = (value) => {
    const num = Number(value);
    return isNaN(num) || num < 0 ? "" : num.toString();
  };

  const handleChange = (setter) => (e) => {
    let newValue = e.target.value;

    if (setter === setItemLvl) {
      newValue = newValue === "" || newValue === "0" ? "1" : newValue;
    }

    setter(normalizeValue(newValue));
  };

  const hasChanges =
    (quantityValid && quantity !== item.quantity) ||
    (itemLvlValid && itemLvl !== item.itemLvl) ||
    (expValid && exp !== item.itemExp);

  const isSaveDisabled =
    !hasChanges || !quantityValid || !itemLvlValid || !expValid;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{item.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Item Details
          </Typography>
          <TextField
            label="Quantity"
            type="number"
            fullWidth
            value={quantity}
            onChange={handleChange(setQuantity)}
            margin="normal"
            inputProps={{ min: 0 }} 
          />
          <TextField
            label="Item Level"
            type="number"
            fullWidth
            value={itemLvl}
            onChange={handleChange(setItemLvl)}
            margin="normal"
            inputProps={{ min: 1 }} 
          />
          <TextField
            label="Experience"
            type="number"
            fullWidth
            value={exp}
            onChange={handleChange(setExp)}
            margin="normal"
            inputProps={{ min: 0 }} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          {isCollected && (
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          )}
          <Button
            onClick={handleSave}
            color="success"
            disabled={isSaveDisabled} 
          >
            {isCollected ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={isSaving} message="Inventory being saved..." />
    </>
  );
};

export default UserItemDialog;
