import React from "react";
import { Typography, Card, CardContent } from "@mui/material";

// Helper function to format dates
const formatDate = (date) => {
  return date ? new Date(date).toLocaleString() : "N/A";
};

const EditEmptyAnimalPlotLogs = ({ log }) => {
  const { previousDetails, newDetails } = log.editEmptyAnimalPlotLog;

  const renderDetailChange = (label, prevValue, newValue, isDate = false) => {
    const formattedPrevValue = isDate ? formatDate(prevValue) : prevValue;
    const formattedNewValue = isDate ? formatDate(newValue) : newValue;

    if (formattedPrevValue !== formattedNewValue) {
      return (
        <Typography variant="body2">
          {label}: {formattedPrevValue} &rarr; {formattedNewValue}
        </Typography>
      );
    }
    return null;
  };

  const renderAnimalNameWithIdChange = (prevName, prevId, newName, newId) => {
    const formattedPrevValue =
      prevName || prevId ? `${prevName || "N/A"} (${prevId || "N/A"})` : "N/A";
    const formattedNewValue =
      newName || newId ? `${newName || "N/A"} (${newId || "N/A"})` : "N/A";

    if (formattedPrevValue !== formattedNewValue) {
      return (
        <Typography variant="body2">
          Animal Name (Animal ID): {formattedPrevValue} &rarr;{" "}
          {formattedNewValue}
        </Typography>
      );
    }
    return null;
  };

  return (
    <Card sx={{ marginBottom: 2, boxShadow: "0px 3px 10px rgba(0,0,0,0.2)" }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          {formatDate(log.date)}
        </Typography>
        <Typography variant="h6">{log.transactType}</Typography>
        <Typography variant="body1">
          {log.userName} <small>({log.userId})</small>
        </Typography>
        <Typography variant="body2">
          Plot Number: {log.editEmptyAnimalPlotLog.plotNumber}
        </Typography>

        {renderAnimalNameWithIdChange(
          previousDetails.animalName,
          previousDetails.addedAnimalId,
          newDetails.animalName,
          newDetails.addedAnimalId
        )}
        {/* Render changes for adultAnimalId */}
        {renderDetailChange(
          "Adult Animal ID",
          previousDetails.adultAnimalId,
          newDetails.adultAnimalId
        )}
        {renderDetailChange(
          "Life Stage",
          previousDetails.currentLifeStage,
          newDetails.currentLifeStage
        )}
        {renderDetailChange(
          "Date Placed",
          previousDetails.datePlaced,
          newDetails.datePlaced,
          true // Indicate that this is a date field
        )}
        {renderDetailChange(
          "Date to Feed",
          previousDetails.dateToFeed,
          newDetails.dateToFeed,
          true // Indicate that this is a date field
        )}
        {renderDetailChange(
          "Date to Harvest",
          previousDetails.dateToHarvest,
          newDetails.dateToHarvest,
          true // Indicate that this is a date field
        )}
        {renderDetailChange(
          "Date to Maturity",
          previousDetails.dateToMaturity,
          newDetails.dateToMaturity,
          true // Indicate that this is a date field
        )}
        {renderDetailChange(
          "Date to Death",
          previousDetails.dateToDeath,
          newDetails.dateToDeath,
          true // Indicate that this is a date field
        )}
        {renderDetailChange("Is Fed", previousDetails.isFed, newDetails.isFed)}
        {renderDetailChange(
          "Feed Type",
          previousDetails.feedType || "N/A",
          newDetails.feedType || "N/A"
        )}
        {renderDetailChange(
          "Feed Amount",
          previousDetails.feedAmount,
          newDetails.feedAmount
        )}
        {renderDetailChange(
          "Affection Level",
          previousDetails.affectionLvl,
          newDetails.affectionLvl
        )}

        <Typography variant="caption">
          Admin: {log.adminName} <small>({log.adminId})</small>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EditEmptyAnimalPlotLogs;
