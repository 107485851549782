// src/components/EditInventoryLogs.js
import React from "react";
import { Typography, Card, CardContent } from "@mui/material";

const EditInventoryLogs = ({ log }) => {
  return (
    <Card sx={{ marginBottom: 2, boxShadow: "0px 3px 10px rgba(0,0,0,0.2)" }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          {new Date(log.date).toLocaleString()}
        </Typography>
        <Typography variant="h6">{log.transactType}</Typography>
        <Typography variant="body1">
          {log.userName} <small>({log.userId})</small>
        </Typography>
        <Typography variant="body2">
          {log.inventoryLog.itemName &&
            `Item Name: ${log.inventoryLog.itemName} `}
          <small>({log.inventoryLog.itemId})</small>
        </Typography>
        {log.inventoryLog.previousQuantity !== log.inventoryLog.newQuantity && (
          <Typography variant="body2">
            Quantity: {log.inventoryLog.previousQuantity.toLocaleString()}{" "}
            &rarr; {log.inventoryLog.newQuantity.toLocaleString()}
          </Typography>
        )}
        {log.inventoryLog.previousItemLvl !== log.inventoryLog.newItemLvl && (
          <Typography variant="body2">
            Item Level: {log.inventoryLog.previousItemLvl} &rarr;{" "}
            {log.inventoryLog.newItemLvl}
          </Typography>
        )}
        {log.inventoryLog.previousExp !== log.inventoryLog.newExp && (
          <Typography variant="body2">
            EXP: {log.inventoryLog.previousExp.toLocaleString()} &rarr;{" "}
            {log.inventoryLog.newExp.toLocaleString()}
          </Typography>
        )}
        <Typography variant="caption">
          Admin: {log.adminName} <small>({log.adminId})</small>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EditInventoryLogs;
