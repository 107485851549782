import React, { useState, useEffect, useRef } from "react";
const SecureLS = require("secure-ls");
import { useNavigate } from "react-router-dom";
import { Container, Alert } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const backgroundImages = [
    "/assets/landingpage/gatherskill-bg.webp",
    "/assets/landingpage/processskill-bg.webp",
    "/assets/landingpage/craftskill-bg.webp",
    "/assets/landingpage/fishskill-bg.webp",
  ];

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");

    if (userDetails && userDetails.userId) {
      navigate("/hoard");
    }

    // Dynamically load the Google Ads script
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8183670145770095";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    return re.test(email);
  };

  const navigateToSignup = () => {
    navigate("/signup");
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!emailRegex.test(newEmail)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address");
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    // Check if the response is in JSON format
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const data = await response.json();

      if (response.status === 200) {
        console.log("Login successful:", data);

        const userInfoResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/auth/get-userinfo/${email}`
        );

        const userInfoData = await userInfoResponse.json();

        if (userInfoResponse.ok) {
          const ls = new SecureLS({ isCompression: false });
          ls.set("userDetails", {
            userId: userInfoData.userId,
            role: userInfoData.role,
          });
        }

        window.gtag("event", "login", {
          event_category: "User",
          event_action: "Login",
          event_label: userInfoData.userId,
        });

        window.gtag("set", { userId: userInfoData.userId });

        navigate("/hoard");
      } else if (
        response.status === 403 &&
        data.message ===
          "Account is deactivated. Contact support for reactivation."
      ) {
        // Handle account deactivated error
        setLoginError(
          "Your account is deactivated. Please contact support at korozoma@hoardingliferpg.com for reactivation."
        );
      } else if (response.status === 400) {
        if (data.message === "User not found") {
          setLoginError("No user found with this email address.");
        } else if (data.message === "Incorrect password") {
          setLoginError("The password you entered is incorrect.");
        } else {
          setLoginError("Login failed. Please try again.");
        }
      } else {
        setLoginError("An error occurred. Please try again later.");
      }
    } else {
      // Handle non-JSON response
      const textResponse = await response.text();
      setLoginError(`An error occurred: ${textResponse}`);
    }
    setLoading(false);
  };

  function Copyright(props) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://www.hoardingliferpg.com/">
          Hoarding Life RPG
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = (currentImageIndex + 1) % backgroundImages.length;
      setCurrentImageIndex(newIndex);
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [currentImageIndex, backgroundImages]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundImages[currentImageIndex]})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          transition: "0.5s ease-in-out",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}
          >
            Hoarding Life
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Where hoarding is life's ambition
          </Typography>
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailErrorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <CircularProgress
                    size={24}
                    sx={{ color: "#fff", marginRight: 2 }}
                  />
                  Logging you in...
                </>
              ) : (
                "Sign In"
              )}
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link
                  onClick={navigateToSignup}
                  component="button"
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
