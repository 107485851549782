import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EditFarmPlotDialog from "./FarmPlotsDialog";
import SecureLS from "secure-ls";

const FarmPlots = ({ plot, seeds, onPlotUpdate }) => {
  const { userId } = useParams();
  const { plotNumber, details } = plot;
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isEmptying, setIsEmptying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleSaveEditDialog = async (newDetails) => {
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/farm-edit/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber,
            newDetails: {
              ...newDetails,
              seedName: newDetails.seedPlanted?.name || "Unknown Seed",
              productName:
                newDetails.harvestProduct?.productName || "Unknown Product",
              productImg: newDetails.harvestProduct?.productImg || null,
            },
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        onPlotUpdate(); // Refresh the entire list of plots
        setIsEditDialogOpen(false);
      } else {
        console.error("Failed to update the plot.");
      }
    } catch (error) {
      console.error("Error updating the plot:", error);
    }
  };

  const handleEmptyPlot = async () => {
    setIsEmptying(true);
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/farm-empty/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber,
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        onPlotUpdate(); // Refresh the entire list of plots
        setIsEmptying(false);
      } else {
        console.error("Failed to empty the plot.");
        setIsEmptying(false);
      }
    } catch (error) {
      console.error("Error emptying the plot:", error);
      setIsEmptying(false);
    }
  };

  const handleDeletePlot = async () => {
    setIsDeleting(true);
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/plots/farm-delete/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber,
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        await onPlotUpdate(); // Refetch the entire list of plots
        setIsDeleting(false);
      } else {
        console.error("Failed to delete the plot.");
        setIsDeleting(false);
      }
    } catch (error) {
      console.error("Error deleting the plot:", error);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          fontFamily: '"Pixelify Sans", sans-serif',
          height: 180,
        }}
      >
        <Box
          sx={{
            width: 180,
            height: 180,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            backgroundColor: isEmptying ? "#f0f0f0" : "transparent", // Change background color during loading
          }}
        >
          {isEmptying ? (
            <CircularProgress />
          ) : details.harvestProduct?.productImg ? (
            <img
              src={details.harvestProduct.productImg}
              alt={details.harvestProduct.productName}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <Typography
              variant="h5"
              sx={{
                color: "#999",
                fontFamily: '"Pixelify Sans", sans-serif',
                textAlign: "center",
              }}
            >
              N/A
            </Typography>
          )}
        </Box>
        <CardContent sx={{ flex: 1 }}>
          <Typography
            variant="h10"
            sx={{
              fontFamily: '"Pixelify Sans", sans-serif',
              fontWeight: "bold",
              mb: 1,
            }}
          >
            Plot Number: {plotNumber} -{" "}
            {details.harvestProduct?.productName || "None"}
          </Typography>
          <Typography variant="body2">
            Planted: {new Date(details.datePlanted).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Harvest Time: {new Date(details.dateToHarvest).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Wilt Time: {new Date(details.dateToWilt).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            Status: {details.productStatus}
          </Typography>
          <Typography variant="body2">
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "center" }}
            >
              Watered: {details.isWatered ? "Yes" : "No"}{" "}
              {details.isWatered && (
                <img
                  src={details.waterImg}
                  alt="Water"
                  style={{ width: 20, height: 20, marginLeft: 4 }}
                />
              )}
            </Box>
          </Typography>
        </CardContent>
        <Stack direction="column" spacing={1} sx={{ pr: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#a6c9f2",
              color: "black",
              fontSize: "0.7rem",
              padding: "4px 8px",
            }}
            startIcon={<EditIcon />}
            onClick={handleEditClick}
          >
            Edit Plot
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#444444", // Pastel Black
              color: "white",
              fontSize: "0.7rem",
              padding: "4px 8px",
            }}
            startIcon={<RemoveCircleOutlineIcon />} // Icon for Empty Plot
            onClick={handleEmptyPlot} // Handle empty plot
            disabled={isEmptying || !details.harvestProduct?.productImg} // Disable if emptying or productImg is not available
          >
            Empty Plot
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: "#f5a6a6",
              color: "black",
              fontSize: "0.7rem",
              padding: "4px 8px",
            }}
            startIcon={<DeleteIcon />}
            onClick={handleDeletePlot} // Handle delete plot
            disabled={isDeleting} // Disable button during loading
          >
            Delete Plot
          </Button>
        </Stack>
      </Card>

      {/* Edit Farm Plot Dialog */}
      <EditFarmPlotDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        plot={plot}
        seeds={seeds} // Pass seeds to the dialog
        onSave={handleSaveEditDialog}
      />
    </>
  );
};

export default FarmPlots;
