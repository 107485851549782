import React, { useState, useEffect, useRef } from "react";
const SecureLS = require("secure-ls");
import { useNavigate } from "react-router-dom";
import { Container, Alert, CircularProgress } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const backgroundImages = [
    "/assets/landingpage/gatherskill-bg.webp",
    "/assets/landingpage/processskill-bg.webp",
    "/assets/landingpage/craftskill-bg.webp",
    "/assets/landingpage/fishskill-bg.webp",
  ];

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");

    if (adminDetails && adminDetails.token) {
      navigate("/admin/dashboard");
    }

    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8183670145770095";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!emailRegex.test(newEmail)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address");
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const data = await response.json();

      if (response.status === 200) {
        const ls = new SecureLS({ isCompression: false });

        ls.set("adminDetails", {
          token: data.token,
          role: "admin",
          name: data.name,
          userId: data.userId,
        });

        navigate("/admin/dashboard");
      } else {
        setLoading(false); 
        if (data.message === "User not found") {
          setLoginError("No user found with this email address.");
        } else if (data.message === "Incorrect password") {
          setLoginError("The password you entered is incorrect.");
        } else {
          setLoginError("Login failed. Please try again.");
        }
      }
    } else {
      setLoading(false); 
      const textResponse = await response.text();
      setLoginError(`An error occurred: ${textResponse}`);
    }
  };

  function Copyright(props) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://www.hoardingliferpg.com/">
          Hoarding Life RPG
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = (currentImageIndex + 1) % backgroundImages.length;
      setCurrentImageIndex(newIndex);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, backgroundImages]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundImages[currentImageIndex]})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          transition: "0.5s ease-in-out",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}
          >
            Hoarding Life Admin Login
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Admin Access Only
          </Typography>

          {loading ? (
            <CircularProgress sx={{ mt: 3 }} /> 
          ) : (
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              {loginError && <Alert severity="error">{loginError}</Alert>}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Admin Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailErrorMessage}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default AdminLogin;
