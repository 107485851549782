import React, { useEffect, useState } from "react";
import SecureLS from "secure-ls";
import { useParams, useLocation } from "react-router-dom";
import {
  CircularProgress,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserItemDialog from "./UserItemDialog";
import CollectedItems from "./CollectedItems";
import UncollectedItems from "./UncollectedItems";

const UserInventory = () => {
  const { userId } = useParams();
  const location = useLocation();
  const userName = location.state?.userName || "User";
  const [collectedInventory, setCollectedInventory] = useState([]);
  const [notCollectedInventory, setNotCollectedInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const ls = new SecureLS({ isCompression: false });
        const adminDetails = ls.get("adminDetails");
        const token = adminDetails ? adminDetails.token : null;

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/inventory/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const { collectedInventory, notCollectedInventory } =
            await response.json();
          setCollectedInventory(collectedInventory);
          setNotCollectedInventory(notCollectedInventory);
          console.log("Collected inventory: ", collectedInventory);
          console.log("Not collected inventory: ", notCollectedInventory);
        } else {
          console.error("Failed to fetch inventory.");
        }
      } catch (error) {
        console.error("Error fetching inventory:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInventory();
  }, [userId]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedItem(null);
    setSuccessMessage(null);
  };

  const handleSave = async (
    itemId,
    itemName,
    quantity,
    itemLvl,
    exp,
    isCollected
  ) => {
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

    const endpoint = isCollected
      ? `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/inventory/${userId}/items/${itemId}`
      : `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/inventory/${userId}/add-item/${itemId}`;

    const method = isCollected ? "PUT" : "POST";

    try {
      const response = await fetch(endpoint, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          itemName,
          quantity,
          itemLvl,
          exp,
          adminName,
          adminId,
        }),
      });

      if (response.ok) {
        const updatedItem = await response.json();
        const itemWithDetails = {
          ...selectedItem,
          ...updatedItem.item,
        };

        if (isCollected) {
          setCollectedInventory((prevInventory) => {
            const updatedInventory = prevInventory.map((invItem) =>
              invItem.itemId === itemId ? itemWithDetails : invItem
            );
            return updatedInventory;
          });
        } else {
          setCollectedInventory((prevInventory) => [
            ...prevInventory,
            itemWithDetails,
          ]);
          setNotCollectedInventory((prevInventory) =>
            prevInventory.filter((invItem) => invItem.itemId !== itemId)
          );
        }

        alert(
          isCollected
            ? "Item Updated Successfully!"
            : "Item Added Successfully!"
        );
      } else {
        console.error("Failed to update item.");
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleDelete = async (itemId) => {
    const ls = new SecureLS({ isCompression: false });
    const adminDetails = ls.get("adminDetails");
    const token = adminDetails ? adminDetails.token : null;
    const adminName = adminDetails ? adminDetails.name : "Unknown Admin"; // Extract the admin name
    const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId"; // Extract the admin ID

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users/inventory/${userId}/items/${itemId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            itemName: selectedItem.name,
            adminName,
            adminId,
          }),
        }
      );

      if (response.ok) {
        setCollectedInventory((prevInventory) =>
          prevInventory.filter((invItem) => invItem.itemId !== itemId)
        );

        setNotCollectedInventory((prevInventory) => [
          ...prevInventory,
          selectedItem,
        ]);

        alert("Item deleted successfully!");
      } else {
        console.error("Failed to delete item.");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <IconButton
          onClick={() => window.history.back()}
          sx={{ marginRight: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: '"Pixelify Sans", sans-serif',
            textAlign: "center",
            flex: 1,
          }}
        >
          {userName}'s Inventory
        </Typography>
      </Box>

      <Typography variant="h6" gutterBottom>
        Collected Items
      </Typography>
      <CollectedItems
        items={collectedInventory}
        onItemClick={handleItemClick}
      />

      <Divider sx={{ marginY: 4 }} />

      <Typography variant="h6" gutterBottom>
        Not Collected Items
      </Typography>
      <UncollectedItems
        items={notCollectedInventory}
        onItemClick={handleItemClick}
      />

      {selectedItem && (
        <UserItemDialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          item={selectedItem}
          onSave={(itemId, itemName, quantity, itemLvl, exp) =>
            handleSave(
              itemId,
              itemName,
              quantity,
              itemLvl,
              exp,
              collectedInventory.includes(selectedItem)
            )
          }
          onDelete={() => handleDelete(selectedItem.itemId)} // Handle the deletion of the item
          isCollected={collectedInventory.includes(selectedItem)}
        />
      )}
    </Box>
  );
};

export default UserInventory;
