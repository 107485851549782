import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  CircularProgress,
} from "@mui/material";
import SecureLS from "secure-ls";
import UserCard from "./Users/UserCard";

const AdminUsers = () => {
  const [users, setUsers] = useState({
    players: [],
    admins: [],
    testers: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const usersRef = useRef(null);

  const ls = new SecureLS({ isCompression: false });
  const adminDetails = ls.get("adminDetails");
  const token = adminDetails ? adminDetails.token : null;

  if (!token) {
    console.error("No admin token found.");
    setLoading(false);
    return;
  }

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/users`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("players: ", data);

        const sortByStatus = (a, b) => {
          if (a.status === "Deactivated" && b.status === "Active") return 1;
          if (a.status === "Active" && b.status === "Deactivated") return -1;
          return 0;
        };

        const players = data
          .filter((user) => user.role === "player")
          .sort((a, b) => sortByStatus(a, b) || b.level - a.level); // Sort by status, then by level
        const admins = data
          .filter((user) => user.role === "admin")
          .sort((a, b) => sortByStatus(a, b) || b.level - a.level);
        const testers = data
          .filter((user) => user.role === "tester")
          .sort((a, b) => sortByStatus(a, b) || b.level - a.level);

        const groupedUsers = { players, admins, testers };

        setUsers(groupedUsers);
        usersRef.current = groupedUsers;
      } else {
        console.error("Failed to fetch users:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (usersRef.current) {
      setUsers(usersRef.current);
      setLoading(false);
      return;
    }

    fetchUsers();
  }, []);

  const filterUsers = (users) => {
    const lowercasedFilter = searchTerm.toLowerCase();
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(lowercasedFilter) ||
        user.email.toLowerCase().includes(lowercasedFilter)
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 4, px: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          fontFamily: '"Pixelify Sans", sans-serif',
        }}
      >
        List of Hoarders
      </Typography>

      {/* Search Bar */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <TextField
          label="Search Users"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "100%", maxWidth: 400 }}
        />
      </Box>

      {/* Display Players */}
      <Divider sx={{ mb: 4 }} />
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Players
        </Typography>
        {filterUsers(users.players).length > 0 ? (
          filterUsers(users.players).map((user) => (
            <UserCard key={user._id} user={user} token={token} refetchUsers={fetchUsers} />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No Players found.
          </Typography>
        )}
      </Box>

      {/* Display Admins */}
      <Divider sx={{ mb: 4 }} />
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Admins
        </Typography>
        {filterUsers(users.admins).length > 0 ? (
          filterUsers(users.admins).map((user) => (
            <UserCard key={user._id} user={user} token={token} refetchUsers={fetchUsers} />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No Admins found.
          </Typography>
        )}
      </Box>

      {/* Display Testers */}
      <Divider sx={{ mb: 4 }} />
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Testers
        </Typography>
        {filterUsers(users.testers).length > 0 ? (
          filterUsers(users.testers).map((user) => (
            <UserCard key={user._id} user={user} token={token} refetchUsers={fetchUsers} />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No Testers found.
          </Typography>
        )}
      </Box>

      <Divider />
    </Box>
  );
};

export default AdminUsers;
