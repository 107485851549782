import React from "react";
import { Typography, Card, CardContent } from "@mui/material";

const EditSkillLogs = ({ log }) => {
  return (
    <Card sx={{ marginBottom: 2, boxShadow: "0px 3px 10px rgba(0,0,0,0.2)" }}>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          {new Date(log.date).toLocaleString()}
        </Typography>
        <Typography variant="h6">{log.transactType}</Typography>
        <Typography variant="body1">
          {log.userName} <small>({log.userId})</small>
        </Typography>
        <Typography variant="body2">
          {log.editSkillLog.skillName}{" "}
          <small>({log.editSkillLog.skillId})</small>
        </Typography>
        {log.editSkillLog.previousSkillLvl !== log.editSkillLog.newSkillLvl && (
          <Typography variant="body2">
            Skill Level: {log.editSkillLog.previousSkillLvl} &rarr;{" "}
            {log.editSkillLog.newSkillLvl}
          </Typography>
        )}
        {log.editSkillLog.previousSkillExp !== log.editSkillLog.newSkillExp && (
          <Typography variant="body2">
            EXP: {log.editSkillLog.previousSkillExp.toLocaleString()} &rarr;{" "}
            {log.editSkillLog.newSkillExp.toLocaleString()}
          </Typography>
        )}
        <Typography variant="caption">
          Admin: {log.adminName} <small>({log.adminId})</small>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EditSkillLogs;
