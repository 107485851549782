import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import SecureLS from "secure-ls";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UnlockedSkills from "./UnlockedSkills";
import LockedSkills from "./LockedSkills";

const UserSkills = () => {
  const { userId } = useParams();
  const location = useLocation();
  const userName = location.state?.userName || "User";
  const [unlockedSkills, setUnlockedSkills] = useState([]);
  const [lockedSkills, setLockedSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSkills = async () => {
      setIsLoading(true);
      try {
        const ls = new SecureLS({ isCompression: false });
        const adminDetails = ls.get("adminDetails");
        const token = adminDetails ? adminDetails.token : null;

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/skills/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const { skills } = await response.json();
          const unlocked = skills.filter(
            (skill) => skill.skillStatus === "unlocked"
          );
          const locked = skills.filter(
            (skill) => skill.skillStatus === "locked"
          );
          setUnlockedSkills(unlocked);
          setLockedSkills(locked);
        } else {
          console.error("Failed to fetch skills.");
        }
      } catch (error) {
        console.error("Error fetching skills:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSkills();
  }, [userId]);

  const handleSkillUpdate = async (updatedSkill) => {
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/skills/${userId}/${updatedSkill.skillId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            skillLevel: updatedSkill.skillLevel,
            skillExp: updatedSkill.skillExp,
            adminId: adminId,
            adminName: adminName,
          }),
        }
      );

      if (response.ok) {
        const { skill } = await response.json();
        setUnlockedSkills((prevSkills) =>
          prevSkills.map((s) => (s.skillId === skill.skillId ? skill : s))
        );
      } else {
        console.error("Failed to update the skill.");
      }
    } catch (error) {
      console.error("Error updating the skill:", error);
    }
  };

  const handleSkillRemove = async (skillId) => {
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/skills/remove/${userId}/${skillId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adminId: adminId,
            adminName: adminName,
          }),
        }
      );

      if (response.ok) {
        const removedSkill = unlockedSkills.find(
          (skill) => skill.skillId === skillId
        );
        setUnlockedSkills((prevSkills) =>
          prevSkills.filter((skill) => skill.skillId !== skillId)
        );
        setLockedSkills((prevSkills) => [...prevSkills, removedSkill]);
        return true; // Indicate success
      } else {
        console.error("Failed to remove skill.");
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error removing skill:", error);
      return false; // Indicate failure
    }
  };

  const handleSkillAdd = async (skillId) => {
    try {
      const ls = new SecureLS({ isCompression: false });
      const adminDetails = ls.get("adminDetails");
      const token = adminDetails ? adminDetails.token : null;
      const adminName = adminDetails ? adminDetails.name : "Unknown Admin";
      const adminId = adminDetails ? adminDetails.userId : "Unknown AdminId";

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/admin/skills/add/${userId}/${skillId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            adminId: adminId,
            adminName: adminName,
          }),
        }
      );

      if (response.ok) {
        const { skill } = await response.json();
        setUnlockedSkills((prevSkills) => [...prevSkills, skill]);
        setLockedSkills((prevSkills) =>
          prevSkills.filter((s) => s.skillId !== skillId)
        );
        return true; // Indicate success
      } else {
        console.error("Failed to add skill.");
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error adding skill:", error);
      return false; // Indicate failure
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: '"Pixelify Sans", sans-serif',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <IconButton
          onClick={() => window.history.back()}
          sx={{ marginRight: 2 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: '"Pixelify Sans", sans-serif',
            textAlign: "center",
            flex: 1,
          }}
        >
          {userName}'s Skills
        </Typography>
      </Box>

      <Typography variant="h6" gutterBottom>
        Unlocked Skills
      </Typography>
      <UnlockedSkills
        skills={unlockedSkills}
        onSkillUpdate={handleSkillUpdate}
        onSkillRemove={handleSkillRemove}
      />

      <Divider sx={{ marginY: 4 }} />

      <Typography variant="h6" gutterBottom>
        Locked Skills
      </Typography>
      <LockedSkills skills={lockedSkills} onSkillAdd={handleSkillAdd} />
    </Box>
  );
};

export default UserSkills;
