import React, { useState } from "react";
import ItemCard from "./ItemCard";
import { Box } from "@mui/material";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ItemCardsSlider = ({
  items,
  lockedItems,
  onGather,
  onAutoGather,
  expPercentages,
  purchasedItems,
  isAutoGather,
  onAutoGatherChange,
  allItems,
  otherLockedItems,
}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 0.35,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
  };

  const [autoGatherState, setAutoGatherState] = useState({});

  const toggleAutoGather = (itemId) => {
    setAutoGatherState((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const filteredItems = items.filter(
    (item) => ![31, 32, 33].includes(item.itemId)
  );

  const filteredLockedItems = lockedItems.filter(
    (item) => ![31, 32, 33].includes(item.itemId)
  );

  return (
    <>
      {window.innerWidth <= 1024 ? (
        <Slider {...settings}>
          {filteredItems.map((item, idx) => (
            <Box key={idx}>
              <ItemCard
                item={item}
                onGather={() => onGather(item.itemId)}
                onAutoGather={() => {
                  onAutoGather(item.itemId);
                  toggleAutoGather(item.itemId);
                }}
                onAutoGatherChange={onAutoGatherChange}
                expPercentage={
                  expPercentages[item.itemId]
                    ? expPercentages[item.itemId].toFixed(2) + "%"
                    : "Loading..."
                }
                purchasedItems={purchasedItems}
                isAutoGather={autoGatherState[item.itemId] || false}
                allItems={allItems} // Pass all items for reference
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
          {filteredLockedItems.map((item, idx) => (
            <Box key={idx}>
              <ItemCard
                item={item}
                onGather={() => {}}
                onAutoGather={() => {}}
                onAutoGatherChange={() => {}}
                expPercentage="Locked"
                purchasedItems={purchasedItems}
                isAutoGather={false}
                isLocked={true}
                lockedItems={lockedItems}
                allItems={allItems} // Pass all items for reference
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
        </Slider>
      ) : (
        <div style={{ overflowY: "scroll", maxHeight: "370px" }}>
          {filteredItems.map((item, idx) => (
            <Box key={idx}>
              <ItemCard
                item={item}
                onGather={() => onGather(item.itemId)}
                onAutoGather={() => {
                  onAutoGather(item.itemId);
                  toggleAutoGather(item.itemId);
                }}
                onAutoGatherChange={onAutoGatherChange}
                expPercentage={
                  expPercentages[item.itemId]
                    ? expPercentages[item.itemId].toFixed(2) + "%"
                    : "Loading..."
                }
                purchasedItems={purchasedItems}
                lockedItems={lockedItems}
                isAutoGather={autoGatherState[item.itemId] || false}
                allItems={allItems} // Pass all items for reference
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
          {filteredLockedItems.map((item, idx) => (
            <Box key={idx}>
              <ItemCard
                item={item}
                onGather={() => {}}
                onAutoGather={() => {}}
                onAutoGatherChange={() => {}}
                expPercentage="Locked"
                purchasedItems={purchasedItems}
                lockedItems={lockedItems}
                isAutoGather={false}
                isLocked={true}
                allItems={allItems} // Pass all items for reference
                otherLockedItems={otherLockedItems}
              />
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default ItemCardsSlider;
