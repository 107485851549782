import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

const ActiveDeactiveUserDialog = ({
  open,
  status,
  name,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {status === "Active" ? "Deactivate User" : "Activate User"}
      </DialogTitle>
      <DialogContent>
        Are you sure you want to{" "}
        {status === "Active" ? "deactivate" : "activate"}{" "}
        <strong>{name}</strong>?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="primary">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActiveDeactiveUserDialog;
